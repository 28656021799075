import React from 'react';

const useIsActiveTab = () => {
  const [isActive, setIsActive] = React.useState(true);

  React.useEffect(() => {
    const onBlur = () => setIsActive(false);
    const onFocus = () => setIsActive(true);

    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  return isActive;
};

export default useIsActiveTab;
