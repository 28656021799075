import React from 'react';
import Star from 'assets/icons/star.svg';

const MandatoryWarning: React.FC = () => (
  <div className="flex pt-2 kiosk:pt-4 items-center kiosk:mb-5 kiosk:px-5">
    <span className="relative p-0.5 after:absolute after:content-[''] after:rounded-full after:w-full after:h-full after:border-2 after:border-star-mandatory after:top-0 after:left-0 after:animate-ping mr-6">
      <Star className="size-2.5 kiosk:size-5 fill-text-star-mandatory" />
    </span>
    <div className="w-full">
      <div className="h-1 rounded-3xl bg-input-text/20" />
      <div className="w-1/2 h-1 mt-2 rounded-3xl bg-input-text/20" />
    </div>
  </div>
);

export default MandatoryWarning;
