import { createDictionary } from 'utils/objectUtils';

export const HOME = 'SO';
export const HOME_VEHICLE_CHECK = 'VC';
export const HOME_OUTDOOR_SHAREBOX = 'OS';

export const returnURL = createDictionary<string>({
  [HOME_VEHICLE_CHECK]: '/vehicle-check',
  [HOME_OUTDOOR_SHAREBOX]: '/QRCode',
}, '/');
