import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useField, FormRenderProps } from 'react-final-form';

import Card from 'components/ui/Card';
import PageFooter from 'components/ui/PageFooter';

import { b } from 'utils/i18nUtils';

import { YesNoChoice } from 'types/Question';
import ExtraStatus from 'types/ExtraStatus';
import PageBaseProps from 'types/PageBase';
import { parse, format } from 'components/ui/Card/utils';
import {
  isVehicleCheckEnabled as isVehicleCheckEnabledSelector,
} from 'modules/auth/selectors';
import { isCourtesyVehicleCheckEnabled as isCourtesyVehicleCheckEnabledSelector } from 'modules/selfServices/selectors';

const VehicleCheckFormRender: React.FC<FormRenderProps & Pick<PageBaseProps, 'onPrev' | 'shouldDisplayBackButton'>> = ({ handleSubmit, onPrev, shouldDisplayBackButton }) => {
  const intl = useIntl();
  const isVehicleCheckEnabled = useSelector(isVehicleCheckEnabledSelector);
  const isCourtesyVehicleCheckEnabled = useSelector(isCourtesyVehicleCheckEnabledSelector);

  const {
    value: status,
    onChange: handleChangeStatus,
  } = useField<YesNoChoice, HTMLElement, ExtraStatus>('vehicleCheck', {
    subscription: { value: true },
    parse,
    format,
  }).input;

  const {
    value: mobilityStatus,
    onChange: handleChangeMobilityStatus,
  } = useField<YesNoChoice, HTMLElement, ExtraStatus>('courtesyVehicleCheck', {
    subscription: { value: true },
    parse,
    format,
  }).input;

  const isDisabled = (isVehicleCheckEnabled && status === undefined) || (isCourtesyVehicleCheckEnabled && mobilityStatus === undefined);

  const vehicleCheckLabel = React.useMemo(() => intl.formatMessage({
    id: `vehicleCheck.selfService.${isCourtesyVehicleCheckEnabled ? 'personalQuestion' : 'modalQuestion'}`,
    defaultMessage: isCourtesyVehicleCheckEnabled
      ? 'Do you want to do a check of your personal vehicle yourself?'
      : 'Do you want to do a check yourself?',
  }), [intl, isCourtesyVehicleCheckEnabled]);

  return (
    <div className="main-content">
      <div className="flex flex-col items-center text-center kiosk:px-16">
        <FormattedMessage id="vehicleCheck.selfService.title" defaultMessage="Vehicle check" tagName="h1" />
        <span className="mt-5 mb-10">
          <FormattedMessage
            id="vehicleCheck.selfService.question"
            defaultMessage="Would you like to do a <b>guided vehicle check?</b>"
            values={{ b }}
          />
        </span>
        <span className="mb-10">
          <FormattedMessage
            id="vehicleCheck.selfService.information"
            defaultMessage="We will ask you to take <b>photos with your own smartphone</b> at the end of the check-in."
            values={{ b }}
          />
        </span>
        <FormattedMessage
          id="vehicleCheck.selfService.responsability"
          defaultMessage="By refusing, you agree to take responsibility for any damage found on the vehicle that would not have been previously reported."
          tagName="span"
        />
      </div>
      <div className="max-w-2xl kiosk:border-t-2 border-bg-divider mb-20 kiosk:pt-20">
        {isVehicleCheckEnabled && (
          <Card
            status={status}
            onStatusChange={handleChangeStatus}
            mandatory
            className="mb-5 kiosk:mb-20"
            titleClassName="grid-cols-1 justify-center"
            title={vehicleCheckLabel}
          />
        )}
        {isCourtesyVehicleCheckEnabled && (
          <Card
            status={mobilityStatus}
            onStatusChange={handleChangeMobilityStatus}
            mandatory
            titleClassName="grid-cols-1 justify-center"
            title={<FormattedMessage id="vehicleCheck.selfService.courtesyQuestion" defaultMessage="Do you want to do a check of the courtesy vehicle yourself?" />}
          />
        )}
      </div>

      <PageFooter
        onPrev={onPrev}
        disabled={isDisabled}
        onNext={handleSubmit}
        shouldDisplayBackButton={shouldDisplayBackButton}
      />
    </div>
  );
};

export default VehicleCheckFormRender;
