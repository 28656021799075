import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';
import { SelfServiceStepConfig } from 'modules/selfServices/types/SelfServiceStepConfig';
import { SelfService, SelfServiceOrigin, SelfServiceType } from 'modules/selfServices/types/SelfService';

import { getFollowingStep } from 'utils/stepUtils';

import CHECK_IN_STEPS from './check-in';
import CHECK_OUT_STEPS from './check-out';
import BREAKDOWN_STEPS from './breakdown';
import { SelfServiceStep, isVisibleStep } from './common';

type SelfServiceStepsDefinition =
  Record<SelfServiceType, Partial<Record<SelfServiceOrigin, Record<keyof typeof SelfServiceSteps, SelfServiceStep>>>>;

const STEPS: SelfServiceStepsDefinition = {
  [SelfServiceType.BREAKDOWN]: BREAKDOWN_STEPS,
  [SelfServiceType.CHECK_IN]: CHECK_IN_STEPS,
  [SelfServiceType.CHECK_OUT]: CHECK_OUT_STEPS,
};

export const getSelfServiceSteps = (selfService: SelfService, config: SelfServiceStepConfig) => {
  const selfServiceSteps: SelfServiceStep[] = Object.values(STEPS[selfService.type][selfService.origin]);

  let steps = selfServiceSteps.filter((step) => isVisibleStep(step, selfService, config));
  steps = steps.map((step) => ({
    ...step,
    next: getFollowingStep<SelfServiceStep>(step, steps, selfServiceSteps),
    previous: getFollowingStep<SelfServiceStep>(step, steps, selfServiceSteps, 'previous'),
  }));

  return steps;
};

export default STEPS;
