import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { getVehicleCheckDetailPage } from 'modules/router/selectors';

import type { RootState } from '../../App/Store';
import { VehicleCheckType } from './types/VehicleCheckModel';

const getState = (state: RootState) => state.vehicleCheck;

export const getContext = createDraftSafeSelector(getState, ({ context }) => context);
export const getVehicleCheckToken = createDraftSafeSelector(getContext, (context) => context?.token);
export const getSelfServiceId = createDraftSafeSelector(getContext, (context) => context?.selfServiceId);

export const getVehicleCheckModel = createDraftSafeSelector(getState, ({ vehicleCheckModel }) => vehicleCheckModel);

export const getVehicleCheckType = createDraftSafeSelector(getContext, (context) => context?.type);

export const getCurrentPageType = createDraftSafeSelector(getVehicleCheckDetailPage, (path) => path?.params?.type as VehicleCheckType);
