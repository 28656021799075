import React from 'react';
import { useIntl } from 'react-intl';
import { FieldValidator, getIn } from 'final-form';
import { useField, useForm } from 'react-final-form';

import { AbstractMedia, MediaType } from 'types/AbstractMedia';
import { Item, VehicleCheckModelType } from 'modules/vehicleCheck/types/VehicleCheckModel';

import { useSelector } from 'hooks';
import useLabelTranslation from 'hooks/useLabelTranslation';

import { getSelfServiceId } from 'modules/vehicleCheck/selectors';

import VehicleCheckImage from 'assets/images/vehicleCheckDefaultCard.jpg';

import MediaCardUpload from '../ui/MediaCard/Card';
import Content from '../ui/MediaCard/Content';

interface CardProps {
  fieldName: string;
  onUpload?:() => void;
  onDelete?: () => void;
}

const DEFAULT_MEDIA: AbstractMedia = { type: MediaType.IMAGE, path: VehicleCheckImage };

const VehicleCheckCard: React.FC<CardProps> = ({ fieldName, onUpload, onDelete }) => {
  const intl = useIntl();
  const { change } = useForm();

  const selfServiceId = useSelector(getSelfServiceId);
  const { getLabelTranslation } = useLabelTranslation();

  const {
    name = '', instruction, media, type, responseMedia,
  } = useField<Item>(fieldName, { subscription: { value: true } }).input.value;

  const validate: FieldValidator<AbstractMedia> = React.useCallback((value, allValues) => {
    if (!value?.uploadId) {
      if (getIn(allValues, `${fieldName}.type`) === VehicleCheckModelType.MANDATORY) { // Mandatory item
        return 'validation.required';
      }
      if (getIn(allValues, `${fieldName}.responseMedia`) !== undefined) { // Media uploading
        return 'validation.loading';
      }
    }
    return undefined;
  }, [fieldName]);

  const setResponseMedia = useField<AbstractMedia>(
    `${fieldName}.responseMedia`,
    { subscription: {}, validate },
  ).input.onChange;

  const uploadId = responseMedia?.uploadId;
  const description = instruction ? getLabelTranslation(instruction) : '';
  const defaultMedia = React.useMemo(() => media ?? DEFAULT_MEDIA, [media]);

  const isUploaded = Boolean(uploadId);
  const isMandatory = type === VehicleCheckModelType.MANDATORY;
  const isAdditional = type === VehicleCheckModelType.ADDITIONAL;

  const title = typeof name === 'string' ? name : getLabelTranslation(name);
  const displayedTitle = isAdditional && !isUploaded
    ? intl.formatMessage({ id: 'vehicleCheck.addAdditionalMedia', defaultMessage: 'Add additional media' })
    : title;

  const hidePreview = isAdditional && !isUploaded;
  const canEditTitle = isAdditional && isUploaded;

  const handleUploaded = React.useCallback((uploadedMedia: AbstractMedia) => {
    setResponseMedia(uploadedMedia);
    onUpload?.();
  }, [onUpload, setResponseMedia]);

  const handleDelete = React.useCallback(() => {
    setResponseMedia(undefined);
    onDelete?.();
  }, [onDelete, setResponseMedia]);

  const handleChangeName = React.useCallback(
    (newName: string) => change(`${fieldName}.name`, newName),
    [change, fieldName],
  );

  return (
    <div>
      <MediaCardUpload
        onDelete={handleDelete}
        hidePreview={hidePreview}
        onUploaded={handleUploaded}
        defaultMedia={defaultMedia}
        className="hide-play-button"
        selfServiceId={selfServiceId}
      />
      <Content
        title={displayedTitle}
        editable={canEditTitle}
        mandatory={isMandatory}
        description={description}
        onChangeName={handleChangeName}
        placeholder={isAdditional && (
          intl.formatMessage({
            id: 'vehicleCheck.addNote',
            defaultMessage: 'Add note (e.g.: scratch on the roof)',
          })
        )}
      />
    </div>
  );
};

export default VehicleCheckCard;
