import React from 'react';

import { useSelector } from 'hooks';
import { useCheckQRCodeTokenMutation } from 'modules/auth/service';

import { KioskType } from 'modules/dealers/types/ContextResponse';

import { getKioskId } from 'modules/kiosk/selectors';
import { getKioskType } from 'modules/dealers/selectors';

import QRCodeScannerDevice from '../models/QRCodeScannerDevice';

/**
 * This custom hook works only on Kiosk with Edge browser, Edge policy "WebHidAllowDevicesForUrls" enabled and in HTTPS.
 * @see https://fleetback.atlassian.net/wiki/x/WhcdAQ
 */
const useQrCodeScanner = () => {
  const kioskId = useSelector(getKioskId);
  const kioskType = useSelector(getKioskType);

  const [login] = useCheckQRCodeTokenMutation();

  const handleLogin = React.useCallback((qrCodeToken: string) => {
    login({ qrCodeToken, kioskId });
  }, [login, kioskId]);

  React.useEffect(() => {
    if (kioskId && kioskType === KioskType.PARTTEAM) {
      const qrCodeScanner = QRCodeScannerDevice.getInstance();

      qrCodeScanner.addListener(handleLogin);
      return () => qrCodeScanner.removeListener(handleLogin);
    }

    return undefined;
  }, [kioskId, kioskType, handleLogin]);
};

export default useQrCodeScanner;
