import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getCurrentStep } from 'modules/steps/selector';
import {
  isPublicDevice,
  shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector,
} from 'modules/dealers/selectors';

import QuestionMark from 'assets/icons/questionMark.svg';

import { b, getHyperlink } from 'utils/i18nUtils';

import Modal from './Modal';
import DealersPhone from './DealersPhone';
import QRCodeViewer from './QRCodeViewer';
import Button, { ButtonType } from './Button';

const DSA_URL = 'https://www.fleetback.com/digital-services-act/';

const HelpLineButton: React.FC = () => {
  const ref = React.useRef<HTMLButtonElement>();

  const isPublic = useSelector(isPublicDevice);
  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);

  const [isOpen, setIsOpen] = React.useState(false);
  const currentStep = useSelector(getCurrentStep);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      <Button
        className="size-10 kiosk:size-20 !p-2"
        onClick={handleOpenModal}
        type={ButtonType.SECONDARY}
        analytics={{
          category: 'Help',
          action: 'Help button',
          label: `Help needed from ${currentStep}`,
        }}
      >
        <QuestionMark className="m-auto size-6 kiosk:size-8" />
      </Button>
      <Modal
        open={isOpen}
        focusRef={ref}
        className="w-full max-w-2xl"
        title={<FormattedMessage id="helpLine.title" defaultMessage="Need help?" tagName="h2" />}
      >
        {shouldDisplayPhoneNumbers && (
          <>
            <div className="flex flex-col items-center">
              <p className="text-center">
                <FormattedMessage
                  id="helpLine.message"
                  defaultMessage="If something is not working properly, please do not hesitate to contact the helpline below."
                />
              </p>
              <DealersPhone />
            </div>
            <div className="w-full h-px my-4 kiosk:my-8 bg-divider-bg" />
          </>
        )}
        <div className="flex flex-col items-center">
          {!isPublic && (
            <FormattedMessage
              id="helpLine.dsa"
              defaultMessage="Content not adapted? You can report the problem <a><b>here</b></a>"
              values={{ a: getHyperlink({ href: DSA_URL, target: '_blank' }), b }}
              tagName="p"
            />
          )}
          {isPublic && (
            <>
              <FormattedMessage
                id="helpLine.dsaKiosk"
                defaultMessage="Content not adapted? You can <b>report the problem</b> by scanning this QR code"
                values={{ b }}
                tagName="p"
              />
              <QRCodeViewer content={DSA_URL} className="!w-1/2 !h-1/2 mt-4 flex justify-center" />
              <span className="kiosk:text-xl kiosk:mt-8 font-medium">{DSA_URL}</span>
            </>
          )}
        </div>
        <Button ref={ref} onClick={handleCloseModal} className="w-full mt-10">
          <FormattedMessage id="close" defaultMessage="Close" />
        </Button>
      </Modal>
    </>
  );
};
export default HelpLineButton;
