import React, { Suspense } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { b } from 'utils/i18nUtils';
import { validateEmail } from 'utils/formUtils';
import useUpload from 'hooks/useUpload';

import PageBaseProps from 'types/PageBase';

import formActions from 'modules/form/actions';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { isBreakdownSelfService, isPublicDevice } from 'modules/dealers/selectors';

import { useUpdateSelfServiceMutation } from 'modules/selfServices/service';

import Popup from 'components/ui/Popup';
import { ButtonType } from 'components/ui/Button';
import { Footer } from 'components/ui/PageFooter';
import {
  Button, Input, Spinner, Switch,
} from 'components/ui';

import Document from './Document';
import useDocuments from './hooks/useDocuments';
import SignatureModal from './SignatureModal';

const ScrollablePdfViewer = React.lazy(() => import('components/ui/PDFViewer/ScrollablePdfViewer'));

const Signature: React.FC<PageBaseProps> = ({ onPrev, onNext, isPenultimate }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isPublic = useSelector(isPublicDevice);
  const isBreakdown = useSelector(isBreakdownSelfService);
  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const { upload, isLoading: isUploadLoading } = useUpload(selfServiceId);
  const [, { isLoading: isUpdateLoading }] = useUpdateSelfServiceMutation({ fixedCacheKey: 'UPDATE/SELF_SERVICE' });

  const [email, setEmail] = React.useState('');
  const [signature, setSignature] = React.useState<Blob>(null);
  const [emailError, setEmailError] = React.useState<string>();
  const [displayEmailField, setDisplayEmailField] = React.useState(false);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = React.useState(false);
  const [hasConfirmedReading, setHasConfirmedReading] = React.useState(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = React.useState(false);

  const {
    document,
    documents,
    onSelectDocument,
    onRead,
    onRetry,
    onPrev: onPrevDocument,
    onNext: onNextDocument,
    isError,
    isLoading,
    isPrevDisabled,
    isNextDisabled,
  } = useDocuments();

  const isLoadingSignature = isUploadLoading || isUpdateLoading;
  const hasntReadAllDocuments = documents.some(({ read }) => !read);
  const hasOneDocument = documents.length === 1;

  const shouldShowEmailSwitch = isBreakdown && hasConfirmedReading;
  const isSignButtonDisabled = !hasConfirmedReading || Boolean(emailError) || (displayEmailField && !email);

  const handleRead = React.useCallback(() => {
    onRead(document?.id);
  }, [onRead, document]);

  const handleSelect = React.useCallback(
    (id: string) => () => {
      onSelectDocument(id);
    },
    [onSelectDocument],
  );

  const handleSignatureChange = React.useCallback((newSignature?: HTMLCanvasElement) => {
    if (newSignature) {
      newSignature.toBlob((blob) => {
        setSignature(blob);
      });
    } else {
      setSignature(null);
    }
  }, []);

  const handleEmailChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newEmail } = event.target;
    let error = 'validation.required';
    if (newEmail) {
      error = validateEmail(newEmail);
    }
    setEmailError(error);
    setEmail(newEmail);
  }, []);

  const handleNextClick = React.useCallback(async () => {
    const media = await upload({
      file: signature,
      isSignature: true,
      contentType: 'image/png',
    });

    // BMWVID-20295 - Avoid the case of the media is not correctly uploaded
    if (media) {
      const { uploadId } = media;
      dispatch(
        formActions.upsertForm({
          key: 'signature',
          values: { uploadId, breakdownServiceEmail: email || undefined },
        }),
      );
      onNext();
    }
  }, [dispatch, email, onNext, signature, upload]);

  const handleDisabledClick = React.useCallback(() => {
    setIsWarningPopupOpen(true);
  }, []);

  const handleClosePopup = React.useCallback(() => {
    setIsWarningPopupOpen(false);
  }, []);

  const handleOpenSignature = React.useCallback(() => {
    setIsSignatureModalOpen(true);
  }, []);

  const handleCloseSignature = React.useCallback(() => {
    setSignature(null);
    setIsSignatureModalOpen(false);
  }, []);

  return (
    <>
      <div className="main-content min-h-[calc(100svh-104px)] kiosk:min-h-svh pb-0 kioskPartteam:pb-48 kioskSharebox:pb-40">
        <div className="flex flex-col items-center">
          <FormattedMessage id="signature.title" defaultMessage="Signature" tagName="h1" />
          <p className="text-center mt-5 text-gray-70">
            <FormattedMessage
              id="signature.indication"
              defaultMessage="Please <b>sign</b> the {count, plural, one {document} other {documents}} below."
              values={{ b, count: documents.length }}
            />
          </p>
        </div>
        <div className="content pb-4 kiosk:pb-0">
          <Suspense fallback={<Spinner />}>
            <ScrollablePdfViewer
              id={document?.id}
              path={document?.path}
              onBottom={handleRead}
              isError={isError}
              isLoading={isLoading}
              isPrevDisabled={isPrevDisabled}
              isNextDisabled={isNextDisabled}
              hideDownload={isPublic}
              hideNavigationButtons={hasOneDocument}
              onRetry={onRetry}
              onPrev={onPrevDocument}
              onNext={onNextDocument}
              className="bg-secondary kioskPartteam:max-h-[36vh]"
              error={(
                <FormattedMessage
                  id="signature.generateFailure"
                  defaultMessage="We are unable to generate your summary"
                />
              )}
            />
          </Suspense>
          <div className="flex flex-col">
            {documents.map(({ id, ...documentProps }) => (
              <Document id={id} key={id} onClick={handleSelect(id)} selected={id === document?.id} {...documentProps} />
            ))}
          </div>

          <div className="relative">
            {hasntReadAllDocuments && (
              <div
                className="absolute top-0 left-0 size-full z-10 cursor-not-allowed"
                onClick={handleDisabledClick}
                data-testid="disabledExtraOverlay"
              />
            )}
            <Switch
              checked={hasConfirmedReading}
              onChange={setHasConfirmedReading}
              data-testid="confirm-switch"
              disabled={hasntReadAllDocuments}
            >
              <FormattedMessage
                id="signature.confirm"
                defaultMessage="I confirm that I have read, understood, and agree to sign the {count, plural, one {document} other {documents}} above."
                values={{ count: documents.length }}
              />
            </Switch>
            {shouldShowEmailSwitch && (
              <>
                <Switch
                  checked={displayEmailField}
                  onChange={setDisplayEmailField}
                  data-testid="email-switch"
                  className="mt-5 kiosk:mt-10"
                >
                  <FormattedMessage
                    id="signature.breakdownSwitch"
                    defaultMessage="I want to receive a summary of my answers by email."
                  />
                </Switch>
                {displayEmailField && (
                  <Input
                    type="email"
                    value={email}
                    autoCorrect="off"
                    inputMode="email"
                    spellCheck="false"
                    autoComplete="email"
                    data-testid="email-input"
                    onChange={handleEmailChange}
                    className="mt-3 kiosk:mt-10"
                    error={emailError && intl.formatMessage({ id: emailError })}
                    label={intl.formatMessage({ id: 'customerInfo.email', defaultMessage: 'Email' })}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <SignatureModal
          disabled={!signature}
          open={isSignatureModalOpen}
          onClose={handleCloseSignature}
          onConfirm={handleNextClick}
          onChange={handleSignatureChange}
          loading={isLoadingSignature}
          confirmMessage={(
            <>
              {isPenultimate && <FormattedMessage id="confirm.title" defaultMessage="Confirm" />}
              {!isPenultimate && <FormattedMessage id="steps.next" defaultMessage="Next" />}
            </>
          )}
        />
        {isWarningPopupOpen && (
          <Popup
            onClose={handleClosePopup}
            title={(
              <FormattedMessage
                id="signature.readError"
                defaultMessage="Your must first read mandatory {count, plural, one {document} other {documents}}"
                tagName="h2"
                values={{ count: documents.length }}
              />
            )}
          />
        )}
      </div>
      <Footer
        hideNextButton
        onNext={handleNextClick}
        loading={isLoading}
        disabled={isSignButtonDisabled}
        wrapperProps={{ className: 'relative kiosk:fixed' }}
        onPrev={onPrev}
        shouldDisplayBackButton
      >
        <Button
          type={ButtonType.TERTIARY}
          className="max-w-md w-full kiosk:inline-flex kiosk:justify-center kiosk:w-72"
          onClick={handleOpenSignature}
          disabled={isSignButtonDisabled}
        >
          <FormattedMessage
            id="signature.sign"
            defaultMessage="Sign {count, plural, one {document} other {documents}}"
            values={{ count: documents.length }}
          />
        </Button>
      </Footer>
    </>
  );
};

export default Signature;
