import React from 'react';
import classNames from 'classnames';

import { Spinner } from 'components/ui';
import { AbstractMedia, MediaType } from 'types/AbstractMedia';

interface BackgroundProps {
  loading?: boolean;
  media?: AbstractMedia;
}

const MEDIA_CLASSNAMES = 'fixed object-cover w-screen h-screen';

const Background: React.FC<React.PropsWithChildren<BackgroundProps>> = ({ media, loading, children }) => {
  const videoRef = React.useRef<HTMLVideoElement>();
  const [forceDefaultBackground, setForceDefaultBackground] = React.useState(false);
  const [firstFrameImage, setFirstFrameImage] = React.useState<string>();
  const [isPlayable, setIsPlayable] = React.useState<boolean>(false);
  const [isImageReady, setIsImageReady] = React.useState<boolean>(false);

  const isImage = media?.type === MediaType.IMAGE;
  const isVideo = media?.type === MediaType.VIDEO;
  const isCustom = !forceDefaultBackground && Boolean(media);

  const isLoadingImage = isImage && !isImageReady;
  const isLoadingVideo = isVideo && !isPlayable && !firstFrameImage;
  const isLoading = loading || (isCustom && (isLoadingVideo || isLoadingImage));

  const handleError = React.useCallback(() => setForceDefaultBackground(true), []);

  React.useLayoutEffect(() => {
    // Reset the background if the media is changed
    if (media) {
      setForceDefaultBackground(false);
    }
  }, [media]);

  const captureFirstFrame = React.useCallback(() => {
    const video = videoRef.current;
    if (video) {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageUrl = canvas.toDataURL('image/png');
        setFirstFrameImage(imageUrl);
      }
    }
  }, []);

  const handleCanPlay = React.useCallback(() => {
    setIsPlayable(true);
  }, []);

  const handleImageLoad = React.useCallback(() => {
    setIsImageReady(true);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="flex items-center justify-center min-h-screen">
          <Spinner />
        </div>
      )}
      <div
        className={classNames('flex flex-col items-center justify-start', {
          homePage: !isLoading,
          'bg-cover bg-center bg-home -z-10': !isLoading && !isCustom,
        })}
      >
        {isCustom && (
          <>
            {!isVideo && (
              <img
                alt=""
                loading="eager"
                src={media.path}
                // @ts-expect-error - See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#fetchpriority)
                // eslint-disable-next-line react/no-unknown-property
                fetchpriority="high"
                onError={handleError}
                onLoad={handleImageLoad}
                className={MEDIA_CLASSNAMES}
                data-testid="background-image"
              />
            )}
            {isVideo && (
              <>
                {!isPlayable && firstFrameImage && (
                  <img src={firstFrameImage} className={classNames(MEDIA_CLASSNAMES, 'pointer-events-none')} alt="" />
                )}
                <video
                  ref={videoRef}
                  loop
                  muted
                  autoPlay
                  playsInline
                  preload="auto"
                  controls={false}
                  disableRemotePlayback
                  disablePictureInPicture
                  crossOrigin="anonymous"
                  onError={handleError}
                  onLoadedData={captureFirstFrame}
                  onCanPlayThrough={handleCanPlay}
                  data-testid="background-video"
                  className={classNames(MEDIA_CLASSNAMES, 'pointer-events-none', {
                    block: isPlayable,
                    hidden: !isPlayable,
                  })}
                >
                  <source src={media.path} />
                </video>
              </>
            )}
          </>
        )}
        {!isLoading && children}
      </div>
    </>
  );
};

export default Background;
