import { isAfter, fromUnixTime } from 'date-fns';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'App/Store';
import { getUrlParam } from 'utils/urlUtils';
import formActions from 'modules/form/actions';
import { resetStep } from 'modules/steps/actions';

import dealersApi from 'modules/dealers/service';
import { getLoginToken, getPolicies } from 'modules/dealers/selectors';
import { ConnectionTypeLogin } from 'modules/dealers/types/ContextResponse';

import authApi from './service';
import { ChannelEnum, LoginParams } from './types/LoginRequest';
import { LoginResponse, SendMFACodeResponse } from './types/LoginResponse';

type InitLoginResponse = SendMFACodeResponse | LoginResponse;

export const login = createAsyncThunk<InitLoginResponse, LoginParams>(
  'login/init',
  async ({
    registration, connectionType, phoneNumber, locationId, ...mfaParams
  }, { dispatch, rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const token = getUrlParam('token');
    const contextToken = getLoginToken(state);

    try {
      const { exp } = jwtDecode<JwtPayload>(contextToken);
      const currentDate = new Date();
      const expirationDate = fromUnixTime(exp);
      const isTokenExpired = isAfter(currentDate, expirationDate);

      // When token expired because user didn't refresh, refetch context to get new exp token
      if (isTokenExpired) {
        await dispatch(dealersApi.endpoints.getContext.initiate(undefined, { forceRefetch: true })).unwrap();
      }

      const isLoginMFA = connectionType === ConnectionTypeLogin.MFA;

      dispatch(formActions.resetForm());
      dispatch(resetStep());

      if (isLoginMFA) {
        const computedPhoneNumber = phoneNumber ? `+${phoneNumber.prefix}${phoneNumber.local}` : undefined;

        const { retryExpiration, ...data } = await dispatch(authApi.endpoints.getMFAConnection.initiate({
          registration,
          phoneNumber: computedPhoneNumber,
          ...mfaParams,
        })).unwrap();

        if (!retryExpiration || isAfter(new Date(), new Date(retryExpiration))) {
          return await dispatch(authApi.endpoints.sendMFACode.initiate({
            connectionId: data.connectionId,
            selfServiceId: data.selfServiceId,
            channel: data.phoneNumber ? ChannelEnum.SMS : ChannelEnum.EMAIL,
          })).unwrap();
        }
        return undefined;
      }

      if (token) {
        return await dispatch(authApi.endpoints.login.initiate({
          registration,
          locationId,
          policies: getPolicies(getState() as RootState),
        })).unwrap();
      }
    } catch (error) {
      return rejectWithValue(error);
    }
    return rejectWithValue(undefined);
  },
);
