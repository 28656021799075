import React from 'react';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { Position } from 'types/Position';

const getImageData = (image: HTMLImageElement) => {
  const {
    width, height, naturalWidth, naturalHeight,
  } = image ?? {};

  const imageRatio = naturalWidth / naturalHeight || 0;
  const imageWidth = Math.min(imageRatio * height, width) || 0;
  const imageHeight = imageWidth / imageRatio || 0;
  const offsetWidth = ((width - imageWidth) / 2) || 0;
  const offsetHeight = ((height - imageHeight) / 2) || 0;

  return {
    imageRatio, imageWidth, imageHeight, offsetWidth, offsetHeight, width, height,
  };
};

interface UseImageProps {
  disabled?: boolean;
  onChange: (pos: Position) => void;
  initialPosition: Position | Record<string, never>
}

const useImage = ({ disabled, onChange, initialPosition }: UseImageProps) => {
  const transformComponentRef = React.useRef<ReactZoomPanPinchRef>();

  const [isLoading, setIsLoading] = React.useState(false);
  const [imageRef, setImageRef] = React.useState<HTMLImageElement>();
  const [imageData, setImageData] = React.useState(getImageData(imageRef));

  React.useLayoutEffect(() => {
    if (!imageRef?.complete) {
      setIsLoading(true);
    }

    const handleResize = () => {
      setImageData(getImageData(imageRef));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [imageRef]);

  const onClick = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      const element = e.target as HTMLDivElement;

      const {
        offsetHeight, offsetWidth, imageHeight, imageWidth,
      } = imageData;

      if (e.nativeEvent.offsetY >= offsetHeight
          && e.nativeEvent.offsetX >= offsetWidth
          && e.nativeEvent.offsetY <= (element.offsetHeight - offsetHeight)
          && e.nativeEvent.offsetX <= (element.offsetWidth - offsetWidth)) {
        onChange?.({
          x: ((e.nativeEvent.offsetX - offsetWidth) / imageWidth) * 100,
          y: ((e.nativeEvent.offsetY - offsetHeight) / imageHeight) * 100,
        });
      }
    }
  }, [disabled, imageData, onChange]);

  const onImageLoaded = React.useCallback(() => {
    if (initialPosition.x || initialPosition.y) {
      requestAnimationFrame(() => transformComponentRef.current?.zoomToElement('marker', 1, 0));
    } else {
      transformComponentRef.current?.centerView(1, 0);
    }

    setImageData(getImageData(imageRef));
    setIsLoading(false);
  }, [imageRef, initialPosition]);

  const position = React.useMemo(() => {
    const {
      imageWidth, imageHeight, offsetWidth, offsetHeight,
    } = imageData;

    return {
      top: ((imageHeight / 100) * initialPosition.y) + offsetHeight,
      left: ((imageWidth / 100) * initialPosition.x) + offsetWidth,
    };
  }, [imageData, initialPosition]);

  const isReady = Boolean(!isLoading && imageRef && position.top && position.left);

  return React.useMemo(() => ({
    isLoading, onImageLoaded, onClick, position, setImageRef, transformComponentRef, isReady,
  }), [isLoading, onImageLoaded, onClick, position, setImageRef, transformComponentRef, isReady]);
};

export default useImage;
