import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormRenderProps, useField } from 'react-final-form';

import PageBaseProps from 'types/PageBase';
import { formatSelectData, formatToArray, required } from 'utils/formUtils';
import { BreakdownInfo, Reason, RequestedWorks } from 'types/BreakdownInfo';

import { FieldValidator } from 'final-form';
import PageFooter from '../ui/PageFooter';
import SelectInput from '../ui/SelectInput';
import FormTextarea from '../ui/form/FormTextarea';

const validateComment: FieldValidator<string> = (comment, { breakdownServiceRequestedWorks }: BreakdownInfo) => {
  const isMandatory = breakdownServiceRequestedWorks?.includes(RequestedWorks.OTHER);
  return isMandatory ? required(comment) : undefined;
};

const Content: React.FC<PageBaseProps & FormRenderProps> = ({
  onPrev, handleSubmit, initialValues, pristine, hasValidationErrors, shouldDisplayBackButton,
}) => {
  const intl = useIntl();

  const { input: reasonInput } = useField('breakdownServiceReason', { subscription: { value: true } });
  const { input: requestedWorks } = useField('breakdownServiceRequestedWorks', {
    subscription: { value: true },
    format: formatToArray<RequestedWorks>,
  });

  const reasonInputData = formatSelectData(Reason, intl, 'breakdownInfo.reason');
  const requestedWorkData = formatSelectData(RequestedWorks, intl, 'breakdownInfo.requestedWorks');

  const displaySkipText = pristine && !initialValues;

  return (
    <div className="main-content">
      <div className="flex flex-col items-center">
        <FormattedMessage
          tagName="h1"
          id="breakdownInfo.title"
          defaultMessage="Information"
        />
        <div className="description mt-5 text-center">
          <FormattedMessage
            id="breakdownInfo.description"
            defaultMessage="Fill in the customer’s contact information and provide details about the breakdown."
            tagName="p"
          />
        </div>
      </div>
      <div className="content">
        <SelectInput
          {...reasonInput}
          data={reasonInputData}
          data-testid="reason-input"
          label={intl.formatMessage({ id: 'breakdownInfo.reasonLabel', defaultMessage: 'Reason for tow' })}
        />
        <SelectInput
          {...requestedWorks}
          multiple
          data={requestedWorkData}
          data-testid="requestedWorks-input"
          label={intl.formatMessage({ id: 'breakdownInfo.requestedWorksLabel', defaultMessage: 'Requested work' })}
        />
        <FormTextarea
          data-testid="comment"
          validate={validateComment}
          name="breakdownServiceComment"
          className="w-full px-5 kiosk:px-10"
          label={intl.formatMessage({ id: 'repairOrder.comment', defaultMessage: 'Comment' })}
        />
      </div>
      <PageFooter
        onPrev={onPrev}
        onNext={handleSubmit}
        disabled={hasValidationErrors}
        shouldDisplayBackButton={shouldDisplayBackButton}
        nextText={displaySkipText ? { id: 'breakdownInfo.skip', defaultMessage: 'Skip' } : undefined}
      />
    </div>
  );
};

export default Content;
