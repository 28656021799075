import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logout } from 'modules/auth/actions';
import selfServicesApi from 'modules/selfServices/service';
import PaymentStatus, { fromFinalInvoice } from './types/PaymentStatus';
import onlinePaymentApi from './service';

const INITIAL_STATE = {
  isPayingModalOpen: false,
  status: PaymentStatus.WAITING_FOR_USER,
};

const onlinePaymentSlice = createSlice({
  name: 'onlinePayment',
  initialState: INITIAL_STATE,
  reducers: {
    openPayingModal: (state) => ({
      ...state,
      isPayingModalOpen: true,
    }),
    closePayingModal: (state) => ({
      ...state,
      isPayingModalOpen: false,
    }),
    setPaymentStatus: (state, action: PayloadAction<PaymentStatus>) => ({
      ...state,
      status: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => INITIAL_STATE);

    builder
      .addMatcher(selfServicesApi.endpoints.getSelfServiceById.matchFulfilled, (state, { payload }) => ({
        ...state,
        status: payload.finalInvoice ? fromFinalInvoice(payload.finalInvoice) : PaymentStatus.WAITING_FOR_USER,
      }));

    builder
      .addMatcher(onlinePaymentApi.endpoints.getFinalInvoiceStatus.matchFulfilled, (state, { payload }) => ({
        ...state,
        status: fromFinalInvoice(payload),
      }));
  },
});

export default onlinePaymentSlice;
