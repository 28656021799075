import { FinalInvoice, FinalInvoiceStatus } from 'modules/selfServices/types/SelfService';

enum PaymentStatus {
  // Payment not initiated, waiting for user to interact
  WAITING_FOR_USER = 'waiting_for_user',
  // Payment initiated, waiting for user to complete the payment
  PENDING = 'pending',
  // Payment completed successfully
  SUCCESS = 'success',
  // Payment failed
  ERROR = 'error',
}

export const fromFinalInvoice = ({ status, pending }: FinalInvoice): PaymentStatus => {
  switch (status) {
    case FinalInvoiceStatus.CREATED:
      return PaymentStatus.WAITING_FOR_USER;
    case FinalInvoiceStatus.SENT:
      return pending ? PaymentStatus.PENDING : PaymentStatus.WAITING_FOR_USER;
    case FinalInvoiceStatus.PAID:
      return PaymentStatus.SUCCESS;
    default:
      return PaymentStatus.ERROR;
  }
};

export const isFinished = (status: PaymentStatus) => status === PaymentStatus.SUCCESS || status === PaymentStatus.ERROR;

export default PaymentStatus;
