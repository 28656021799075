import React from 'react';
import { useIntl } from 'react-intl';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

import Label from 'types/Label';
import Card from 'components/ui/Card';
import ExtraStatus from 'types/ExtraStatus';
import { parse, format } from 'components/ui/Card/utils';

import { formatUndefined } from 'utils/formUtils';
import FormKeys from 'modules/form/types/FormKeys';
import QuestionType, { YesNoChoice } from 'types/Question';
import useLabelTranslation from 'hooks/useLabelTranslation';
import { isIntlMessage } from 'modules/notifications/types/Notification';

interface QuestionFormProps {
  name: string;
  questionFilter?: (question: QuestionType) => boolean;
}

const Question: React.FC<QuestionFormProps> = ({ name, questionFilter }) => {
  const intl = useIntl();
  const { getLabelTranslation } = useLabelTranslation();

  const { value: question } = useField(name, { subscription: { value: true } }).input;
  const { value: comment, onChange: handleChangeComment } = useField<string>(`${name}.comment`, {
    subscription: { value: true },
    format: formatUndefined,
  }).input;

  const title = isIntlMessage(question.title)
    ? intl.formatMessage(question.title)
    : getLabelTranslation(question.title as Label);

  const { value: status, onChange: handleChangeStatus } = useField<YesNoChoice, HTMLElement, ExtraStatus>(
    `${name}.response`,
    {
      subscription: { value: true },
      parse,
      format,
    },
  ).input;

  return (
    questionFilter(question) && (
      <Card
        allowComment
        centerText
        {...question}
        status={status}
        comment={comment}
        onStatusChange={handleChangeStatus}
        onCommentChange={handleChangeComment}
        titleClassName="grid-cols-1 justify-center"
        title={title}
      />
    )
  );
};

const Questions: React.FC<{
  name: FormKeys;
  questionFilter?: (question: QuestionType) => boolean;
}> = ({ name, questionFilter = () => true }) => {
  const { fields: questions } = useFieldArray<QuestionType>(name, { subscription: {} });

  return (
    <>
      {questions.map((path: string) => (
        <Question key={path} name={path} questionFilter={questionFilter} />
      ))}
    </>
  );
};

export default Questions;
