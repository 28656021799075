import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { KeysSafeAction } from 'modules/kiosk/types/KeysSafe';
import { KioskType } from 'modules/dealers/types/ContextResponse';

import authApi from 'modules/auth/service';
import kioskApi from 'modules/kiosk/service';
import { AppStartListening } from 'App/ListenerMiddleware';

import { logout } from 'modules/auth/actions';
import { closeKeysSafe } from 'modules/kiosk/actions';

import { getKioskType } from 'modules/dealers/selectors';

import Camera from 'models/Camera';

import { PARTTEAM_RACKS, WEBCAM_DEVICE_NAMES } from './constants';
import { getDeviceName as getPartteamDeviceName } from './utils/partteam';

const listenerMiddleware = createListenerMiddleware();
const startAppListening = listenerMiddleware.startListening as AppStartListening;

// Start cameras when the user logs in on a matching kiosk type
startAppListening({
  matcher: isAnyOf(
    authApi.endpoints.loginMFA.matchFulfilled,
    authApi.endpoints.loginAdmin.matchFulfilled,
  ),
  effect: (_, { getState }) => {
    const kioskType = getKioskType(getState()) as KioskType;

    switch (kioskType) {
      case KioskType.PARTTEAM: {
        // Start all cameras, so the stream is ready when the user is shown the camera view
        WEBCAM_DEVICE_NAMES[KioskType.PARTTEAM].forEach((deviceName) => {
          const camera = Camera.getInstance(deviceName);
          camera.start();
        });
        break;
      }
      default:
        break;
    }
  },
});

// Stop *unused* cameras after the slot is opened
// Note: This will not be triggered by admin actions because any rack can be opened
startAppListening({
  matcher: kioskApi.endpoints.openSlot.matchFulfilled,
  effect: ({ payload }, { getState }) => {
    const kioskType = getKioskType(getState()) as KioskType;

    switch (kioskType) {
      case KioskType.PARTTEAM: {
        const unsedCameraRacks = PARTTEAM_RACKS.filter((rack) => rack !== payload.rack);
        unsedCameraRacks.forEach((rack) => {
          const camera = Camera.getInstance(getPartteamDeviceName(rack));
          camera.stop();
        });
        break;
      }
      default:
        break;
    }
  },
});

function closeAllCameras(kioskType: KioskType) {
  switch (kioskType) {
    case KioskType.PARTTEAM: {
      WEBCAM_DEVICE_NAMES[kioskType].forEach((deviceName) => {
        const camera = Camera.getInstance(deviceName);
        camera.stop();
      });
      break;
    }
    default:
      break;
  }
}

// Stop all cameras after a logout
startAppListening({
  matcher: logout.match,
  effect: (_, { getState }) => {
    const kioskType = getKioskType(getState()) as KioskType;
    closeAllCameras(kioskType);
  },
});

// Stop all cameras after the doors are closed, except in admin
startAppListening({
  matcher: closeKeysSafe.match,
  effect: ({ payload }, { getState }) => {
    const { asAdmin, emergency } = payload as KeysSafeAction;

    if (!asAdmin && !emergency) {
      const kioskType = getKioskType(getState()) as KioskType;
      closeAllCameras(kioskType);
    }
  },
});

export default listenerMiddleware;
