import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import { Button } from 'components/ui';
import { ReadySelfService } from 'modules/kiosk/types/GetReadySelfServices';
import { getSlotById, getSlotIdBySelfServiceId } from 'modules/kiosk/selectors';

import SlotCamera from '../SlotCamera';

const AdminKeysManagementModalContent: React.FC<ReadySelfService & { onClose: (accepted: boolean) => void }> = ({
  id: selfServiceId,
  registration,
  customerDisplayName,
  onClose,
}) => {
  const slotId = useSelector((state) => getSlotIdBySelfServiceId(state, selfServiceId));
  const { rack } = useSelector((state) => getSlotById(state, slotId));

  const handleClose = () => onClose(true);

  return (
    <>
      <h1>{registration}</h1>
      <div className="text-2xl">
        {`${registration} ${customerDisplayName ? `- ${customerDisplayName}` : ''}`}
      </div>
      <h1 className="mt-10 text-center !leading-normal" data-testid="key-question">
        <FormattedMessage
          id="admin.manage.key.emptyDescription"
          defaultMessage="Please remove all the keys in the slot."
        />
      </h1>
      <SlotCamera rack={rack} className="my-10" />
      <div className="my-16 bg-white rounded-5xl p-10">
        <Button className="min-w-18" onClick={handleClose} testId="key-confirm-btn">
          <FormattedMessage id="confirm.close" defaultMessage="Close door" />
        </Button>
      </div>
    </>
  );
};

export default AdminKeysManagementModalContent;
