import React from 'react';
import classNames from 'classnames';

import { OnClickHandler } from './Item';

export interface MenuItemClickParams {
  key?: React.Key;
  originalEvent: React.MouseEvent<HTMLElement, MouseEvent>;
}

type MenuItemClickEvent = (params: MenuItemClickParams) => void;

export interface MenuProps {
  visible?: boolean;
  className?: string;
  onClick?: MenuItemClickEvent;
}

const Menu: React.FC<React.PropsWithChildren<MenuProps>> = ({
  onClick,
  visible,
  className,
  children,
}) => {
  // Override children click action
  const handleOnClick = React.useCallback(
    (onClickProps: OnClickHandler, key?: React.Key) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      onClick?.({ key, originalEvent: event });
      onClickProps?.(event);
    },
    [onClick],
  );

  return (
    <div
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      className={classNames(
        className,
        { hidden: !visible },
        'origin-top-left absolute mt-2 w-full rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5 ',
      )}
    >
      <div className="py-1" role="none">
        {React.Children.map(children, (child) => (React.isValidElement(child)
          ? React.cloneElement(child, {
            ...child.props,
            onClick: handleOnClick(child.props.onClick, child.key),
          })
          : null))}
      </div>
    </div>
  );
};

export default Menu;
