import { shallowEqual } from 'utils/objectUtils';

import GenericQuestion from 'types/Question';
import type ParkingForm from 'types/ParkingForm';
import type { ExtrasForm } from 'types/ExtrasForm';
import type QuestionForm from 'types/QuestionForm';
import type { RepairOrderForm } from 'types/RepairOrderForm';
import type { CustomerInfoForm } from 'types/CustomerInfoForm';
import type { MobilityFormType } from 'types/MobilityDocumentFormType';
import type { SelfService } from 'modules/selfServices/types/SelfService';
import { KioskPreferencesForm, QuestionsForm } from 'types/QuestionsForm';

import FormKeys from './types/FormKeys';

type ConciliateFunc<T> = (selfService: SelfService, cache?: Partial<T>) => Partial<T>;

const isQuestionsForm = (cache: Partial<KioskPreferencesForm | QuestionsForm> = {}): cache is Partial<QuestionsForm> => FormKeys.QUESTIONS in cache;

const isKioskPreferencesForm = (cache: Partial<KioskPreferencesForm | QuestionsForm> = {}): cache is Partial<KioskPreferencesForm> => FormKeys.KIOSK_USAGE in cache;

const conciliateQuestions = (
  formKey: FormKeys.KIOSK_USAGE | FormKeys.QUESTIONS,
  questions: GenericQuestion[],
  cache: Partial<KioskPreferencesForm | QuestionsForm>,
) => {
  if (!Array.isArray(questions)) {
    return undefined;
  }

  let cacheItems: GenericQuestion[] = [];
  if (isQuestionsForm(cache) && formKey === FormKeys.QUESTIONS) {
    cacheItems = cache[formKey];
  } else if (isKioskPreferencesForm(cache) && formKey === FormKeys.KIOSK_USAGE) {
    cacheItems = cache[formKey];
  }

  return {
    [formKey]: questions.map((question) => {
      const cacheItem = cacheItems?.find(({ id }) => question.id === id);
      const hasChange = cacheItem === undefined
        || cacheItem.mandatory !== question.mandatory
        || !shallowEqual(cacheItem.title as Record<string, unknown>, question.title as Record<string, unknown>)
        || !shallowEqual(cacheItem.description, question.description);

      return {
        ...question,
        comment: hasChange ? question.comment : cacheItem?.comment,
        response: hasChange ? question.response : cacheItem?.response,
      };
    }),
  };
};

const repairOrderConciliate: ConciliateFunc<RepairOrderForm> = (selfService, cache) => ({ medias: selfService.medias, customerComment: selfService.customerComment, ...cache });

const customerInfoConciliate: ConciliateFunc<CustomerInfoForm> = (selfService) => ({
  permanent: 'true',
  ...selfService.customerInfo,
});

const extrasConciliate: ConciliateFunc<ExtrasForm> = (selfService, cache) => {
  const extras = selfService.checklist?.items?.[0]?.advices;
  return Array.isArray(extras) ? {
    extras: extras.map((extra) => {
      const cacheItem = cache?.extras?.find(({ adviceId }) => adviceId === extra.adviceId);
      const hasChange = cacheItem === undefined
        || cacheItem.discountedPrice !== extra.discountedPrice
        || cacheItem.mandatory !== extra.mandatory
        || !shallowEqual(cacheItem.adviceName, extra.adviceName);

      return {
        ...extra,
        status: hasChange ? extra.status : cacheItem.status,
      };
    }),
  } : undefined;
};

const questionsConciliate: ConciliateFunc<QuestionForm> = (selfService, cache) => conciliateQuestions(FormKeys.QUESTIONS, selfService.questionForm?.questions, cache);

const parkingConciliate: ConciliateFunc<ParkingForm> = (selfService, cache) => ({
  ...selfService.parking,
  ...cache,
  mapId: cache?.mapId
    ?? selfService.parking?.mapId
    ?? Object.values(selfService.parkingMap?.selectedMaps ?? {})[0]
    ?? selfService.parkingMap?.maps?.[0]?.id,
});

const mobilityParkingConciliate: ConciliateFunc<ParkingForm> = (selfService, cache) => ({
  ...(selfService?.mobility?.vehicle?.parkingReturn ?? {}),
  ...cache,
  mapId: cache?.mapId
    ?? selfService?.mobility?.vehicle?.parkingReturn?.mapId
    ?? Object.values(selfService.parkingMap?.selectedMaps ?? {})[0]
    ?? selfService.parkingMap?.maps?.[0]?.id,
});

const mobilityConciliate: ConciliateFunc<MobilityFormType> = (selfService, cache) => ({
  ...cache,
  ...selfService?.mobility,
  documents: selfService?.mobility?.documents?.map((document) => {
    const cacheDocument = cache?.documents?.find(({ id }) => document.id === id);
    return cacheDocument?.status !== document.status ? document : cacheDocument;
  }),
});

const kioskPreferencesQuestionsConciliate: ConciliateFunc<KioskPreferencesForm> = (selfService, cache) => conciliateQuestions(FormKeys.KIOSK_USAGE, selfService.kioskPreferencesQuestions, cache);

const Conciliate: Record<`${string}Conciliate`, ConciliateFunc<unknown>> = {
  customerInfoConciliate,
  extrasConciliate,
  questionsConciliate,
  repairOrderConciliate,
  parkingConciliate,
  mobilityParkingConciliate,
  mobilityConciliate,
  kioskPreferencesQuestionsConciliate,
};

export default Conciliate;
