import React from 'react';
import { useIntl } from 'react-intl';
import { useField } from 'react-final-form';

import { useGetLanguagesQuery } from 'modules/translations/service';

import SelectInput from 'components/ui/SelectInput';

const LanguageInput: React.FC = () => {
  const intl = useIntl();
  const { data = [] } = useGetLanguagesQuery();
  const { input } = useField('language', { subscription: { value: true } });

  const languages = data.reduce((acc: { [x:string] : string }, element : string) => ({
    ...acc,
    [element]: intl.formatMessage({ id: `language.${element}`, defaultMessage: element.toUpperCase() }),
  }), {});

  return (
    <SelectInput
      {...input}
      label={intl.formatMessage({ id: 'customerInfo.language', defaultMessage: 'Language' })}
      data={languages}
      data-testid="language-input"
    />
  );
};

export default LanguageInput;
