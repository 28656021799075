import React from 'react';
import { useDispatch } from 'react-redux';
import { FormApi, FormState } from 'final-form';
import { Form, FormProps, FormRenderProps } from 'react-final-form';

import { useSelector } from 'hooks';
import FormKeys from 'modules/form/types/FormKeys';
import { getFormValuesByKey } from 'modules/form/selectors';
import { getLastUpdatedTime } from 'modules/sockets/selectors';

import getDecorator from './decorator';
import useInitialValue from './useInitialValue';

export type ErrorComponentProps = {
  onClose?: () => void;
  formState?: FormState<any>
};

interface ConnectedFormWrapperProps<ChildProps = Record<string, unknown>> extends FormRenderProps {
  formKey: FormKeys;
  componentChild: React.ComponentType<ChildProps>;
  errorComponent?: React.ReactElement<ErrorComponentProps>;
}

const ConnectedFormWrapper:React.FC<ConnectedFormWrapperProps> = ({ errorComponent: ErrorComponent, componentChild: Component, ...props }) => {
  const { form, formKey } = props;

  const [displayError, setDisplayError] = React.useState(false);

  const lastUpdatedTime = useSelector(getLastUpdatedTime);
  const value = useSelector((state) => getFormValuesByKey(state, formKey));

  React.useEffect(() => {
    if (lastUpdatedTime && value) {
      form.initialize(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedTime]);

  const handleSubmit = () => {
    if (ErrorComponent && form.getState().hasValidationErrors) {
      setDisplayError(true);
    } else {
      props.handleSubmit();
    }
  };

  return (
    <>
      <Component {...props} handleSubmit={handleSubmit} />
      {displayError && (
        React.cloneElement(ErrorComponent, { onClose: () => setDisplayError(false), formState: form.getState() })
      )}
    </>
  );
};

interface ConnectedFormProps extends FormProps<any, any> {
  formKey: FormKeys;
}

const ConnectedForm: React.FC<ConnectedFormProps> = ({
  onSubmit, formKey, component, ...props
}) => {
  const dispatch = useDispatch();
  const initialValue = useInitialValue(formKey);
  const decorators = React.useMemo(() => (
    [...props.decorators ?? [], getDecorator<unknown>(formKey, dispatch)]
  ), [formKey, dispatch, props.decorators]);

  // async is required to trigger submitting form subscription in the decorator and thus persist the form on submit
  const handleSubmit = async (values: unknown, form: FormApi<unknown, unknown>) => onSubmit(values, form);

  return (
    <Form
      {...props}
      formKey={formKey}
      decorators={decorators}
      onSubmit={handleSubmit}
      componentChild={component}
      initialValues={initialValue}
      component={ConnectedFormWrapper}
    />
  );
};

export default ConnectedForm;
