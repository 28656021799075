import React from 'react';

import PageBaseProps from 'types/PageBase';
import FormKeys from 'modules/form/types/FormKeys';

import Content from './Content';
import ConnectedForm from '../ui/ConnectedForm';

const BreakdownInfo: React.FC<PageBaseProps> = ({ onNext, onPrev, shouldDisplayBackButton }) => (
  <ConnectedForm
    onPrev={onPrev}
    onSubmit={onNext}
    component={Content}
    keepDirtyOnReinitialize
    formKey={FormKeys.BREAKDOWN_INFO}
    shouldDisplayBackButton={shouldDisplayBackButton}
    subscription={{
      pristine: true, initialValues: true, errors: true, hasValidationErrors: true,
    }}
  />
);

export default BreakdownInfo;
