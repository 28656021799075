import React from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'react-final-form';

import Textarea, { TextareaProps } from '../Textarea';

type FormTextareaProps = Omit<TextareaProps, 'value' | 'onChange'> & {
  name: string
  format?: (value: string, name: string) => string
  parse?: (value: string, name: string) => string
  validate?: (value: string, allValues: unknown) => string | undefined
};

const FormTextarea: React.FC<FormTextareaProps> = ({
  label,
  name,
  rows,
  disabled,
  required,
  format,
  parse,
  validate,
  className,
  description,
  'data-testid': dataTestId,
}) => {
  const intl = useIntl();
  return (
    <Field name={name} subscription={{ value: true, error: true }} format={format} parse={parse} validate={validate}>
      {({ input, meta }) => (
        <Textarea
          label={label}
          value={input.value}
          onChange={input.onChange}
          rows={rows}
          disabled={disabled}
          required={required}
          className={className}
          error={meta.error && intl.formatMessage({ id: meta.error, defaultMessage: meta.error })}
          data-testid={dataTestId}
          description={description}
        />
      )}
    </Field>
  );
};

export default FormTextarea;
