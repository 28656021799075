import { createListenerMiddleware } from '@reduxjs/toolkit';

import { logout } from 'modules/auth/actions';
import { setCurrentStep } from 'modules/steps/actions';
import * as actions from 'modules/selfServices/actions';

import chatApi from 'modules/chat/service';
import { AppStartListening } from 'App/ListenerMiddleware';
import selfServicesApi from 'modules/selfServices/service';

import { ErrorTypeEnum, isApiErrorResponse } from 'types/Error';

import { getFormattedFormData } from 'modules/form/selectors';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { getTotalElapsedTime } from 'modules/elapsedTime/selectors';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

startAppListening({
  actionCreator: actions.finalizeSelfService,
  effect: async ({ payload }, { dispatch, getState }) => {
    const totalElapsedTime = getTotalElapsedTime(getState());
    const selfServiceId = getSelectedSelfServiceId(getState());

    // Get the selfService
    const selfService = await dispatch(selfServicesApi.endpoints.getSelfServiceById.initiate(
      { id: selfServiceId },
    )).unwrap();

    const formattedData = getFormattedFormData(getState(), selfService);
    try {
      await dispatch(selfServicesApi.endpoints.updateSelfService.initiate({
        id: selfServiceId,
        body: {
          ...selfService,
          ...formattedData,
          elapsedTime: totalElapsedTime + payload.time,
        },
      }, { fixedCacheKey: 'UPDATE/SELF_SERVICE' })).unwrap();
      if (payload.shouldReset) {
        dispatch(logout({ doorTimeout: true }));
      } else {
        dispatch(setCurrentStep(payload.nextStep));
      }
    } catch (error) {
      const isWrongStatus = isApiErrorResponse(error) && error.data.errorType === ErrorTypeEnum.WRONG_STATUS;
      if (isWrongStatus) {
        dispatch(setCurrentStep(payload.nextStep));
      }
    }
  },
});

// Re-fetch the final instructions after the chat is read (for the first time) to hide the indicator
startAppListening({
  matcher: chatApi.endpoints.readMessages.matchFulfilled,
  effect: (_, { dispatch, getState }) => {
    const selfServiceId = getSelectedSelfServiceId(getState());
    const finalInstructions = selfServicesApi.endpoints.getFinalInstructions.select({ selfServiceId })(getState());
    if (finalInstructions.data?.unreadCount > 0) {
      dispatch(selfServicesApi.endpoints.getFinalInstructions.initiate({ selfServiceId }, { forceRefetch: true }));
    }
  },
});

export default listenerMiddleware;
