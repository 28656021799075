import React from 'react';
import classNames, { Argument } from 'classnames';

export interface StepIndicatorProps {
  currentIndex: number;
  length: number;
  className?: Argument;
  stepClassName?: Argument;
  'data-testid'?: string;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
  currentIndex,
  length: lengthProp,
  className,
  stepClassName,
  'data-testid': dataTestId = 'steps',
}) => {
  const length = lengthProp > 0 ? lengthProp : 0;
  const steps = React.useMemo(
    () => new Array(length).fill(undefined),
    [length],
  );
  return (
    <div
      className={classNames('flex grow items-center justify-center py-2 px-5', className)}
      data-testid={dataTestId}
    >
      {steps.map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={classNames(
            'step-indicator ease-in duration-300 bg-gray-70/20 rounded-full size-1.5 mx-1.5',
            { '!bg-gray-90 !size-2.5': currentIndex === index },
            stepClassName,
          )}
          data-testid={
            currentIndex === index
              ? `step-selected-${index}`
              : `step-${index}`
          }
        />
      ))}
    </div>
  );
};

export default StepIndicator;
