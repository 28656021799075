import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames, { Argument } from 'classnames';
import { PhotoIcon, XMarkIcon } from '@heroicons/react/24/solid';

import Media, { MediaInterface } from 'types/Media';
import { AbstractMedia, MediaType, RenderOptions } from 'types/AbstractMedia';

import Spinner from './Spinner';
import Image from './Image';
import Video from './Video';
import Document from './Document';

export const DEFAULT_MEDIA_COMPONENTS: Record<MediaType, (
  media: AbstractMedia,
  renderOptions?: RenderOptions,
) => React.ReactNode> = {
  [MediaType.IMAGE]: (
    { path, thumbnailPath },
    { className, thumbnail, watermarkTitle } = {},
  ) => <Image src={thumbnail ? thumbnailPath ?? path : path} className={className} thumbnail={thumbnail} watermarkTitle={watermarkTitle} />,
  [MediaType.VIDEO]: (
    media,
    { className, thumbnail, onSeeking } = {},
  ) => <Video media={media} thumbnail={thumbnail} onSeeking={onSeeking} className={className} />,
  [MediaType.DOCUMENT]: (
    document,
    { thumbnail, onPageChange } = {},
  ) => <Document document={document} thumbnail={thumbnail} onPageChange={onPageChange} />,
};

export interface FileProps extends AbstractMedia {
  className?: Argument;
  onClick?: (media: MediaInterface, event: React.MouseEvent) => void;
  onDelete?: (media: MediaInterface) => void;
}

const File: React.FC<FileProps & Partial<MediaInterface>> = ({
  className,
  onClick,
  onDelete,
  ...mediaProps
}) => {
  const media = new Media(mediaProps);
  const { isLoaded, isLoading, isDeleting } = media;

  const isClickable = Boolean(onClick && isLoaded);

  const handleOnClick = (event: React.MouseEvent) => {
    if (isClickable) {
      onClick(media, event);
    }
  };

  const handleOnDelete = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!isDeleting) {
      onDelete(media);
    }
  };

  return (
    <div
      onClick={handleOnClick}
      data-testid={`file-${media.path}`}
      className={classNames(
        'relative size-16 kiosk:size-36 flex rounded-lg kiosk:rounded-2xl bg-secondary-darken-30 overflow-hidden z-0',
        { 'cursor-pointer': isClickable },
        className,
      )}
    >
      {onDelete && (
        <XMarkIcon
          onClick={handleOnDelete}
          // eslint-disable-next-line max-len
          className="absolute top-1 right-1 kiosk:right-3 kiosk:top-3 size-4 kiosk:size-8 cursor-pointer text-white backdrop-blur-sm bg-black/5 rounded-full z-30"
        />
      )}
      {isLoading && <Spinner />}
      {!isLoading && !isLoaded && (
        <div className="m-auto text-center text-white text-xs">
          <PhotoIcon className="size-8 m-auto kiosk:size-16" />
          <FormattedMessage id="media.thumbnailUnavailable" defaultMessage="Thumbnail unavailable" />
        </div>
      )}
      {isLoaded && DEFAULT_MEDIA_COMPONENTS[media.type](
        media,
        { className: 'w-full !bg-cover', thumbnail: true },
      )}
    </div>
  );
};

export default File;
