import { createReducer, EntityState } from '@reduxjs/toolkit';

import kioskApi from 'modules/kiosk/service';
import { selfServicesAdapter } from 'modules/kiosk/reducers/adapters';

import type { ReadySelfService } from 'modules/kiosk/types/GetReadySelfServices';

type InitialState = EntityState<ReadySelfService, string> & { loading: boolean; };

const selfServicesReducer = createReducer<InitialState>(
  selfServicesAdapter.getInitialState({ loading: false }),
  (builder) => {
    builder
      .addMatcher(
        kioskApi.endpoints.getReadySelfServices.matchPending,
        (state) => ({ ...state, loading: true }),
      )
      .addMatcher(
        kioskApi.endpoints.getReadySelfServices.matchFulfilled,
        (state, action) => selfServicesAdapter.setAll({ ...state, loading: false }, action),
      );
  },
);

export default selfServicesReducer;
