import React, { Suspense } from 'react';
import { FormattedMessage } from 'react-intl';

import type { AbstractMedia, RenderOptions } from 'types/AbstractMedia';

import Spinner from '../Spinner';
import ErrorBoundary from '../ErrorBoundary';
import { PdfViewerProps } from '../PDFViewer';
import FileTypeIcon from './FileTypeIcon';

// Use React.lazy to load the component only when needed and avoid error on start on IOS 12
// The error is managed in the ErrorBoundary component
const PdfViewer = React.lazy(() => import('../PDFViewer/index'));

type DocumentProps = {
  document: AbstractMedia,
} & Pick<RenderOptions, 'thumbnail'> & Omit<PdfViewerProps, 'path'>;

const Document: React.FC<DocumentProps> = ({
  document, thumbnail, canvasRef, onPageChange,
}) => (
  <>
    {thumbnail && <FileTypeIcon filename={document.filename} />}
    {!thumbnail && (
      <ErrorBoundary
        errorComponent={(
          <div className="text-white">
            <FormattedMessage id="media.errorMessage" defaultMessage="This type of file is not allowed" />
          </div>
        )}
      >
        <Suspense fallback={<Spinner />}>
          <PdfViewer path={document.path} canvasRef={canvasRef} onPageChange={onPageChange} />
        </Suspense>
      </ErrorBoundary>
    )}
  </>
);

export default Document;
