import { SELF_SERVICE } from 'constants/url';

import { FinalInvoice } from 'modules/selfServices/types/SelfService';

import api from 'service';

const onlinePaymentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFinalInvoiceStatus: build.query<FinalInvoice, { selfServiceId: string }>({
      query: ({ selfServiceId }) => ({
        method: 'GET',
        url: `${SELF_SERVICE}/${selfServiceId}/finalInvoice/status`,
      }),
    }),
  }),

});

export const { useGetFinalInvoiceStatusQuery } = onlinePaymentApi;

export default onlinePaymentApi;
