import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, FormattedTime } from 'react-intl';

import { isOwner } from 'modules/chat/utils';
import { Message } from 'modules/chat/types/Message';

interface MessageContentProps extends Message {
  isFirstUnread?: boolean;
  showAuthor?: boolean;
  showFooter?: boolean;
}

const MessageContent: React.FC<MessageContentProps> = ({
  id,
  content,
  createdBy,
  channel,
  createdOn,
  showAuthor,
  showFooter,
  isFirstUnread,
}) => {
  const isCustomer = isOwner(createdBy);
  const flexPosition = isCustomer ? 'justify-end mr-2' : 'justify-start';

  return (
    <div id={id}>
      {isFirstUnread && (
        <div className="flex items-center">
          <span className="text-info font-semibold my-6 mr-2">
            <FormattedMessage id="new" defaultMessage="New" />
          </span>
          <div className="h-px w-full bg-info" />
        </div>
      )}
      {showAuthor && !createdBy?.scheduler && (
        <span className={classNames('flex items-center font-bold my-4', flexPosition)}>
          {isCustomer ? <FormattedMessage id="you" defaultMessage="You" /> : createdBy?.displayName}
        </span>
      )}
      <div data-testid={`message-${id}`} className={classNames('flex', flexPosition)}>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className={classNames('overflow-hidden whitespace-pre-wrap break-words max-w-full rounded-3xl px-5 py-2', {
            'bg-info text-white': isCustomer,
            'bg-secondary': !isCustomer,
          })}
        />
      </div>
      {showFooter && (
        <div className={classNames('flex mt-2', flexPosition)}>
          {channel && (
            <>
              <FormattedMessage id={`channel.${channel}`} defaultMessage={channel} />
              {' - '}
            </>
          )}
          <FormattedTime value={new Date(createdOn)} />
        </div>
      )}
    </div>
  );
};

export default MessageContent;
