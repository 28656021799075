import { Argument } from 'classnames';

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

export interface RenderOptions {
  className?: Argument;
  thumbnail?: boolean;
  onPreview?: () => void;
  onPageChange?: (pageNumber: number, totalPages: number) => void;
  watermarkTitle?: string;
  onSeeking?: () => void;
}

export interface AbstractMedia {
  id?: string;
  path: string;
  type: MediaType;
  mediaMetricUrl?: string;
  uploadId?: string;
  filename?: string;
  restricted?: boolean;
  contentType?: string;
  originalPath?: string;
  thumbnailPath?: string;
}
