import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { newIncomingMessage } from 'modules/sockets/actions';

import { IncomingMessage } from 'modules/sockets/types/SocketResponse';

import chatApi from './service';
import { messagesAdapter } from './adapters';

import { linkifyMessage } from './utils';
import type { Message } from './types/Message';
import type InitialState from './types/InitialState';

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    page: undefined,
    messages: messagesAdapter.getInitialState(),
  } as InitialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<number>) => ({
      ...state,
      page: {
        ...state.page,
        number: action.payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      chatApi.endpoints.getMessages.matchFulfilled,
      (state, action) => ({
        page: action.payload.page,
        messages: (action.payload.page.number === 0
          ? messagesAdapter.setAll({ ...state.messages }, action.payload.content)
          : messagesAdapter.upsertMany({ ...state.messages }, action.payload.content)),
      }),
    );

    const prependMessage = (
      state: InitialState,
      action: PayloadAction<Message | IncomingMessage>,
    ) => {
      let newMessage = action.payload as Message;
      if ('data' in action.payload) {
        newMessage = action.payload.data.message;
      }

      const messages = messagesAdapter.addOne(
        { ...state.messages },
        { ...newMessage, content: linkifyMessage(newMessage) },
      );

      // Move the new message to the top
      const ids = [...messages.ids];
      ids.pop();
      ids.unshift(newMessage.id);
      messages.ids = ids;

      return {
        ...state,
        messages,
      };
    };

    builder.addMatcher(newIncomingMessage.match, prependMessage);
    builder.addMatcher(chatApi.endpoints.addMessage.matchFulfilled, prependMessage);

    builder.addMatcher(chatApi.endpoints.readMessages.matchFulfilled, (state) => ({
      ...state,
      messages: messagesAdapter.updateMany(
        { ...state.messages },
        state.messages.ids.map((id) => ({
          id,
          changes: { unread: false },
        })),
      ),
    }));
  },
});

export default chatSlice;
