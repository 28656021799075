import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from 'hooks';

import { logout } from 'modules/auth/actions';
import { shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector } from 'modules/dealers/selectors';

import { br } from 'utils/i18nUtils';

import NoWifi from 'assets/icons/noWifi.svg';

import Modal from './Modal';
import Button from './Button';
import DealersPhone from './DealersPhone';

export const TIMEOUT = 300000; // 5 minutes

const OfflineModal = () => {
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLButtonElement>();

  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(logout());
    }, TIMEOUT);

    return () => clearTimeout(timeoutId);
  }, [dispatch]);

  const handleRedirect = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Modal focusRef={ref} open className="w-full max-w-md kiosk:max-w-4xl kioskSharebox:max-w-2xl" title={<FormattedMessage id="offline.feedback" defaultMessage="No internet connection" tagName="h2" />}>
      <div className="flex flex-col items-center">
        <div className="rounded-full size-80 bg-warn-bg/20 flex items-center justify-center mt-1">
          <div className="rounded-full size-40 bg-warn-bg flex items-center justify-center">
            <NoWifi className="absolute w-12 aspect-square" />
          </div>
        </div>
        <p className="text-center mt-10">
          <FormattedMessage
            id="offline.description"
            defaultMessage="If the internet connection cannot be restored, we regret to inform you that it is no longer possible to use this kiosk.{br}If the problem persists, do not hesitate to contact us."
            values={{
              br,
            }}
          />
        </p>
        {shouldDisplayPhoneNumbers && <DealersPhone />}
      </div>
      <Button ref={ref} onClick={handleRedirect} className="w-full mt-10" testId="close">
        <FormattedMessage id="header.close" defaultMessage="Close" />
      </Button>
    </Modal>
  );
};

export default OfflineModal;
