import api from 'service/index';
import { TRANSLATIONS } from 'constants/url';
import { getUrlParam } from 'utils/urlUtils';
import type { I18nCollection } from 'modules/translations/types/InitialState';
import type { TranslationRequestParams } from 'modules/translations/types/TranslationsRequest';

const translationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query<Array<string>, void>({
      query: () => `${new URL(process.env.BASE_URL).origin}/refdata/languages`,
    }),
    getTranslationsByLanguage: builder.mutation<
    I18nCollection,
    TranslationRequestParams
    >({
      query: (params) => {
        const token = getUrlParam('token');
        return {
          method: 'GET',
          url: TRANSLATIONS,
          params: { ...params, token: token ?? undefined },
        };
      },
    }),
  }),
});

export const { useGetTranslationsByLanguageMutation, useGetLanguagesQuery } = translationsApi;

export default translationsApi;
