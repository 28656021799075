import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from './Modal';
import Button, { ButtonType, ButtonProps } from './Button';

interface ConfirmProps {
  open: boolean;
  title: ReactNode;
  question?: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  hideCloseButton?: boolean;
  focusCancel?: boolean;
  cancel?: ReactNode;
  confirm?: ReactNode;
  cancelProps?: ButtonProps;
  confirmProps?: ButtonProps;
}

const Confirm: React.FC<ConfirmProps> = ({
  open,
  title,
  question,
  onCancel,
  onConfirm,
  focusCancel,
  cancelProps,
  confirmProps,
  hideCloseButton,
  cancel = <FormattedMessage id="cancel" defaultMessage="Cancel" />,
  confirm = <FormattedMessage id="confirm.title" defaultMessage="Confirm" />,
}) => {
  const confirmRef = React.useRef<HTMLButtonElement>();
  const cancelRef = React.useRef<HTMLButtonElement>();

  return (
    <Modal
      open={open}
      title={title}
      onClose={onCancel}
      outsideClickClose={false}
      focusRef={focusCancel ? cancelRef : confirmRef}
      hideCloseButton={hideCloseButton}
      className="max-w-3xl pt-8 opacity-100 scale-100"
    >
      <div className="flex flex-col items-center text-center mt-5 kiosk:mt-10">
        {question}
        <div className="flex flex-col kiosk:flex-row w-full mt-4 kiosk:mt-10">
          <Button
            ref={cancelRef}
            onClick={onCancel}
            type={ButtonType.SECONDARY}
            className="w-full mt-5 mb-3 kiosk:mr-5 kiosk:my-0"
            testId="cancel"
            {...cancelProps}
          >
            {cancel}
          </Button>
          <Button
            ref={confirmRef}
            testId="confirm"
            onClick={onConfirm}
            className="w-full kiosk:ml-5"
            {...confirmProps}
          >
            {confirm}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Confirm;
