import React from 'react';
import Linkify from 'linkify-react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { setPageNumber } from 'modules/chat/actions';

import { useGetMessagesQuery, useReadMessagesMutation } from 'modules/chat/service';

import {
  getPageNumber, getSortedMessages, getTotalPages, haveUnreadMessages as haveUnreadMessagesSelector,
} from 'modules/chat/selectors';

import { Spinner } from 'components/ui';

type MessageListProps = {
  selfServiceId: string;
};

const PAGE_SIZE = 10;

/**
 * Component that displays a list of messages fetched from the API.
 * This *needs* to be a component to allow refetching messages when it's re-mounted.
 */
const MessageList: React.FC<MessageListProps> = ({
  selfServiceId,
}) => {
  const dispatch = useDispatch();

  const lastMessageId = React.useRef<string>();

  const pageNumber = useSelector(getPageNumber);
  const totalPages = useSelector(getTotalPages);
  const messages = useSelector(getSortedMessages);
  const haveUnreadMessages = useSelector(haveUnreadMessagesSelector);

  const [readMessages, { isLoading }] = useReadMessagesMutation();
  const { isFetching, isUninitialized } = useGetMessagesQuery(
    { selfServiceId, page: pageNumber, size: PAGE_SIZE },
    { refetchOnMountOrArgChange: true },
  );

  const hasNextPage = pageNumber < totalPages - 1;

  const handleLoadMore = React.useCallback(() => dispatch(setPageNumber(pageNumber + 1)), [dispatch, pageNumber]);

  const [loadMoreRef, { rootRef }] = useInfiniteScroll({
    hasNextPage,
    loading: isFetching,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 24px 0px',
  });

  // Read messages when scrolling to the bottom
  const handleOnScroll = React.useCallback(({ target }: React.UIEvent<HTMLDivElement>) => {
    if (haveUnreadMessages && !isLoading && target instanceof HTMLElement) {
      const scrollPosition = target.scrollTop + target.clientHeight;
      if (scrollPosition >= target.scrollHeight - 24) {
        readMessages(selfServiceId);
      }
    }
  }, [haveUnreadMessages, isLoading, readMessages, selfServiceId]);

  // Read messages on mount
  React.useEffect(() => {
    const timeoutId = setTimeout(() => readMessages(selfServiceId), 1000);
    return () => clearTimeout(timeoutId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scroll to first fetched message (infinite scroll)
  React.useLayoutEffect(() => {
    if (!isFetching && messages.length > 0) {
      lastMessageId.current = messages[messages.length - 1].id;

      const { id } = messages[messages.length >= PAGE_SIZE - 1 ? PAGE_SIZE - 2 : messages.length - 1];
      requestAnimationFrame(() => document.getElementById(id)?.scrollIntoView());
    }
  // We only listen to `isFetching` toggling to avoid reacting on websocket/sent messages
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  // Show new message dot when a new message is received or scroll to bottom if message was sent by the user
  React.useLayoutEffect(() => {
    const latestMessage = messages[messages.length - 1];
    if (
      lastMessageId.current
      && lastMessageId.current !== latestMessage?.id
      && latestMessage.createdBy?.onCustomerBehalf
    ) {
      requestAnimationFrame(() => document.getElementById(latestMessage.id)?.scrollIntoView());
    }
  }, [messages]);

  return (
    <div ref={rootRef} onScroll={handleOnScroll} className="mt-4 py-1 px-2 flex flex-col gap-2 h-full overflow-y-auto">
      {(isUninitialized || isFetching || hasNextPage) && (
        <div>
          <Spinner data-testid="chat-loader" ref={loadMoreRef} />
        </div>
      )}
      {messages?.map(({ id, content, createdBy }) => (
        <div
          id={id}
          key={id}
          data-testid={`message-${id}`}
          className={classNames('flex', {
            'justify-end': createdBy && createdBy.onCustomerBehalf,
            'justify-start': !createdBy?.onCustomerBehalf,
          })}
        >
          <div
            className={classNames('overflow-hidden whitespace-pre-wrap break-words max-w-full rounded-3xl px-5 py-2', {
              'bg-info text-white': createdBy && createdBy.onCustomerBehalf,
              'bg-secondary': !createdBy?.onCustomerBehalf,
            })}
          >
            <Linkify options={{ target: { url: '_blank' } }}>
              {content}
            </Linkify>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MessageList;
