import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';
import { Extension, MediaMimeType, MediaSupport } from 'constants/mediaTypes';

// eslint-disable-next-line no-useless-escape,max-len
const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;

export const required = <T>(value: T) => (typeof value !== 'boolean' && !value ? 'validation.required' : undefined);

export const validateEmail = (email: string) => {
  const pattern = RegExp(EMAIL_VALIDATION_REGEX);
  return !email || pattern.test(email) ? undefined : 'validation.email';
};

export const formatToArray = <T>(value: Array<T> = []) => value ?? [];

export const formatUndefined = <T>(value: T) => value;

export const round = (value:number, decimals = 2) => {
  const powerDecimal = 10 ** decimals;
  return Math.round(value * powerDecimal) / powerDecimal;
};

export const isAuthorizedFile = (file:File, acceptor?: MediaSupport): boolean => {
  if (acceptor) {
    const extension:Extension = `.${file.name.split('.').pop()}`;
    return (acceptor.types?.includes(file.type as MediaMimeType) ?? true)
    && (acceptor.extensions?.includes(extension.toLowerCase() as Extension) ?? true);
  }
  return true;
};

export const formatSelectData = (data: Record<string, string>, intl: IntlShape, key: string) : Record<string, ReactNode> => Object.values(data).reduce(
  (acc, item) => ({ ...acc, [item]: intl.formatMessage({ id: `${key}.${item}`, defaultMessage: item }) }),
  {},
);
