import React from 'react';
import { useIntl } from 'react-intl';
import { useField } from 'react-final-form';

import Civilities from 'constants/civilities';

import SelectInput from 'components/ui/SelectInput';

const CivilityInput: React.FC = () => {
  const intl = useIntl();
  const { input } = useField('civility', { subscription: { value: true } });

  const data = Object.keys(Civilities).reduce< { [key in Civilities]? : string }>((acc, civility : string) => ({
    ...acc,
    [civility]: intl.formatMessage({ id: `civility.${civility}`, defaultMessage: civility.toUpperCase() }),
  }), {});

  return (
    <SelectInput
      {...input}
      label={intl.formatMessage({ id: 'customerInfo.civility', defaultMessage: 'Civility' })}
      data={data}
      data-testid="civility-input"
    />
  );
};

export default CivilityInput;
