import { SelfServiceOrigin } from 'modules/selfServices/types/SelfService';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import {
  KEY,
  SIGNATURE,
  REMOTE_KEY,
  isNotAnswered,
  SelfServiceStep,
  FINAL_INSTRUCTIONS,
  PARKING as COMMON_PARKING,
  QUESTIONS as COMMON_QUESTIONS,
} from './common';
import BreakdownInfo from '../../BreakdownInfo';

const BREAKDOWN_INFO: SelfServiceStep = {
  key: SelfServiceSteps.BREAKDOWN_INFO,
  apiKey: SelfServiceAPISteps.CONTACT_INFO,
  component: BreakdownInfo,
  next: SelfServiceSteps.QUESTIONS,
  data: {
    preventBrowserNavigation: true,
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

const QUESTIONS: SelfServiceStep = { ...COMMON_QUESTIONS, previous: BREAKDOWN_INFO.key, next: COMMON_PARKING.key };
const PARKING: SelfServiceStep = { ...COMMON_PARKING, previous: COMMON_QUESTIONS.key, next: SIGNATURE.key };

const ON_SITE_BREAKDOWN_STEPS: Record<string, SelfServiceStep> = {
  BREAKDOWN_INFO,
  QUESTIONS,
  PARKING,
  SIGNATURE: {
    ...SIGNATURE,
    previous: PARKING.key,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...SIGNATURE.data, isFinalizeStep: true },
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: SIGNATURE.key },
};

const KIOSK_BREAKDOWN_STEPS: Record<string, SelfServiceStep> = {
  BREAKDOWN_INFO,
  QUESTIONS,
  PARKING,
  SIGNATURE: { ...SIGNATURE, previous: PARKING.key, next: KEY.key },
  KEY: {
    ...KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...KEY.data, isFinalizeStep: true },
  },
  REMOTE_KEY: {
    ...REMOTE_KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...REMOTE_KEY.data, isFinalizeStep: true },
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: KEY.key },
};

export default {
  [SelfServiceOrigin.ON_SITE]: ON_SITE_BREAKDOWN_STEPS,
  [SelfServiceOrigin.KIOSK]: KIOSK_BREAKDOWN_STEPS,
};
