import React from 'react';
import classNames, { Argument } from 'classnames';

import { isLowPerfMode } from 'modules/app/selectors';

import MediaLoader from './MediaLoader';
import { useSelector } from '../../hooks';

interface ImageProps {
  src: string;
  thumbnail?: boolean;
  className?: Argument;
  watermarkTitle?: string;
}

const Image: React.FC<ImageProps> = ({
  src, thumbnail, className, watermarkTitle,
}) => {
  const lowPerfMode = useSelector(isLowPerfMode);

  const [rotation, setRotation] = React.useState(45);
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  React.useEffect(() => {
    setIsImageLoaded(false);
    const img = new window.Image();

    const imgLoad = () => {
      if (watermarkTitle) {
        const { height, width } = img;
        setRotation((Math.atan(height / width) * 180) / Math.PI);
      }
      setIsImageLoaded(true);
    };
    img.addEventListener('load', imgLoad, { once: true });
    img.src = src;

    return () => img.removeEventListener('load', imgLoad);
  }, [src, watermarkTitle]);

  const styleAttribute = React.useMemo(
    () => ({
      '--tw-rotate': `-${rotation}deg`,
      '--tw-translate-x': '-50%',
      '--tw-translate-y': '-50%',
    } as React.CSSProperties),
    [rotation],
  );

  const backgroundStyle = React.useMemo(
    () => ({ backgroundImage: `url("${src}")` } as React.CSSProperties),
    [src],
  );

  return (
    <>
      {!isImageLoaded && <MediaLoader displaySpinner={!thumbnail || lowPerfMode} />}
      {isImageLoaded && (
        <>
          {thumbnail && (
            <>
              {!lowPerfMode && (
                <div
                  className="bg-origin-padding bg-center bg-no-repeat bg-120 blur-2xl absolute inset-0 z-0 h-full"
                  style={backgroundStyle}
                />
              )}
              <div
                className={classNames(
                  'relative bg-origin-padding bg-center bg-no-repeat bg-contain h-full z-10',
                  className,
                )}
                style={backgroundStyle}
              />
            </>
          )}
          {!thumbnail && (
            <div className={classNames({ relative: !!watermarkTitle })}>
              <img src={src} alt="" className="max-h-full max-w-full" />
              {watermarkTitle && (
                <span
                  // eslint-disable-next-line max-len
                  className="text-nowrap absolute transform text-6xl top-1/2 left-1/2 font-bold uppercase text-white opacity-40 drop-shadow-watermark"
                  style={styleAttribute}
                >
                  {watermarkTitle}
                </span>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Image;
