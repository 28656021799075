import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormRenderProps } from 'react-final-form';

import { useSelector } from 'hooks';
import PageBaseProps from 'types/PageBase';
import GenericQuestion from 'types/Question';
import PageFooter from 'components/ui/PageFooter';
import FormKeys from 'modules/form/types/FormKeys';
import Questions from 'components/Questions/Questions';
import { getStepsConfig } from 'modules/form/selectors';
import { QuestionsForm as QuestionsFormType } from 'types/QuestionsForm';
import { getOrigin, getSelfServiceType } from 'modules/dealers/selectors';
import { KioskPreferencesQuestionsIds } from 'modules/selfServices/constants';
import { isKioskPreferenceQuestionVisible } from 'modules/selfServices/utils';

const KioskUsageRender: React.FC<
FormRenderProps<QuestionsFormType> & Pick<PageBaseProps, 'onPrev'> & { formKey: FormKeys }
> = ({
  onPrev, formKey, handleSubmit, hasValidationErrors,
}) => {
  const config = useSelector(getStepsConfig);
  const origin = useSelector(getOrigin);
  const type = useSelector(getSelfServiceType);

  const isQuestionVisible = (question: GenericQuestion) =>
    isKioskPreferenceQuestionVisible(question.id as KioskPreferencesQuestionsIds, type, origin, config);

  return (
    <div className="main-content max-w-3xl">
      <div className="content">
        <div className="flex flex-col items-center">
          <FormattedMessage id="page.kioskUsage.title" defaultMessage="Kiosk usage" tagName="h1" />
          <span className="mt-5 text-center">
            <FormattedMessage
              id="page.kioskUsage.description"
              defaultMessage="You can use our kiosk to retrieve your keys 24/7."
            />
          </span>
        </div>
        <Questions name={formKey} questionFilter={isQuestionVisible} />
        <div className="text-center">
          <FormattedMessage
            id="page.kioskUsage.footer"
            defaultMessage="Your dealership will do their best to respect your wishes. You may change you mind by contacting your dealership."
            tagName="span"
          />
        </div>
      </div>
      <PageFooter onNext={handleSubmit} onPrev={onPrev} disabledStyle={hasValidationErrors} />
    </div>
  );
};

export default KioskUsageRender;
