import { Slot } from 'modules/kiosk/types/Slot';
import { KeyType } from 'modules/kiosk/types/GetReadySelfServices';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { KeyStatus, Kiosk } from './Kiosk';

export interface OpenKeysSafeRequest {
  kioskId: string;
  selfServiceId: string;
  slotId?: string;
  token?: string;
  emergency?: boolean;
  doorTimeout?: boolean;
}

export interface OpenKeysSafeResponse extends Pick<Slot, 'id' | 'rack' | 'position' | 'finalized'> {
  selfServiceId?: string;
  kiosk: Pick<Kiosk, 'id' | 'name'>;
}

export interface CloseKeysSafeRequest extends OpenKeysSafeRequest {
  status?: KeyStatus;
}

export interface KeysSafeAction {
  selfServiceId?: string;
  selfServiceType?: SelfServiceType;
  asAdmin?: boolean;
  emergency?: boolean;
  slotId?: string;
  keyStatus?: KeyStatus;
  keyType?: KeyType;
}

export interface CloseSafeAction extends KeysSafeAction {
  hasAccepted?: boolean;
}

export enum DoorsStatus {
  PENDING = 'PENDING',
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
  OPENING = 'OPENING',
  CLOSING = 'CLOSING',
}
