export type Extension = `.${string}`;
export type MediaMimeType = `${string}/${string}`;

export interface MediaSupport {
  extensions?: Array<Extension>,
  types?: Array<MediaMimeType>
}

const GENERIC_IMAGE_SUPPORT: MediaSupport = {
  extensions: ['.jpeg', '.jpg', '.png'],
  types: [
    'image/bmp',
    'image/jpeg',
    'image/png',
  ],
};

const GENERIC_VIDEO_SUPPORT: MediaSupport = {
  extensions: ['.mp4', '.mov'],
  types: [
    'video/mp4',
    'video/quicktime',
  ],
};

export const SUPPORTED_MEDIAS: Record<string, MediaSupport> = {
  REPAIR_ORDER: {
    extensions: ['.jpeg', '.jpg', '.png', '.mp4', '.mov'],
    types: [
      'image/bmp',
      'image/jpeg',
      'image/png',
      'video/mp4',
      'video/quicktime',
    ],
  },
  MOBILITY_DOCUMENT: GENERIC_IMAGE_SUPPORT,
  VEHICLE_CHECK_IMAGE: GENERIC_IMAGE_SUPPORT,
  VEHICLE_CHECK_VIDEO: GENERIC_VIDEO_SUPPORT,
};
