import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { isLocalKiosk } from 'modules/dealers/selectors';

import Car from 'assets/icons/car.svg';
import Phone from 'assets/icons/phone.svg';
import HandKey from 'assets/icons/handKey.svg';
import ParkedCar from 'assets/icons/parkedCar.svg';
import DocumentCar from 'assets/icons/documentCar.svg';

import { PageHeader } from '../ui';
import Footer from '../ui/PageFooter';
import PageBaseProps from '../../types/PageBase';

const instructions = [
  {
    key: 'PARKED_CAR',
    icon: ParkedCar,
    title: {
      id: 'breakdownService.instructions.parkVehicle',
      defaultMessage: 'Park the vehicle',
    },
  }, {
    key: 'CAR',
    icon: Car,
    title: {
      id: 'breakdownService.instructions.rememberPlate',
      defaultMessage: 'Remember the licence plate',
    },
  }, {
    key: 'DOCUMENT_CAR',
    icon: DocumentCar,
    title: {
      id: 'breakdownService.instructions.carDocument',
      defaultMessage: 'Make sure the vehicle documents are inside the vehicle',
    },
  }, {
    key: 'HAND_KEY',
    icon: HandKey,
    title: {
      id: 'breakdownService.instructions.keyReady',
      defaultMessage: 'Take the keys of the vehicle',
    },
  }, {
    key: 'PHONE',
    icon: Phone,
    title: {
      id: 'breakdownService.instructions.phone',
      defaultMessage: 'Take your cell phone',
    },
  }];

const BreakdownInstructions:React.FC<PageBaseProps> = ({ onNext }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const isKiosk = useSelector(isLocalKiosk);

  const handleOnPrev = React.useCallback(() => {
    navigate(`/${search}`);
  }, [navigate, search]);

  return (
    <>
      <PageHeader hideSteps hidePrevButton={isKiosk} onPrev={handleOnPrev} />
      <div className="main-content">
        <div className="flex flex-col items-center">
          <FormattedMessage
            id="home.breakdown"
            defaultMessage="No appointment"
            tagName="h1"
          />
          <h3 className="mt-10 kiosk:mt-20 kioskSharebox:mt-10">
            <FormattedMessage
              id="breakdownService.beforeStarting"
              defaultMessage="Before starting"
            />
          </h3>
          <div className="mt-2 kiosk:mt-4 mb-10 kiosk:mb-20 kioskSharebox:mb-10 kiosk:px-24 kioskSharebox:px-0 kioskSharebox:grid kioskSharebox:grid-cols-2 kioskSharebox:gap-8">
            {instructions.map(({ icon: Icon, title, key }) => (
              <div className="flex flex-row items-center py-6  kiosk:py-16 kioskSharebox:py-6 border-b last:border-b-0" key={key}>
                <div className="bg-secondary rounded-2xl p-4">
                  <Icon className="size-8 kiosk:size-16 fill-gray-90" />
                </div>
                <p className="ml-5 kiosk:ml-10 font-medium text-gray-70">
                  <FormattedMessage {...title} />
                </p>
              </div>
            ))}
          </div>
        </div>
        <Footer
          onNext={onNext}
          onPrev={handleOnPrev}
          shouldDisplayBackButton
        />
      </div>
    </>
  );
};

export default BreakdownInstructions;
