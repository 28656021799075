import classNames from 'classnames';
import React from 'react';

export type OnClickHandler = (
  event: React.MouseEvent<HTMLElement, MouseEvent>
) => void;

interface MenuItemProps {
  key?: React.Key;
  onClick?: OnClickHandler;
  className?: string;
}

const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
  key,
  onClick,
  children,
  className,
}) => {
  const handleOnClick: React.MouseEventHandler<HTMLElement> = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onClick?.(event);
    },
    [onClick],
  );

  return (
    <span
      key={key}
      tabIndex={0}
      role="menuitem"
      onClick={handleOnClick}
      className={classNames(
        className,
        'text-gray-700 block px-2 py-2 hover:bg-gray-50 cursor-pointer text-sm kiosk:text-xl kiosk:py-4',
      )}
    >
      {children}
    </span>
  );
};

export default MenuItem;
