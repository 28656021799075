import React, { ReactNode } from 'react';
import classNames, { Argument } from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/solid';

import Spinner from './Spinner';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> {
  center?: boolean;
  label?: ReactNode;
  value: string | number;
  loading?: boolean;
  onClear?: () => void;
  type?: React.HTMLInputTypeAttribute;
  disabled?: boolean;
  required?: boolean;
  error?: React.ReactNode | boolean;
  className?: Argument;
  icon?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  'data-testid'?: string;
  inputClassName?: Argument;
  labelClassName?: Argument;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  center,
  label,
  onChange,
  onClear,
  onKeyDown,
  value,
  className,
  loading,
  icon,
  required,
  disabled,
  type,
  error,
  'data-testid': dataTestId,
  inputClassName,
  labelClassName,
  ...rest
}, ref) => {
  const id = React.useId();
  const inputRef = React.useRef<HTMLInputElement>();
  const refToUse = ref ?? inputRef;
  const hideErrorMessage = !error || typeof error === 'boolean';

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && 'current' in refToUse) {
      refToUse.current.blur();
    }
    onKeyDown?.(event);
  };

  const displayIcon = Boolean(icon || loading || onClear);
  const hasValue = value && String(value).length > 0;

  return (
    <div
      className={classNames(
        'relative z-0 group rounded-lg kiosk:rounded-2xl',
        className,
      )}
    >
      <input
        id={id}
        ref={refToUse}
        type={type}
        value={value}
        placeholder=" "
        onChange={onChange}
        required={required}
        disabled={disabled}
        className={classNames('input peer bg-input-bg', {
          'shadow-input-error focus:shadow-input-error': error,
          'kiosk:pr-20': Boolean(icon),
          'text-center': center,
          'pt-8': !label,
        }, inputClassName)}
        data-testid={dataTestId}
        autoComplete="off"
        {...rest}
        onKeyDown={handleKeyDown}
      />
      {label && (
        <label className={classNames('input-label', { 'text-error': error }, labelClassName)} htmlFor={id}>
          {label}
        </label>
      )}
      {displayIcon && (
        <div className="absolute top-5 right-5 kiosk:top-11 kiosk:right-10">
          {loading && <Spinner />}
          {!loading && (
            <>
              {Boolean(onClear) && hasValue && (
                <div data-testId="input-clear-btn" onClick={onClear} className="size-7 -mr-1.5 -mt-1.5 kiosk:w-16 kiosk:h-16 kiosk:-mt-4 kiosk:-mr-4 cursor-pointer flex justify-between items-center">
                  <XMarkIcon className="m-auto size-3.5 kiosk:w-8 kiosk:h-8" />
                </div>
              )}
              {!hasValue && icon}
            </>
          )}
        </div>
      )}
      {!hideErrorMessage && (
        <span
          className={classNames(
            'text-error px-1 text-xs kiosk:text-xl block !mt-2.5 kiosk:mt-5',
            { 'text-center': center },
          )}
          data-testid="error-text"
        >
          {error}
        </span>
      )}
    </div>
  );
});

export default Input;
