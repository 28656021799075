export enum MediaEntityType {
  MOBILITY = 'MOBILITY',
  SELF_SERVICE = 'SELF_SERVICE',
  VEHICLE_CHECK = 'VEHICLE_CHECK',
}

export enum MediaSubType {
  DOCUMENT_MEDIA = 'DOCUMENT_MEDIA',
  ITEM_RESPONSE_MEDIA = 'ITEM_RESPONSE_MEDIA',
  MEDIAS = 'MEDIAS',
}
