import React from 'react';
import classNames from 'classnames';

import type { Slot as SlotType } from 'modules/kiosk/types/Slot';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import CheckInIcon from 'assets/icons/checkIn.svg';
import CheckOutIcon from 'assets/icons/checkOut.svg';

const ellipsis = 'text-center text-ellipsis overflow-hidden w-full whitespace-nowrap';

const Slot: React.FC<Omit<SlotType, 'rack'>> = ({
  id,
  position,
  displayName,
  referenceId,
  registration,
  selfServiceType,
  used,
}) => {
  const Icon = selfServiceType === SelfServiceType.CHECK_IN ? CheckInIcon : CheckOutIcon;

  return (
    <li
      className={classNames(
        'relative flex flex-col justify-center w-72 h-32 items-center px-5 pt-7 pb-4 border-2 border-solid border-border-bg rounded-2xl',
        { 'bg-secondary border-tertiary': used },
      )}
      data-testid={`slot-${id}`}
    >
      <div
        className={classNames(
          'flex items-center justify-center absolute w-12 h-10 -top-5 border-solid border-2 bg-white border-border-bg rounded-3xl',
          { 'w-[82px] !bg-secondary border-tertiary': used },
        )}
      >
        <span className="!text-xl font-bold">{position}</span>
        {used && <Icon className="size-7 ml-2 fill-gray-90" />}
      </div>
      <span className={classNames(ellipsis, '!text-xl !leading-6 font-bold')}>{registration}</span>
      <span className={classNames(ellipsis, '!text-lg !leading-6')}>{referenceId}</span>
      {displayName && <span className={classNames(ellipsis, '!text-lg !leading-6')}>{displayName}</span>}
    </li>
  );
};

export default Slot;
