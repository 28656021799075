import React from 'react';
import { FormattedMessage } from 'react-intl';

import PageBaseProps from 'types/PageBase';

import PageFooter from 'components/ui/PageFooter';
import CourtesyVehicle from '.';

const CourtesyVehicleStep: React.FC<PageBaseProps> = ({ onNext, onPrev, shouldDisplayBackButton }) => (
  <div className="main-content">
    <div className="content">
      <div className="flex flex-col items-center kiosk:mb-10 kiosk:mt-1">
        <FormattedMessage id="courtesyVehicle.title" defaultMessage="Courtesy vehicle" tagName="h1" />
      </div>
      <CourtesyVehicle />
    </div>
    <PageFooter onNext={onNext} onPrev={onPrev} shouldDisplayBackButton={shouldDisplayBackButton} />
  </div>
);

export default CourtesyVehicleStep;
