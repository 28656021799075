import { MessageDescriptor } from 'react-intl';

import Label from './Label';

export enum YesNoChoice {
  YES = 'YES',
  NO = 'NO',
}

export enum QuestionType {
  YES_NO = 'YES_NO',
}

interface GenericQuestion {
  id: string;
  description?: Label;
  mandatory: boolean;
  title: MessageDescriptor | Label;
  type: QuestionType;
  response?: unknown;
  comment?: string;
}

export default GenericQuestion;
