import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { createPortal } from 'react-dom';
import KeyboardReact, { KeyboardLayoutObject, KeyboardReactInterface } from 'react-simple-keyboard';

import { useSelector } from 'hooks';
import SendIcon from 'assets/icons/send.svg';
import { getLocation } from 'modules/router/selectors';
import { Button, Input, Textarea } from 'components/ui';
import { isShareboxIndoorKiosk } from 'modules/dealers/selectors';
import { getSelectedLanguage } from 'modules/translations/selectors';

import {
  BUTTON_LABELS,
  getPortalContainer,
  LAYOUT_BY_LANGUAGE,
  WRITABLE_INPUT_TYPES,
  WRITABLE_INPUT_TYPES_CONFIG,
} from './KeyUtils';

import './index.css';

const buttonThemes = [
  {
    class: '!bg-tertiary text-white',
    buttons: '{bksp}',
  },
  {
    class: '!bg-tertiary text-white',
    buttons: '{enter}',
  },
];

// See https://stackoverflow.com/a/46012210
const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;

const KeyboardModal = () => {
  const intl = useIntl();
  const focusedInput = React.useRef<HTMLInputElement | null>();
  const componentRef = React.useRef<(HTMLInputElement & HTMLTextAreaElement) | null>();

  const location = useSelector(getLocation);
  const selectedLanguage = useSelector(getSelectedLanguage);

  const [value, setValue] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [layoutName, setLayoutName] = React.useState('default');
  const [layout, setLayout] = React.useState<KeyboardLayoutObject>();

  React.useEffect(() => {
    setShow(false);
  }, [location]);

  const onFocus = React.useCallback(({ target }: Event) => {
    focusedInput.current = target as HTMLInputElement;

    setLayoutName('default');
    setLayout(WRITABLE_INPUT_TYPES_CONFIG[focusedInput.current.type].layout ?? LAYOUT_BY_LANGUAGE[selectedLanguage]);

    setValue(focusedInput.current.value);
    setShow(true);
  }, [selectedLanguage]);

  const onClose = React.useCallback((text?: string) => {
    setShow(false);

    if (typeof text === 'string') {
      // Sets the value of the focused element using native setter
      if (focusedInput.current instanceof HTMLTextAreaElement) {
        nativeTextAreaValueSetter.call(focusedInput.current, text);
      } else {
        nativeInputValueSetter.call(focusedInput.current, text);
      }

      // Dispatches a native `input` event after setting the value to trigger React's onChange
      const event = new Event('input', { bubbles: true });
      focusedInput.current.dispatchEvent(event);
    }

    // This ensures our portal will always be the last child of the body as it will be recreated
    document.body.removeChild(getPortalContainer());
  }, []);

  // We use onKeyReleased instead of onKeyPress to hide the keyboard
  // because the event keeps getting called even after the keyboard is closed with the latter
  const handleKeyReleased = React.useCallback((which: string) => {
    if (which === '{shift}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    } else if (which === '{tab}') {
      onClose();
    }
  }, [layoutName, onClose]);

  const handleKeyboardInit = React.useCallback((keyboard: KeyboardReactInterface) => {
    keyboard.setInput(value);
    componentRef.current.selectionStart = value.length;
    componentRef.current.selectionEnd = value.length;
  }, [value]);

  const handleSend = React.useCallback(() => onClose(value), [onClose, value]);

  const handleChange = React.useCallback((newValue: string) => {
    // use to focus the cursor position in the textarea
    componentRef.current.blur();
    componentRef.current.focus();
    setValue(newValue);
  }, []);

  React.useLayoutEffect(() => {
    let inputs: NodeListOf<HTMLInputElement>;

    const observer = new MutationObserver(() => {
      inputs = document.querySelectorAll('input,textarea');
      inputs.forEach((input) => {
        if (WRITABLE_INPUT_TYPES.includes(input.type) && input.id !== 'keyboard-input') {
          // Ensure that the event listener is only added once
          input.removeEventListener('focus', onFocus);
          input.addEventListener('focus', onFocus);
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      inputs?.forEach((input) => input.removeEventListener('focus', onFocus));
      observer.disconnect();
    };
  }, [onFocus]);

  const display = React.useMemo(() => BUTTON_LABELS(intl), [intl]);

  const isTextarea = focusedInput.current?.type === 'textarea';
  const excludedKeys = WRITABLE_INPUT_TYPES_CONFIG[focusedInput.current?.type]?.excludedKeys;
  const InputComponent = isTextarea ? Textarea : Input;

  // We need to use a portal here to ensure that the keyboard is always on top of everything (ie. including modals)
  return show && createPortal(
    (
      <div className="z-60 fixed top-0 bottom-0 left-0 right-0">
        <div className="fixed h-screen w-screen bg-gray-90/25" onClick={handleSend} />
        <div className="fixed w-screen bottom-0">
          <div className="keyboard-inputWrapper bg-input-bg p-4 flex gap-2">
            <InputComponent
              readOnly
              autoFocus
              value={value}
              ref={componentRef}
              id="keyboard-input"
              className="!rounded-none flex-1"
              inputClassName={classNames(
                '!rounded-lg bg-white !rounded !p-4',
                { '!m-0 !h-32 resize-none': isTextarea },
              )}
            />
            <Button
              onClick={handleSend}
              className={classNames('btn-keyboard w-16 flex justify-center items-center', { 'h-32': isTextarea })}
            >
              <SendIcon className="aspect-square fill-white" />
            </Button>
          </div>
          <KeyboardReact
            mergeDisplay
            newLineOnEnter
            layout={layout}
            display={display}
            layoutName={layoutName}
            onChange={handleChange}
            buttonTheme={buttonThemes}
            onInit={handleKeyboardInit}
            excludeFromLayout={excludedKeys}
            onKeyReleased={handleKeyReleased}
          />
        </div>
      </div>
    ), getPortalContainer(),
  );
};

// This is a safeguard to ensure that the keyboard is only displayed on Sharebox kiosks
const KeyboardModalWrapper = () => {
  const allowKeyboard = useSelector(isShareboxIndoorKiosk);
  return allowKeyboard && <KeyboardModal />;
};

export default KeyboardModalWrapper;
