import React from 'react';
import classNames, { Argument } from 'classnames';

import Modal from './Modal';

const Popup: React.FC<React.PropsWithChildren<{ duration?: number, onClose?: (value: boolean) => void, title?: React.ReactElement, className?: Argument }>> = ({
  duration = 4000, onClose, title, children, className,
}) => {
  const timeoutId = React.useRef<NodeJS.Timeout>(null);

  const handleOnClose = React.useCallback(() => onClose?.(false), [onClose]);

  React.useEffect(() => {
    timeoutId.current = setTimeout(handleOnClose, duration);
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [duration, handleOnClose]);

  return (
    <Modal
      open
      title={title}
      hideCloseButton
      onClose={handleOnClose}
      className={classNames('w-full max-w-lg kiosk:max-w-4xl kioskSharebox:max-w-2xl', className)}
    >
      {children}
    </Modal>
  );
};

export default Popup;
