import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { addTime } from 'modules/elapsedTime/actions';
import { setCurrentStep } from 'modules/steps/actions';
import { finalizeSelfService } from 'modules/selfServices/actions';

import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import { getCurrentStep } from 'modules/steps/selector';
import { getStepsConfig } from 'modules/form/selectors';
import { isLogoutModalDisplayed } from 'modules/sockets/selectors';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import {
  getSelfServiceType, isCheckinSelfService, isPublicDevice, isRemoteKiosk as isRemoteKioskSelector,
} from 'modules/dealers/selectors';

import { useDispatch } from 'hooks';
import useElapsedTime from 'hooks/useElapsedTime';

import socketSlice from 'modules/sockets/reducer';

import { getUrlParam } from 'utils/urlUtils';
import { Spinner, Steps } from 'components/ui';
import SocketRedirectModal from 'components/SocketRedirectModal';
import { updateFormDebounce } from 'components/ui/ConnectedForm/decorator';
import { isKioskOffline as isKioskOfflineSelector } from 'modules/kiosk/selectors';

import Modal from '../ui/Modal';
import Inactivity from './Inactivity';
import { SelfServiceStep } from './Steps/common';
import UpdateLogoutModal from '../UpdateLogoutModal';
import { getSelfServiceSteps } from './Steps';
import KioskError from '../Error/KioskError';

const SelfServiceLayout: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { getElapsedTime, reset } = useElapsedTime();

  const isPublic = useSelector(isPublicDevice);
  const config = useSelector(getStepsConfig);
  const currentStep = useSelector(getCurrentStep);
  const isCheckin = useSelector(isCheckinSelfService);
  const isRemoteKiosk = useSelector(isRemoteKioskSelector);
  const displayModal = useSelector(isLogoutModalDisplayed);
  const isKioskOffline = useSelector(isKioskOfflineSelector);
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const type = useSelector(getSelfServiceType)?.toLowerCase();

  const {
    data, isLoading, isSuccess, isError, isFetching,
  } = useGetSelfServiceByIdQuery({ id: selfServiceId });

  const displayLoading = isLoading || isFetching;

  const steps = React.useMemo(() => (
    data ? Object.values(getSelfServiceSteps(data, config)) : []
  ), [data, config]);

  const handleOnChange = (newStep: SelfServiceStep) => {
    const prevStep = steps.find(({ key }) => key === newStep.previous);
    updateFormDebounce.flush();

    const shouldFinalizeSelfService = typeof prevStep?.data?.isFinalizeStep === 'function'
      ? prevStep?.data?.isFinalizeStep(data)
      : prevStep?.data?.isFinalizeStep ?? false;

    if (isCheckin && newStep.key === 'FINAL_INSTRUCTIONS') {
      dispatch(socketSlice.actions.closeSocket());
    }

    if (shouldFinalizeSelfService) {
      dispatch(finalizeSelfService(newStep.key, getElapsedTime()));
    } else {
      dispatch(setCurrentStep(newStep.key));
      dispatch(addTime({ key: newStep.key, time: getElapsedTime() }));
    }
    reset();
  };

  const promptMessage = intl.formatMessage({
    id: `prompt.backPrevent.${type}`,
    defaultMessage: `Warning, you are about to leave your ${type}.`,
  });

  if (!selfServiceId || isError) {
    const pathToken = getUrlParam('token');
    return (<Navigate to={`/?token=${pathToken}`} />);
  }

  const displayKioskError = isRemoteKiosk && isKioskOffline;

  return (
    <div>
      {displayLoading && <Spinner className="homePage" />}
      {!displayLoading && isSuccess && (
        <Steps step={currentStep} steps={steps} onChange={handleOnChange} promptMessage={promptMessage} />
      )}
      <SocketRedirectModal />
      {displayKioskError && <Modal open hideCloseButton className="w-full max-w-lg !bg-input-bg"><KioskError /></Modal>}
      {displayModal && <UpdateLogoutModal />}
      {isPublic && <Inactivity />}
    </div>
  );
};

export default SelfServiceLayout;
