import { createReducer } from '@reduxjs/toolkit';

import kioskApi from 'modules/kiosk/service';
import { logout } from 'modules/auth/actions';
import selfServicesApi from 'modules/selfServices/service';
import { Kiosk, KioskStatus } from 'modules/kiosk/types/Kiosk';
import { SelfServiceOrigin } from 'modules/selfServices/types/SelfService';

type InitialState = Omit<Kiosk, 'slots'>;

const initialState: InitialState = {
  id: null,
  name: null,
  available: false,
  status: KioskStatus.INACTIVE,
};

const kioskReducer = createReducer<InitialState>(initialState, (builder) => {
  builder
    .addCase(logout, () => initialState)
    .addMatcher(kioskApi.endpoints.getKioskData.matchFulfilled, (state, { payload: { slots, ...data } }) => data)
    .addMatcher(selfServicesApi.endpoints.getSelfServiceById.matchFulfilled, (state, { payload }) => {
      const { origin, slot } = payload;
      if (origin === SelfServiceOrigin.KIOSK && slot) {
        return {
          ...state,
          id: slot.kiosk.id,
          name: slot.kiosk.name,
        };
      }
      return { ...state };
    });
});

export default kioskReducer;
