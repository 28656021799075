import React from 'react';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSelector } from 'hooks';
import PageBaseProps from 'types/PageBase';
import FormKeys from 'modules/form/types/FormKeys';
import { FormValidatorWithIntl } from 'types/Form';
import { getStepsConfig } from 'modules/form/selectors';
import { KioskPreferencesForm } from 'types/QuestionsForm';
import { getSelectedSelfService } from 'modules/selfServices/selectors';
import { KioskPreferencesQuestionsIds } from 'modules/selfServices/constants';
import { isKioskPreferenceQuestionVisible } from 'modules/selfServices/utils';

import Popup from '../ui/Popup';
import ConnectedForm from '../ui/ConnectedForm';
import KioskUsageRender from './KioskUsageRender';
import MandatoryWarning from '../MandatoryWarning';

export const validateForm: FormValidatorWithIntl<KioskPreferencesForm> = (intl, selfService, config) => ({ kioskPreferencesQuestions = [] }) => kioskPreferencesQuestions.reduce((acc, question, index) => {
  const isVisible = isKioskPreferenceQuestionVisible(
    question.id as KioskPreferencesQuestionsIds,
    selfService.type,
    selfService.origin,
    config,
  );
  return {
    ...acc,
    ...(isVisible
            && question.mandatory
            && typeof question.response === 'undefined' && {
      [`kioskPreferencesQuestions[${index}].error`]: intl.formatMessage({
        id: 'validation.required',
        defaultMessage: 'This field is mandatory',
      }),
    }),
  };
}, {});

const KioskUsageForm: React.FC<PageBaseProps> = ({ onNext, onPrev, shouldDisplayBackButton }) => {
  const intl = useIntl();
  const config = useSelector(getStepsConfig);
  const selfService = useSelector(getSelectedSelfService);

  return (
    <ConnectedForm
      onPrev={onPrev}
      onSubmit={onNext}
      component={KioskUsageRender}
      formKey={FormKeys.KIOSK_USAGE}
      mutators={{ ...arrayMutators }}
      subscription={{ hasValidationErrors: true }}
      shouldDisplayBackButton={shouldDisplayBackButton}
      validate={validateForm(intl, selfService, config)}
      errorComponent={(
        <Popup
          title={(
            <FormattedMessage
              id="questions.mandatoryModal.title"
              defaultMessage="You must first answer all mandatory questions"
              tagName="h2"
            />
          )}
        >
          <MandatoryWarning />
        </Popup>
      )}
    />
  );
};

export default KioskUsageForm;
