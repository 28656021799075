import linkify from 'linkify-string';
import { isSameDay, isBefore, differenceInMinutes } from 'date-fns';

import { createDictionary } from 'utils/objectUtils';

import User from 'types/User';
import UserType from 'types/UserType';

import { Link, Message } from './types/Message';

export enum MessageTagEnum {
  LINK = '{{link}}',
}

const TAG_FORMATTER: Record<MessageTagEnum, (link: Link) => string> = createDictionary(
  {
    [MessageTagEnum.LINK]: ({ displayText, targetUrl }) => `<a href="${targetUrl}" target='_blank'>${displayText}</a>`,
  },
  ({ tag }) => tag,
);

export const linkifyMessage = (message: Partial<Message>) => {
  const linkifiedMessage = linkify(message.content, { target: { url: '_blank' } });
  return message.links?.reduce(
    (acc, link) => acc.replace(link.tag, TAG_FORMATTER[link.tag](link)),
    linkifiedMessage,
  ) ?? linkifiedMessage;
};

const TIME_FRAME = 3; // as minutes
export const isOwner = (user: User) => user?.type === UserType.SYSTEM && user.onCustomerBehalf;

const isSameWriter = (msg1: Message, msg2: Message | undefined): boolean => (msg1 && msg2 ? msg1.createdBy.id === msg2.createdBy.id : false);
const isInSameTimeFrame = (msg1: Message, msg2: Message | undefined) => (msg1 && msg2 ? Math.abs(differenceInMinutes(msg2.createdOn, msg1.createdOn)) <= TIME_FRAME : false);
const isSameChannel = (msg1: Message, msg2: Message | undefined) => (msg1 && msg2 ? msg1.channel === msg2.channel : false);
const isSameDate = (msg1: Message, msg2: Message | undefined) => (msg1 && msg2 ? isSameDay(msg1.createdOn, msg2.createdOn) : false);

const groupMessages = (messages: Message[], date: string): Message[][] => messages.reduce<Message[][]>((acc, message, index) => {
  if (isSameDay(date, message.createdOn)) {
    const previous = messages[index - 1];
    const sameTimeframe = isInSameTimeFrame(message, previous);
    const sameChannel = isSameChannel(message, previous);
    const sameWriter = isSameWriter(message, previous);

    if (sameChannel && sameTimeframe && sameWriter) {
      acc[acc.length - 1].push(message);
    } else {
      acc.push([message]);
    }
  }
  return acc;
}, []);

interface GroupedMessages {
  date: string;
  createdBy: User;
  messages: Message[][];
}

/** Group by date; each date has a list of messages grouped by timeframe, channel, and writer */
export const groupMessagesByDate = (messages: Message[]): GroupedMessages[] => messages.reduce<GroupedMessages[]>((acc, message, index) => {
  const { createdBy, createdOn: date } = message;
  const previous = messages[index - 1];

  if (!isSameDate(message, previous)) {
    acc.push({
      date,
      createdBy,
      messages: groupMessages(messages, date),
    });
  }
  return acc;
}, []);

export const getLastMessageByDate = (messages: Message[]): Message | null => messages.reduce<Message | null>((lastMessage, current) => {
  if (!lastMessage || isBefore(lastMessage.createdOn, current.createdOn)) {
    return current;
  }
  return lastMessage;
}, null);
