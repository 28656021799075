import React from 'react';
import arrayMutators from 'final-form-arrays';

import PageBaseProps from 'types/PageBase';
import FormKeys from 'modules/form/types/FormKeys';

import ConnectedForm from 'components/ui/ConnectedForm';

import MobilityError from './MobilityError';
import MobilityDocumentFormRender from './MobilityDocumentFormRender';

const MobilityDocumentForm: React.FC<PageBaseProps> = ({ onNext, onPrev, ...pageProps }) => (
  <ConnectedForm
    onPrev={onPrev}
    onSubmit={onNext}
    keepDirtyOnReinitialize
    formKey={FormKeys.MOBILITY}
    mutators={{ ...arrayMutators }}
    subscription={{ errors: true }}
    errorComponent={<MobilityError />}
    component={MobilityDocumentFormRender}
    {...pageProps}
  />
);

export default MobilityDocumentForm;
