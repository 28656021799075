import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';

import PageBaseProps from 'types/PageBase';

import { logout } from 'modules/auth/actions';

import { getMFAError } from 'modules/auth/selectors';
import {
  getKioskLocation, getKioskName, getKioskType, getLocationId,
} from 'modules/dealers/selectors';

import { Button, Input, PageFooter } from 'components/ui';

import getConfig from './config';

const KioskLocationId: React.FC<PageBaseProps> = ({ onNext, onPrev }) => {
  const dispatch = useDispatch();

  const mfaError = useSelector(getMFAError);
  const currentKioskType = useSelector(getKioskType);
  const currentKioskName = useSelector(getKioskName);
  const currentLocationId = useSelector(getLocationId);
  const currentKioskLocation = useSelector(getKioskLocation);

  const kioskType = React.useMemo(
    () => mfaError?.kioskType ?? currentKioskType,
    [mfaError, currentKioskType],
  );

  const locationId = React.useMemo(
    () => mfaError?.locationId ?? mfaError?.kioskName ?? currentLocationId ?? currentKioskName,
    [mfaError, currentKioskName, currentLocationId],
  );

  const kioskLocation = React.useMemo(
    () => mfaError?.kioskLocation ?? currentKioskLocation,
    [mfaError, currentKioskLocation],
  );

  const {
    title,
    description,
    locationLabel,
    lockerImage,
    lockerLocationTagImage,
  } = React.useMemo(
    () => getConfig(kioskType, kioskLocation, Boolean(mfaError)),
    [mfaError, kioskType, kioskLocation],
  );

  const handleQuit = React.useCallback(() => dispatch(logout()), [dispatch]);

  return (
    <div className="main-content !pt-24">
      <div className="flex flex-col gap-4 text-center">
        <h1 className="text-gray-90">
          {title}
        </h1>
        <p className="w-full text-gray-70 mb-6 mt-5 whitespace-pre-wrap break-words">
          {description}
        </p>
        <Input
          disabled
          value={locationId}
          inputClassName="text-center"
          label={locationLabel}
        />
        <div className="relative grow bg-input-bg rounded-lg kiosk:rounded-2xl p-3 w-full mb-24">
          <img alt={lockerImage.alt} className="h-auto w-full rounded-lg" src={lockerImage.src} />
          {lockerLocationTagImage?.src && (
            <>
              {/* eslint-disable-next-line max-len */}
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rounded-full bg-white p-2 w-1/3 aspect-square">
                <img
                  src={lockerLocationTagImage.src}
                  alt={lockerLocationTagImage.alt}
                  className="rounded-full size-full"
                />
              </div>
            </>
          )}
        </div>
      </div>
      {!mfaError && <PageFooter onNext={onNext} onPrev={onPrev} />}
      {mfaError && (
        <div className="nextButton">
          <Button className="w-full" onClick={handleQuit} testId="quit">
            <FormattedMessage id="quit" defaultMessage="Quit" />
          </Button>
        </div>
      )}
    </div>

  );
};

export default KioskLocationId;
