import React from 'react';
import Bowser from 'bowser';
import * as Sentry from '@sentry/react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useSelector } from 'hooks';
import { getUrlParam } from 'utils/urlUtils';
import useInitAnalytics from 'hooks/useInitAnalytics';

import { isShareboxIndoorKiosk } from 'modules/dealers/selectors';
import { HOME, HOME_OUTDOOR_SHAREBOX } from 'constants/ErrorReturnPage';

import { isLowPerfMode } from 'modules/app/selectors';
import { getAdminToken, getQRCodeToken } from 'modules/auth/selectors';
import { isLocalKioskOffline as isKioskOfflineSelector } from 'modules/healthCheck/selectors';

import Home from 'components/Home';
import Skip from 'components/Skip';
import Admin from 'components/Admin';
import Login from 'components/Login';
import Cookies from 'components/Cookies';
import Slots from 'components/Admin/Slots';
import Error404 from 'components/Error/Error404';
import AdminLayout from 'components/Admin/Layout';
import VehicleCheck from 'components/VehicleCheck';
import AdminLogin from 'components/Admin/AdminLogin';
import Management from 'components/Admin/Management';
import OfflineModal from 'components/ui/OfflineModal';
import SelfServiceLayout from 'components/SelfServices';
import KioskLocationId from 'components/KioskLocationId';
import FinalInstructions from 'components/FinalInstructions';
import HomeOutdoorSharebox from 'components/HomeOutdoorSharebox';
import VehicleCheckForm from 'components/VehicleCheck/VehicleCheckForm';
import PaymentLanding, { PaymentStatus } from 'components/PaymentCompleted';

import './refreshData';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const browser = Bowser.getParser(window.navigator.userAgent);
const isIOS18 = browser.satisfies({
  mobile: {
    safari: '~18',
  },
});

const Router: React.FC = () => {
  useInitAnalytics();

  const location = useLocation();

  const token = getUrlParam('token');
  const outdoorShareboxToken = getUrlParam('OSToken');

  const adminToken = useSelector(getAdminToken);
  const lowPerfMode = useSelector(isLowPerfMode);
  const qrCodeToken = useSelector(getQRCodeToken);
  const isKioskOffline = useSelector(isKioskOfflineSelector);
  const isShareboxIndoor = useSelector(isShareboxIndoorKiosk);

  const publicRoutes = !token && !outdoorShareboxToken;
  const displayOfflineModal = isKioskOffline && location.pathname !== '/'; // hide the modal on the home page

  React.useLayoutEffect(() => {
    if (isShareboxIndoor || outdoorShareboxToken) {
      document.body.classList.add('no-cursor');
    }
    if (lowPerfMode) {
      document.body.classList.add('no-transition', 'no-blur');
    }
  }, [lowPerfMode, isShareboxIndoor, outdoorShareboxToken]);

  // Workaround to fix the height issue on IOS 18 see BMWVID-21277
  React.useEffect(() => {
    if (isIOS18) {
      setTimeout(() => window.scrollTo(0, 1), 10);
    }
  }, [location]);

  return (
    <>
      {token && (
        <>
          <Cookies />
          {displayOfflineModal && <OfflineModal />}
          <SentryRoutes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="resume" element={<Skip />} />
              <Route path="checkin" element={<SelfServiceLayout />} />
              <Route path="checkout" element={<SelfServiceLayout />} />
              <Route path="wrongKiosk" element={<KioskLocationId />} />
              <Route path="breakdown" element={<SelfServiceLayout />} />
              {qrCodeToken && <Route path="admin/login" element={<AdminLogin />} />}
              {adminToken && (
                <Route path="admin" element={<AdminLayout />}>
                  <Route index element={<Admin />} />
                  <Route path="slots" element={<Slots />} />
                  <Route path="management/:type" element={<Management />} />
                </Route>
              )}
              <Route path="vehicle-check">
                <Route index element={<VehicleCheck />} />
                <Route path=":type" element={<VehicleCheckForm />} />
                <Route path="final-instructions/:selfServiceId" element={<FinalInstructions />} />
              </Route>
            </Route>
            <Route path="*" element={<Error404 target={HOME} />} />
          </SentryRoutes>
        </>
      )}
      {outdoorShareboxToken && (
        <Routes>
          <Route path="/QRCode" element={<HomeOutdoorSharebox />} />
          <Route path="*" element={<Error404 target={HOME_OUTDOOR_SHAREBOX} />} />
        </Routes>
      )}
      {publicRoutes && (
        <Routes>
          <Route path="/payment-success" element={<PaymentLanding status={PaymentStatus.SUCCESS} />} />
          <Route path="/payment-failure" element={<PaymentLanding status={PaymentStatus.FAILURE} />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      )}
    </>
  );
};

export default Router;
