import React from 'react';
import classNames from 'classnames';

import Input, { InputProps } from 'components/ui/Input';

const Value: React.FC<Omit<InputProps, 'disabled'>> = ({
  inputClassName, labelClassName, className, ...rest
}) => (
  <Input
    {...rest}
    disabled
    className={classNames('text-center w-full sm:w-1/2 border-b-2 border-white last:border-0 sm:border-b-0 sm:odd:border-r-2 sm:odd:last:border-r-0 my-0 sm:my-2 !rounded-none', className)}
    labelClassName={classNames('text-input-text p-0 !top-4', labelClassName)}
    inputClassName={classNames('text-center disabled:opacity-100 text-black !pb-2 !pt-8 sm:!pb-0 sm:!pt-6', inputClassName)}
  />
);

export default Value;
