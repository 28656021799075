import React from 'react';
import { useDispatch } from 'react-redux';

import useCountdown from 'hooks/useCountdown';

import { INACTIVITY_TIME_TO_LOGOUT } from 'modules/auth/constants';

import ProgressBar from 'components/ui/ProgressBar';

interface InactivityProgressBarProps {
  date: Date | string,
  stopped?: boolean;
  onEnded?: () => void;
}

const InactivityProgressBar: React.FC<InactivityProgressBarProps> = ({ date, stopped, onEnded }) => {
  const dispatch = useDispatch();

  const { countdown } = useCountdown({ date });

  const progress = ((countdown - 1) * 100) / INACTIVITY_TIME_TO_LOGOUT;

  React.useLayoutEffect(() => {
    if (countdown === 0 && !stopped) {
      onEnded?.();
    }
  }, [dispatch, countdown, stopped, onEnded]);

  return (
    <ProgressBar progress={progress} />
  );
};

export default React.memo(InactivityProgressBar);
