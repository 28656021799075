import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isIntlMessage } from 'modules/notifications/types/Notification';

import Modal from './Modal';
import Button from './Button';
import { AlertProps, descriptionFormatter } from './Alert';

const AlertModal: React.FC<AlertProps> = ({
  title, description, duration, onClose,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const timeout = React.useRef<NodeJS.Timeout>(null);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  React.useLayoutEffect(() => {
    if (duration) {
      timeout.current = setTimeout(handleClose, duration);
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [duration, handleClose, onClose]);

  return (
    <Modal
      open={isOpen}
      hideCloseButton
      onClose={handleClose}
      className="w-full max-w-lg kiosk:max-w-lg kioskSharebox:max-w-lg"
      title={isIntlMessage(title) ? <FormattedMessage tagName="h2" {...title} /> : title}
    >
      <div className="flex flex-col items-center text-center gap-8">
        <p className="first-letter:uppercase">{isIntlMessage(description) ? <FormattedMessage {...descriptionFormatter(description)} /> : description}</p>
        <Button onClick={handleClose} className="w-full" testId="closeButton">
          <FormattedMessage id="close" defaultMessage="Close" />
        </Button>
      </div>
    </Modal>
  );
};

export default AlertModal;
