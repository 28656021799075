import { REHYDRATE } from 'redux-persist';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { logout } from 'modules/auth/actions';
import formsAdapter from 'modules/form/adapter';
import dealersApi from 'modules/dealers/service';
import formKeys from 'modules/form/types/FormKeys';
import selfServicesApi from 'modules/selfServices/service';

import type { RootState } from '../../App/rootReducer';
import type { MediaInterface } from '../../types/Media';

const formsSlice = createSlice({
  name: 'form',
  initialState: formsAdapter.getInitialState(),
  reducers: {
    upsertForm: formsAdapter.upsertOne,
    upsertFormMany: formsAdapter.upsertMany,
    resetForm: () => formsAdapter.getInitialState(),
    resetVCForm: (state) => ({ ...state, entities: { ...state.entities, vehicleCheck: undefined } }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => formsAdapter.getInitialState())
      .addCase(REHYDRATE, (state, action) => {
        const { payload } = action as PayloadAction<RootState>;
        const formValues = payload?.form?.entities?.[formKeys.REPAIR_ORDER]?.values as { medias: MediaInterface[] };
        const medias = formValues?.medias?.map((media) => ({
          ...media,
          path: media.path?.toString().startsWith('blob:') ? undefined : media.path,
        }), []);
        formsAdapter.upsertOne(state, { key: formKeys.REPAIR_ORDER, values: { medias } });
      })
      .addMatcher(
        selfServicesApi.endpoints.getFinalInstructions.matchFulfilled,
        () => formsAdapter.getInitialState(),
      )
      .addMatcher(
        dealersApi.endpoints.getContext.matchFulfilled,
        () => formsAdapter.getInitialState(),
      );
  },
});

export default formsSlice;
