import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from 'App/Store';
import { DoorsStatus } from 'modules/kiosk/types/KeysSafe';
import { hasKioskRootAccess } from 'modules/auth/selectors';
import { getSelectedLanguage } from 'modules/translations/selectors';
import { getContext, isKioskOrigin } from 'modules/dealers/selectors';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';
import { ReadySelfService } from 'modules/kiosk/types/GetReadySelfServices';
import { selfServicesAdapter, slotsAdapter } from './reducers/adapters';

import { Slot } from './types/Slot';
import kioskApi from './service';

const getRoot = (state: RootState) => state;
const getUi = (state: RootState) => state.kiosk.ui;
const getSlots = (state: RootState) => state.kiosk.slots;
const getDoors = (state: RootState) => state.kiosk.doors;
const getProps = <T>(_: RootState, params: T) => params;

export const isKioskOffline = createDraftSafeSelector(getUi, (ui) => ui.isOffline);
export const isKioskReady = createDraftSafeSelector(getUi, (ui) => ui.isKioskReady);

export const getDoorsStatus = createDraftSafeSelector(getDoors, (doors) => doors.status);
export const isPending = createDraftSafeSelector(getDoorsStatus, (status) => status === DoorsStatus.PENDING);
export const isOpening = createDraftSafeSelector(getDoorsStatus, (status) => status === DoorsStatus.OPENING);
export const isOpened = createDraftSafeSelector(getDoorsStatus, (status) => status === DoorsStatus.OPENED);
export const isClosing = createDraftSafeSelector(getDoorsStatus, (status) => status === DoorsStatus.CLOSING);
export const isClosed = createDraftSafeSelector(getDoorsStatus, (status) => status === DoorsStatus.CLOSED);
export const isClosingOrClosed = createDraftSafeSelector(isClosing, isClosed, (closing, closed) => closing || closed);
export const isMoving = createDraftSafeSelector(isOpening, isClosing, (opening, closing) => opening || closing);
export const isIdle = createDraftSafeSelector(isPending, isClosed, (pending, closed) => pending || closed);
export const hasError = createDraftSafeSelector(getDoors, (doors) => doors.hasError);
export const getIsOpenSuccess = createDraftSafeSelector(isOpened, hasError, (opened, error) => opened && !error);
export const getIsOpenFailure = createDraftSafeSelector(isPending, hasError, (pending, error) => pending && error);
export const getIsCloseSuccess = createDraftSafeSelector(isClosed, hasError, (closed, error) => closed && !error);
export const getIsCloseFailure = createDraftSafeSelector(isOpened, hasError, (opened, error) => opened && error);

export const getKioskId = createDraftSafeSelector(
  isKioskOrigin,
  getContext,
  (isKiosk, context) => (isKiosk ? context?.kioskId : undefined),
);
export const isKioskDataLoading = createDraftSafeSelector(
  getRoot,
  getKioskId,
  (state, kioskId) => kioskApi.endpoints.getKioskData.select({ kioskId })(state).isLoading,
);

export const isKioskDataSuccess = createDraftSafeSelector(
  getRoot,
  getKioskId,
  (state, kioskId) => kioskApi.endpoints.getKioskData.select({ kioskId })(state).isSuccess,
);

export const showEmergencyAlert = createDraftSafeSelector(hasKioskRootAccess, isIdle, hasError, (hasRootAccess, idle, error) => hasRootAccess && idle && !error);

// SlotsEntity selectors
const slotsSelector = slotsAdapter.getSelectors<RootState>(getSlots);

export const getFreeSlotId = createDraftSafeSelector(
  slotsSelector.selectAll,
  (slots) => slots.find(({ used }) => used === false)?.id,
);

export const getSlotBySelfServiceId = createDraftSafeSelector(
  slotsSelector.selectAll,
  getProps<string>,
  (slots, selfServiceId) => slots.find((slot) => slot.selfServiceId === selfServiceId),
);
export const getSlotIdBySelfServiceId = createDraftSafeSelector(getSlotBySelfServiceId, (slot) => slot?.id);
export const getRackBySelfServiceId = createDraftSafeSelector(getSlotBySelfServiceId, (slot) => slot?.rack);

export const getSlotsByRack = createDraftSafeSelector(
  slotsSelector.selectAll,
  (slots) => slots.reduce(
    (acc: Record<number, Slot[]>, slot) => ({
      ...acc,
      [slot.rack]: [...(acc[slot.rack] ?? []), slot],
    }),
    {},
  ),
);

export const getSlotById = slotsSelector.selectById;

// SelfServicesEntity selectors
const getSelfServices = (state: RootState) => state.kiosk.selfServices;
const selfServicesSelectors = selfServicesAdapter.getSelectors<RootState>(getSelfServices);

export const getAllSelfServices = createDraftSafeSelector(selfServicesSelectors.selectAll, (selfServices) => {
  const sortByUnFinalized = (a: ReadySelfService) => (a.finalized === false ? -1 : 1);
  return selfServices.sort(sortByUnFinalized);
});

export const getSelfServicesIsLoading = createDraftSafeSelector(getSelfServices, ({ loading }) => loading);
export const getSelfServiceById = createDraftSafeSelector(
  selfServicesSelectors.selectEntities,
  getProps<string>,
  (selfServices, id) => selfServices[id],
);

export const getReadySelfServiceSearchText = createDraftSafeSelector(getUi, ({ readySearchText }) => readySearchText || undefined);

export const getKioskSpeechForCurrentLocale = createDraftSafeSelector(
  getContext,
  getSelectedLanguage,
  ({ kioskSpeech = {} }, language) => kioskSpeech[language] ?? kioskSpeech.en ?? Object.values(kioskSpeech)[0],
);

export const getUnFinalizedSlot = createDraftSafeSelector(
  slotsSelector.selectAll,
  (slots) => slots.filter(({ finalized }) => finalized === false),
);

export const hasUnFinalizedSlot = createDraftSafeSelector(
  getUnFinalizedSlot,
  (unFinalizedSlot) => unFinalizedSlot.length > 0,
);

export const unFinalizedSlotTypes = createDraftSafeSelector(
  getUnFinalizedSlot,
  (unFinalizedSlot) => unFinalizedSlot.reduce((acc, { selfServiceType }) => {
    // handle breakdown as check-in
    const type = selfServiceType === SelfServiceType.CHECK_OUT ? SelfServiceType.CHECK_OUT : SelfServiceType.CHECK_IN;
    if (!acc.includes(type)) {
      acc.push(type);
    }
    return acc;
  }, []).sort((a) => (a === SelfServiceType.CHECK_IN ? -1 : 1)),
);
