import React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import arrayMutators from 'final-form-arrays';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'hooks';
import { ButtonType } from 'components/ui/Button';
import { Confirm, LanguageSelect, Spinner } from 'components/ui';

import { getOrigin } from 'modules/dealers/selectors';
import { getPublicToken } from 'modules/auth/selectors';
import { useCreateVehicleCheckMutation } from 'modules/vehicleCheck/service';
import {
  useLazyGetSelfServiceByIdQuery,
  usePatchVehicleCheckDecisionMutation,
} from 'modules/selfServices/service';

import {
  getContext,
  getSelfServiceId,
  getVehicleCheckModel,
} from 'modules/vehicleCheck/selectors';

import {
  isLoadingGetContext,
  isLoadingGetModel,
  isSuccessGetModel,
} from 'modules/vehicleCheck/querySelectors';

import { SelfServiceOrigin } from 'modules/selfServices/types/SelfService';
import { FormVehicleCheck } from 'modules/vehicleCheck/types/FormVehicleCheck';
import { VehicleCheckType } from 'modules/vehicleCheck/types/VehicleCheckModel';

import Content from './Content';
import SendingModal from './modals/SendingModal';
import { formatDataToFormValues, formatFormValuesToData } from './utils';

const VehicleCheckForm: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { type } = useParams<{ type: VehicleCheckType }>();

  const origin = useSelector(getOrigin);
  const publicToken = useSelector(getPublicToken);
  const selfServiceId = useSelector(getSelfServiceId);
  const context = useSelector(getContext);
  const model = useSelector(getVehicleCheckModel);

  const isFetchingModel = useSelector(isLoadingGetModel);
  const isSuccessModel = useSelector(isSuccessGetModel);
  const isFetchingContext = useSelector(isLoadingGetContext);

  const isFetching = isFetchingContext || isFetchingModel;

  const [fetchSelfService] = useLazyGetSelfServiceByIdQuery();
  const [patchVC, { isLoading: isUpdating }] = usePatchVehicleCheckDecisionMutation();
  const [createVehicleCheck, { isLoading, isSuccess }] = useCreateVehicleCheckMutation();

  const [displayDiscardModal, setDisplayDiscardModal] = React.useState(false);

  const initialValues = React.useMemo(
    () => formatDataToFormValues(selfServiceId, model),
    [selfServiceId, model],
  );

  const redirect = React.useCallback(async () => {
    const nbVehicleCheckRemaining = Object.values(VehicleCheckType).filter(
      (currentType) => context[currentType]?.accepted && !context[currentType]?.answered,
    ).length;

    if (nbVehicleCheckRemaining === 1) {
      await fetchSelfService({ id: selfServiceId });
      if (origin === SelfServiceOrigin.ON_SITE) {
        navigate(`/checkin?token=${publicToken}`);
      } else {
        // Replace is important to avoid the user to go back to the form
        navigate(`/vehicle-check/final-instructions/${selfServiceId}?token=${publicToken}`, { replace: true });
      }
    } else {
      navigate(`/vehicle-check${search}`);
    }
  }, [context, fetchSelfService, selfServiceId, origin, navigate, publicToken, search]);

  const handleDiscardVehicleCheck = React.useCallback(async () => {
    await patchVC({ selfServiceId, accepted: false, type });
    redirect();
  }, [patchVC, selfServiceId, type, redirect]);

  const handleCancel = React.useCallback(() => setDisplayDiscardModal(false), []);

  const handleSubmit = (data: FormVehicleCheck) => {
    const values = formatFormValuesToData(data);
    createVehicleCheck({ model: values, type });
  };

  React.useEffect(() => {
    if (isSuccess) {
      redirect();
    }
  }, [fetchSelfService, isSuccess, redirect, selfServiceId]);

  return (
    <>
      {isFetching && (
        <div className="h-screen flex">
          <Spinner className="!w-28 !h-28" />
        </div>
      )}
      {isSuccessModel && (
        <>
          <div className="lg:w-2/3 m-auto">
            <div className="flex justify-end w-full px-7">
              <LanguageSelect />
            </div>
            <h1 className="text-center">
              <FormattedMessage id={`vehicleCheck.types.${type}`} defaultMessage={`${type} vehicle`} />
            </h1>
            <h2 className="font-bold text-center py-6 text-xl px-8">
              <FormattedMessage
                id="page.vehicleCheck.title"
                defaultMessage="Please take all the photos or videos requested below."
              />
            </h2>
            <Form
              subscription={{}}
              component={Content}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              mutators={{ ...arrayMutators }}
              setDisplayDiscardModal={setDisplayDiscardModal}
            />
          </div>
          <SendingModal type={type} open={isLoading} />
          <Confirm
            focusCancel
            onCancel={handleCancel}
            open={displayDiscardModal}
            onConfirm={handleDiscardVehicleCheck}
            cancelProps={{ type: ButtonType.TERTIARY }}
            confirmProps={{ type: ButtonType.SECONDARY, loading: isUpdating }}
            cancel={<FormattedMessage id="header.cancel" defaultMessage="No, continue" />}
            confirm={<FormattedMessage id="header.confirm" defaultMessage="Yes, cancel" />}
            title={(
              <span className="flex justify-center items-center text-base font-bold">
                <FormattedMessage
                  id="page.vehicleCheck.modal.discard.title"
                  defaultMessage="Are you sure you want to cancel your vehicle check ?"
                />
              </span>
            )}
          />
        </>
      )}
    </>
  );
};

export default VehicleCheckForm;
