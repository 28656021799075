import { REHYDRATE } from 'redux-persist';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { AppStartListening } from 'App/ListenerMiddleware';
import healthCheckApi from 'modules/healthCheck/service';
import { isLocalKiosk } from 'modules/dealers/selectors';
import api from 'service/index';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const HEALTH_CHECK_POLLING_INTERVAL = 10000;

startAppListening({
  predicate: (action, state) => (action.type === REHYDRATE || action.type === api.util.resetApiState.type) && isLocalKiosk(state),
  effect: async (_, { dispatch }) => {
    dispatch(healthCheckApi.endpoints.healthCheck.initiate(
      undefined,
      { subscriptionOptions: { pollingInterval: HEALTH_CHECK_POLLING_INTERVAL } },
    ));
  },
});

export default listenerMiddleware;
