import React, { AnimationEventHandler } from 'react';
import { Argument } from 'classnames';

interface UseUnmountAnimationArgs {
  trigger: boolean;
  animation: string;
  disabled?: boolean;
}

interface UseUnmountAnimationResponse {
  isVisible: boolean;
  animation: Argument;
  handleAnimationEnd: AnimationEventHandler;
}

const useUnmountAnimation = ({ trigger, animation, disabled }: UseUnmountAnimationArgs): UseUnmountAnimationResponse => {
  const [isVisible, setIsVisible] = React.useState(trigger);
  const shouldTriggerAnimation = !disabled && !trigger;

  React.useEffect(() => {
    if (trigger || disabled) {
      setIsVisible(trigger);
    }
  }, [disabled, trigger]);

  const handleAnimationEnd = React.useCallback<AnimationEventHandler>(({ animationName }) => {
    if (animation.endsWith(animationName)) {
      setIsVisible(trigger);
    }
  }, [animation, trigger]);

  return { isVisible, handleAnimationEnd, animation: shouldTriggerAnimation && animation };
};

export default useUnmountAnimation;
