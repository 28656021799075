import React from 'react';
import classNames, { Argument } from 'classnames';

export type TextareaProps = {
  label?: string;
  value: string;
  rows?: number;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  description?: React.ReactNode;
  className?: Argument;
  inputClassName?: Argument;
  'data-testid'?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({
  label, onChange, value, rows = 5, className, inputClassName, description, required, disabled, error, 'data-testid': dataTestid, ...rest
}, ref) => {
  const id = React.useId();

  return (
    <div
      className={classNames('relative z-0 group bg-input-bg rounded-lg kiosk:rounded-2xl flex flex-col', className)}
    >
      <textarea
        id={id}
        ref={ref}
        value={value}
        rows={rows}
        placeholder=" "
        onChange={onChange}
        required={required}
        disabled={disabled}
        data-testid={dataTestid}
        className={classNames('textarea peer', inputClassName, { '!mb-0': description, 'border border-error': error })}
        {...rest}
      />
      {label && <label className="textarea-label" htmlFor={id}>{label}</label>}
      {description && <span className="my-5 text-sm text-gray-70">{description}</span>}
      {error && (<span className="mb-5 text-error px-1 text-xs kiosk:text-xl" data-testid="error-text">{error}</span>)}
    </div>
  );
});

export default Textarea;
