import { push } from 'redux-first-history';
import { matchPath } from 'react-router-dom';
import { AppStartListening } from 'App/ListenerMiddleware';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { getSearch } from 'modules/router/selectors';
import { locationChange } from 'modules/router/actions';
import { HOME_VEHICLE_CHECK } from 'constants/ErrorReturnPage';

import vehicleCheckApi from './service';
import { RootState } from '../../App/rootReducer';
import { VehicleCheckType } from './types/VehicleCheckModel';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

startAppListening({
  matcher: locationChange.match,
  effect: async ({ payload }, { dispatch, getState }) => {
    const path = matchPath('/vehicle-check/:type', payload.location.pathname);
    if (path) {
      const context = await dispatch(vehicleCheckApi.endpoints.getVCContext.initiate(undefined, { forceRefetch: true })).unwrap();
      const type = path.params.type as VehicleCheckType;
      if (context[type].answered) {
        const search = getSearch(getState() as RootState);
        const params = new URLSearchParams(search);
        params.append('target', HOME_VEHICLE_CHECK);
        dispatch(push(`/error?${params.toString()}`));
      } else {
        await dispatch(vehicleCheckApi.endpoints.getModel.initiate({ selfServiceId: context.selfServiceId, type }, { forceRefetch: true }));
      }
    }
  },
});

export default listenerMiddleware;
