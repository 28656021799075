import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { AppStartListening } from 'App/ListenerMiddleware';

import { setCurrentStep } from 'modules/steps/actions';
import { locationChange } from 'modules/router/actions';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { isLocalKiosk as isLocalKioskSelector } from 'modules/dealers/selectors';

import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import selfServicesApi from 'modules/selfServices/service';
import { FinalInvoice } from 'modules/selfServices/types/SelfService';
import onlinePaymentApi from './service';

import { getStatus } from './selectors';

import { isFinished } from './types/PaymentStatus';

const listenerMiddleware = createListenerMiddleware();
const startAppListening = listenerMiddleware.startListening as AppStartListening;

const POLL_INTERVAL = 5000;

startAppListening({
  matcher: isAnyOf(
    setCurrentStep.match,
    locationChange.match,
  ),
  effect: async (_, {
    cancelActiveListeners, getState, dispatch, delay, take,
  }) => {
    cancelActiveListeners();

    let state = getState();
    const isLocalKiosk = isLocalKioskSelector(state);
    // Don't use selector in order fix a circular dependency, to change when the main issue is resolved
    let { currentStep } = state.steps;

    // We don't poll on 'local' kiosks
    if (!isLocalKiosk && currentStep === SelfServiceSteps.FINAL_INVOICE) {
      let status = getStatus(state);
      const selfServiceId = getSelectedSelfServiceId(state);
      if (!isFinished(status)) {
        let payable = true;

        /* eslint-disable no-await-in-loop */
        do {
          await delay(POLL_INTERVAL);

          dispatch(onlinePaymentApi.endpoints.getFinalInvoiceStatus.initiate(
            { selfServiceId },
            { forceRefetch: true },
          ));

          // It's better to wait for the API call using `take` instead of `await` to allow cancellation
          // eslint-disable-next-line no-await-in-loop
          const [{ payload }] = await take(
            (action) => onlinePaymentApi.endpoints.getFinalInvoiceStatus.matchFulfilled(action)
                        || onlinePaymentApi.endpoints.getFinalInvoiceStatus.matchRejected(action),
          );

          state = getState();
          // The status in the store is updated by the previous call and/or by the websocket
          status = getStatus(state);
          // The current step might have changed in the meantime (e.g. websocket update)
          currentStep = state.steps.currentStep;
          // Final invoice becomes non-payable if the user clicks on "Pay later" button
          payable = (payload as FinalInvoice).payable ?? true;
        } while (payable && !isFinished(status) && currentStep === SelfServiceSteps.FINAL_INVOICE);
        /* eslint-enable no-await-in-loop */

        // Fetch the self-service to update the final invoice if we're still on the final invoice step
        // This allows to show the next button or to redirect the user
        if (currentStep === SelfServiceSteps.FINAL_INVOICE) {
          dispatch(selfServicesApi.endpoints.getSelfServiceById.initiate(
            { id: selfServiceId },
            { forceRefetch: true },
          ));
        }
      }
    }
  },
});

export default listenerMiddleware;
