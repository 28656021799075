import api from 'service/index';
import { QR_CODE } from 'constants/url';
import { getUrlParam } from 'utils/urlUtils';
import { addNotification } from 'modules/notifications/actions';
import { NotificationType } from 'modules/notifications/types/Notification';

const qrCodeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getQRCode: builder.query<string, void>({
      query: () => {
        const token = getUrlParam('OSToken');
        return {
          method: 'GET',
          url: QR_CODE,
          params: { token },
          headers: {
            accept: 'image/png',
          },
          responseHandler: (response) => response.blob(),
        };
      },
      transformResponse: (response: Blob) => URL.createObjectURL(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'home.outdoorSharebox.generateQrCodeFailure',
                defaultMessage: 'An error occurred while generating the link',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
  }),
});

export const { useLazyGetQRCodeQuery } = qrCodeApi;

export default qrCodeApi;
