import { matchPath } from 'react-router-dom';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../App/Store';

const getState = (state: RootState) => state.router;

export const getLocation = createDraftSafeSelector(getState, ({ location }) => location);
export const getSearch = createDraftSafeSelector(
  getLocation,
  (location) => location.search,
);

export const isAdminLoginPage = createDraftSafeSelector(
  getState,
  ({ location }) => location.pathname === '/admin/login',
);

export const isHomePage = createDraftSafeSelector(
  getState,
  ({ location }) => matchPath('/', location.pathname),
);

export const isLoginPage = createDraftSafeSelector(
  getState,
  ({ location }) => matchPath('/login', location.pathname),
);

export const getVehicleCheckDetailPage = createDraftSafeSelector(
  getState,
  ({ location }) => matchPath('/vehicle-check/:type', location.pathname),
);
