import React from 'react';
import { push } from 'redux-first-history';
import { addMinutes, toDate } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';

import { b } from 'utils/i18nUtils';
import { usePatchVehicleCheckDecisionMutation } from 'modules/selfServices/service';
import { useGetVCLinkQuery, useSendVCLinkMutation } from 'modules/vehicleCheck/service';

import formActions from 'modules/form/actions';
import { isPublicDevice } from 'modules/dealers/selectors';
import { getSelectedSelfServiceId, getVehicleCheckPublicToken } from 'modules/auth/selectors';

import PageBaseProps from 'types/PageBase';
import { VehicleCheckType } from 'modules/vehicleCheck/types/VehicleCheckModel';

import Button from 'components/ui/Button';
import Spinner from 'components/ui/Spinner';
import Confirm from 'components/ui/Confirm';
import QRCodeViewer from 'components/ui/QRCodeViewer';

import {
  isVehicleCheckAccepted as isVehicleCheckAcceptedSelector,
  isCourtesyVehicleCheckRefused as isCourtesyVehicleCheckRefusedSelector,
  isCourtesyVehicleCheckAccepted as isCourtesyVehicleCheckAcceptedSelector,
  getAcceptedVehicleChecks,
} from 'modules/form/selectors';

const Instructions: React.FC<PageBaseProps> = ({ onNext }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const isPublic = useSelector(isPublicDevice);
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const vehicleCheckToken = useSelector(getVehicleCheckPublicToken);
  const acceptedVehicleChecks = useSelector(getAcceptedVehicleChecks);
  const isVehicleCheckAccepted = useSelector(isVehicleCheckAcceptedSelector);
  const isCourtesyVehicleCheckRefused = useSelector(isCourtesyVehicleCheckRefusedSelector);
  const isCourtesyVehicleCheckAccepted = useSelector(isCourtesyVehicleCheckAcceptedSelector);

  const nbVehicleCheck = acceptedVehicleChecks.length;

  const { data, isLoading } = useGetVCLinkQuery({ selfServiceId });
  const [sendLink, { isLoading: isSending }] = useSendVCLinkMutation();
  const [patchVC, { isLoading: isUpdating }] = usePatchVehicleCheckDecisionMutation();

  const handleOnClickStart = () => {
    dispatch(formActions.resetVCForm());

    if (!isPublic && vehicleCheckToken) {
      if (nbVehicleCheck === 1) {
        dispatch(push(`/vehicle-check/${acceptedVehicleChecks[0]}?token=${vehicleCheckToken}`));
      } else {
        dispatch(push(`/vehicle-check?token=${vehicleCheckToken}`));
      }
    } else {
      onNext?.();
    }
  };

  const handleOnClickSend = async () => {
    await sendLink({ selfServiceId }).unwrap();
    onNext?.();
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    if (isVehicleCheckAccepted) {
      await patchVC({ selfServiceId, accepted: false }).unwrap();
    }
    if (isCourtesyVehicleCheckAccepted) {
      await patchVC({ selfServiceId, accepted: false, type: VehicleCheckType.COURTESY }).unwrap();
    }
    onNext?.();
  };

  const vehicleCheckLabel = React.useMemo(() => {
    let labelDescriptor;
    if (isCourtesyVehicleCheckAccepted) {
      labelDescriptor = { id: 'page.vehicleCheck.qrCodeStep.type.courtesy', defaultMessage: 'courtesy' };
    }
    if (isVehicleCheckAccepted && isCourtesyVehicleCheckRefused) {
      labelDescriptor = { id: 'page.vehicleCheck.qrCodeStep.type.personal', defaultMessage: 'personal' };
    }
    if (isCourtesyVehicleCheckAccepted && isVehicleCheckAccepted) {
      labelDescriptor = { id: 'page.vehicleCheck.qrCodeStep.type.personalCourtesy', defaultMessage: 'personal and courtesy' };
    }
    return labelDescriptor ? intl.formatMessage(labelDescriptor) : undefined;
  }, [intl, isCourtesyVehicleCheckAccepted, isCourtesyVehicleCheckRefused, isVehicleCheckAccepted]);

  const expirationTime = React.useMemo(() => {
    const expire = !isLoading && data?.exp ? parseInt(data.exp, 10) : 60; // 1 hour
    return intl.formatTime(toDate(addMinutes(new Date(), expire)), { hour: 'numeric', minute: 'numeric' });
  }, [data, isLoading, intl]);

  return (
    <div className="main-content text-center grid grid-flow-row auto-rows-auto">

      <div className="self-start !mb-0">
        <FormattedMessage
          id="page.vehicleCheck.qrCodeStep.title"
          defaultMessage="Guided vehicle check"
          tagName="h1"
        />

        {isPublic && (
          <div className="mt-5 text-gray-90 max-w-3xl mx-auto">
            <FormattedMessage
              id="page.vehicleCheck.qrCodeStep.kioskDescription"
              // eslint-disable-next-line max-len
              defaultMessage="Please <b>scan the following QR code</b> to open your guided vehicle check{hasDetails, select, true { ({vehicleCheckDetails})} other{}} or click on the button to <b>receive the link by SMS</b> to the phone number provided in your contact details."
              tagName="p"
              values={{ b, vehicleCheckDetails: vehicleCheckLabel, hasDetails: Boolean(vehicleCheckLabel) }}
            />
          </div>
        )}

        {!isPublic && (
          <div className="mt-5 text-gray-90">
            <FormattedMessage
              id="page.vehicleCheck.qrCodeStep.startDescription"
              defaultMessage="Start your guided vehicule check{hasDetails, select, true { ({vehicleCheckDetails})} other{}} by clicking the button below."
              tagName="p"
              values={{
                vehicleCheckDetails: vehicleCheckLabel, hasDetails: Boolean(vehicleCheckLabel),
              }}
            />
          </div>
        )}

        <div className="mt-5 text-gray-90">
          <FormattedMessage
            id="page.vehicleCheck.qrCodeStep.linkExpiration"
            defaultMessage="You have until <b>{time}</b> to complete your tour of the vehicle"
            tagName="p"
            values={{
              time: expirationTime,
              b: (text) => b(text, 'text-sm kiosk:text-2xl kioskSharebox:text-xl'),
            }}
          />
        </div>

        {isPublic && (
          <div className="flex flex-col items-center">
            {isLoading && <Spinner />}
            {data?.url && (
              <div className="aspect-square w-2/3 md:w-1/3 kioskPartteam:w-1/2 kioskSharebox:w-1/4 bg-input-bg rounded-3xl p-4 kiosk:p-6 mt-12">
                <QRCodeViewer content={data.url} className="!h-auto !w-full rounded-xl kiosk:rounded-xl" options={{ margin: 2 }} />
              </div>
            )}
            <Button className="mt-12 kiosk:px-24" onClick={handleOnClickSend} loading={isSending} testId="send-link">
              <FormattedMessage id="page.vehicleCheck.qrCodeStep.sendLink" defaultMessage="Send me the link" />
            </Button>
          </div>
        )}
      </div>

      <div className="self-end">
        <div
          className="font-bold underline cursor-pointer mt-5 kiosk:mb-24 mb-36  justify-self-end"
          onClick={onOpen}
          data-testid="cancel-vehicleCheck"
        >
          <FormattedMessage
            id="page.vehicleCheck.discard"
            defaultMessage="I no longer wish to carry out {nbVehicleCheck, plural, one {a vehicle check} other {vehicle checks}}"
            tagName="p"
            values={{ nbVehicleCheck }}
          />
        </div>
        <div className="nextButton">
          {isPublic && (
            <Button className="px-8 kiosk:px-24" onClick={onNext}>
              <FormattedMessage
                id="steps.finish"
                defaultMessage="Finish"
              />
            </Button>
          )}
          {!isPublic && (
            <Button
              disabled={isLoading}
              testId="start-vehicleCheck"
              onClick={handleOnClickStart}
              className="mt-12 kiosk:px-24"
            >
              <FormattedMessage
                id="page.vehicleCheck.qrCodeStep.start"
                defaultMessage="Start the vehicle check"
              />
            </Button>
          )}
        </div>
        <Confirm
          open={open}
          onCancel={onClose}
          onConfirm={handleConfirm}
          cancel={<FormattedMessage id="header.cancel" defaultMessage="No, continue" />}
          confirm={<FormattedMessage id="header.confirm" defaultMessage="Yes, cancel" />}
          confirmProps={{ loading: isUpdating }}
          title={(
            <h2 className="text-2xl">
              <FormattedMessage id="page.vehicleCheck.qrCodeStep.cancel" defaultMessage="Cancel your vehicle check" />
            </h2>
          )}
          question={(
            <div className="text-center">
              <FormattedMessage
                id="vehicleCheck.selfService.responsibility"
                // eslint-disable-next-line max-len
                defaultMessage="By refusing, you agree to take responsibility for any damage found on the vehicle that would not have been previously reported."
                tagName="p"
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Instructions;
