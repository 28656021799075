import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import authApi from 'modules/auth/service';
import kioskApi from 'modules/kiosk/service';
import { logout } from 'modules/auth/actions';
import { setIsKioskOffline, setIsKioskReady } from 'modules/kiosk/actions';
import selfServicesApi from 'modules/selfServices/service';
import { KioskConnectionStatus } from 'modules/dealers/types/ContextResponse';

interface InitialState { isPaymentModalVisible: boolean; isKioskReady: boolean; readySearchText?: string; isOffline: boolean }

const initialState: InitialState = {
  isKioskReady: false,
  isPaymentModalVisible: false,
  readySearchText: null,
  isOffline: false,
};

const kioskReducer = createReducer<InitialState>(initialState, (builder) => {
  builder
    .addCase(logout, () => initialState)
    .addCase(setIsKioskReady, (state, { payload }) => ({ ...state, isKioskReady: payload }))
    .addCase(setIsKioskOffline, (state, { payload }) => ({ ...state, isOffline: payload }))
    .addMatcher(
      selfServicesApi.endpoints.getSelfServiceById.matchFulfilled,
      (state, action) => ({ ...state, isOffline: action.payload.kioskConnectionStatus === KioskConnectionStatus.OFFLINE }),
    )
    .addMatcher(
      isAnyOf(authApi.endpoints.loginMFA.matchFulfilled, authApi.endpoints.loginAdmin.matchFulfilled),
      (state, { payload }) => ({ ...state, isKioskReady: payload?.kioskPrepared ?? false }),
    )
    .addMatcher(
      kioskApi.endpoints.getReadySelfServices.matchFulfilled,
      (state, { meta }) => ({ ...state, readySearchText: meta.arg.originalArgs.text }),
    );
});

export default kioskReducer;
