export const INACTIVITY_SELF_SERVICE_FLOWS = 120;

export const INACTIVITY_ADMIN = 20;

export const INACTIVITY_TIME_TO_LOGOUT = 20;

export const INACTIVITY_TIME_OPENED_DOORS = 300;

export const INACTIVITY_TIME_ON_ERROR_PAGE = 60;

export const INACTIVITY_TIME_ON_FINAL_INSTRUCTIONS = 30;
