import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { KioskLocation } from 'modules/dealers/types/ContextResponse';

export enum ErrorTypeEnum {
  FINAL_INVOICE_ENABLED = 'FINAL_INVOICE_ENABLED',
  MFA_CODE_REACHED = 'MFA_CODE_REACHED',
  MFA_CODE_TIMEOUT = 'MFA_CODE_TIMEOUT',
  MFA_MAX_ATTEMPT_REACHED = 'MFA_MAX_ATTEMPT_REACHED',
  MFA_WRONG_CODE = 'MFA_WRONG_CODE',
  MFA_WRONG_CONNECTION_STATUS = 'MFA_WRONG_CONNECTION_STATUS',
  SO_CHECK_IN_KIOSK_ALL_SLOTS_USED = 'SO_CHECK_IN_KIOSK_ALL_SLOTS_USED',
  SO_CHECK_OUT_ALREADY_COMPLETED = 'SO_CHECK_OUT_ALREADY_COMPLETED ',
  SO_CHECK_OUT_KIOSK_SLOT_NOT_PREPARED = 'SO_CHECK_OUT_KIOSK_SLOT_NOT_PREPARED',
  SO_COURTESY_VEHICLE_NOT_AVAILABLE = 'SO_COURTESY_VEHICLE_NOT_AVAILABLE',
  SO_KIOSK_WRONG_PIN_CODE = 'SO_KIOSK_WRONG_PIN_CODE',
  SO_PHONE_NUMBER_NOT_VALID = 'SO_PHONE_NUMBER_NOT_VALID',
  SO_WRONG_KIOSK = 'SO_WRONG_KIOSK',
  SO_WRONG_QR_CODE = 'SO_WRONG_QR_CODE',
  SO_WRONG_REGISTRATION = 'SO_WRONG_REGISTRATION',
  SO_WRONG_SHAREBOX_LOCATION_ID = 'SO_WRONG_SHAREBOX_LOCATION_ID',
  WRONG_STATUS = 'SELF_SERVICE_WRONG_STATUS',
}

export interface APIError<T = undefined, U = undefined> {
  errorCode: number;
  errorMessage: string;
  errorStatus: string;
  errorType?: T extends undefined ? ErrorTypeEnum : T;
  errorMetadata?: U;
}

export interface WrongKioskErrorMetadata {
  kioskId: string;
  kioskLocation: KioskLocation;
  kioskName: string;
  locationId: string;
}

export interface ApiErrorResponse {
  status: number;
  data: APIError;
}

export const isApiErrorResponse = (
  error: SerializedError | FetchBaseQueryError | ApiErrorResponse,
): error is ApiErrorResponse => (
  typeof (error as ApiErrorResponse)?.status === 'number'
);

export const DEALER_ERROR_TYPES = [
  ErrorTypeEnum.FINAL_INVOICE_ENABLED,
  ErrorTypeEnum.SO_CHECK_IN_KIOSK_ALL_SLOTS_USED,
  ErrorTypeEnum.SO_CHECK_OUT_KIOSK_SLOT_NOT_PREPARED,
  ErrorTypeEnum.SO_WRONG_REGISTRATION,
];
