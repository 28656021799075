import { RETRY_GET_MEDIA_TIMEOUT } from '../modules/camera/constants';

class Camera {
  private static instances: Map<string, Camera> = new Map();

  public static getInstance(deviceName: string): Camera {
    if (!Camera.instances.has(deviceName)) {
      Camera.instances.set(deviceName, new Camera(deviceName));
    }

    return Camera.instances.get(deviceName);
  }

  private deviceName: string;

  private deviceId?: string;

  private stream?: Promise<MediaStream>;

  constructor(deviceName: string) {
    this.deviceName = deviceName;
  }

  private async getDeviceId(): Promise<string | undefined> {
    const allDevices = await navigator.mediaDevices?.enumerateDevices() ?? [];
    const device = allDevices.find(({ label }) => label.startsWith(this.deviceName));
    return device?.deviceId;
  }

  private async init(): Promise<MediaStream> {
    this.deviceId = await this.getDeviceId();

    // Get the stream if the device is found
    if (this.deviceId) {
      const constraints = {
        audio: false,
        video: {
          aspectRatio: 4 / 3,
          height: { ideal: 480 },
          deviceId: this.deviceId,
        },
      };

      return navigator.mediaDevices.getUserMedia(constraints);
    }

    // Retry to get media stream after timeout if the device was not found
    return new Promise((resolve) => {
      setTimeout(() => this.init().then(resolve), RETRY_GET_MEDIA_TIMEOUT);
    });
  }

  public async getStream(): Promise<MediaStream> {
    // Restart the stream if it was stopped
    if (!this.stream) {
      this.start();
    }

    return this.stream;
  }

  public exists(): boolean {
    return Boolean(this.deviceId);
  }

  public start(): void {
    this.stream = this.init();
  }

  public async stop(): Promise<void> {
    if (this.stream) {
      const stream = await this.stream;
      stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }
  }
}

export default Camera;
