import Key from 'components/Key';
import Parking from 'components/Parking';
import Questions from 'components/Questions';
import Signature from 'components/Signature';
import RemoteKey from 'components/RemoteKey';
import CustomerInfo from 'components/CustomerInfo';
import KioskLocationId from 'components/KioskLocationId';
import FinalInstructions from 'components/FinalInstructions';

import { SelfService, SignatureStatus } from 'modules/selfServices/types/SelfService';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { isNotAnswered } from './utils';
import KioskUsageForm from '../../KioskUsage';
import type { SelfServiceStep } from '../types';

export const CUSTOMER_INFO: SelfServiceStep = {
  key: SelfServiceSteps.CUSTOMER_INFO,
  apiKey: SelfServiceAPISteps.CONTACT_INFO,
  component: CustomerInfo,
  data: {
    preventBrowserNavigation: true,
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

export const QUESTIONS: SelfServiceStep = {
  key: SelfServiceSteps.QUESTIONS,
  apiKey: SelfServiceAPISteps.QUESTIONS,
  component: Questions,
  data: {
    isVisible: ({ status, questionForm }: SelfService) => isNotAnswered(status) && questionForm?.questions?.length > 0,
  },
};

export const PARKING: SelfServiceStep = {
  key: SelfServiceSteps.PARKING,
  apiKey: SelfServiceAPISteps.PARKING,
  component: Parking,
  data: {
    isVisible: ({
      status,
      parkingMap,
    }: SelfService) => isNotAnswered(status) && (parkingMap?.allowParkingSpot || parkingMap?.maps?.length > 0),
  },
};

export const SIGNATURE: SelfServiceStep = {
  key: SelfServiceSteps.SIGNATURE,
  apiKey: SelfServiceAPISteps.SIGNATURE,
  component: Signature,
  data: {
    isVisible: ({ status, mobility }: SelfService) => isNotAnswered(status)
                                                   || mobility?.contract?.status === SignatureStatus.UNSIGNED,
  },
};

export const KIOSK_LOCATION: SelfServiceStep = {
  key: SelfServiceSteps.KIOSK_LOCATION,
  apiKey: SelfServiceAPISteps.DIRECTIONS_TO_KIOSK,
  component: KioskLocationId,
  data: {
    isVisible: ({ status }, config) => isNotAnswered(status) && config.remoteKiosk,
  },
};

export const KEY: SelfServiceStep = {
  key: SelfServiceSteps.KEY,
  apiKey: SelfServiceAPISteps.KEYS,
  component: Key,
  data: {
    preventBrowserNavigation: true,
    isVisible: ({ status }, config) => isNotAnswered(status) && config.localKiosk && !config.shareboxIndoorKiosk,
  },
};

export const REMOTE_KEY: SelfServiceStep = {
  key: SelfServiceSteps.KEY,
  apiKey: SelfServiceAPISteps.KEYS,
  component: RemoteKey,
  data: {
    hidePrevButton: true,
    isVisible: ({ status }, config) => isNotAnswered(status) && (config.remoteKiosk || config.shareboxIndoorKiosk),
  },
};

export const FINAL_INSTRUCTIONS: SelfServiceStep = {
  key: SelfServiceSteps.FINAL_INSTRUCTIONS,
  apiKey: SelfServiceAPISteps.FINAL_INSTRUCTIONS,
  component: FinalInstructions,
  data: {
    isVisible: true,
    hideHeader: true,
    preventBrowserNavigation: true,
  },
};

export const KIOSK_USAGE: SelfServiceStep = {
  component: KioskUsageForm,
  key: SelfServiceSteps.KIOSK_USAGE,
  apiKey: SelfServiceAPISteps.KIOSK_USAGE_QUESTION,
};
