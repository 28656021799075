import * as Sentry from '@sentry/react';
import { push } from 'redux-first-history';
import { AppStartListening } from 'App/ListenerMiddleware';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import dealersApi from 'modules/dealers/service';
import { getSearch } from 'modules/router/selectors';
import { KeyType } from 'modules/kiosk/types/GetReadySelfServices';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';
import { isKioskEmergencyMode, isKioskReadyOptional } from 'modules/dealers/selectors';

import kioskApi from './service';
import * as actions from './actions';
import { setDoorError, setDoorStatus } from './actions';
import {
  getKioskId,
  isKioskReady,
  getFreeSlotId,
  getSelfServiceById,
  getSlotIdBySelfServiceId,
  getReadySelfServiceSearchText, getSlotBySelfServiceId,
} from './selectors';

import { KeyStatus } from './types/Kiosk';
import {
  CloseKeysSafeRequest,
  CloseSafeAction,
  DoorsStatus,
  KeysSafeAction,
  OpenKeysSafeRequest,
} from './types/KeysSafe';

const listenerMiddleware = createListenerMiddleware();
const startAppListening = listenerMiddleware.startListening as AppStartListening;

const handleErrorWhenOpeningOrClosing = (event: Event, errorPayload: Record<string, unknown>) => {
  Sentry.setContext('operation', errorPayload);
  Sentry.captureException(event);
};

startAppListening({
  actionCreator: actions.openKeysSafe,
  effect: async ({ payload }, { dispatch, getState, take }) => {
    const isReadyOptional = isKioskReadyOptional(getState());
    const isReady = isKioskReady(getState());
    if (!isReadyOptional && !isReady) {
      await take(actions.setIsKioskReady.match);
    }

    const state = getState();
    const kioskId = getKioskId(state);
    const hasKioskDoorsError = isKioskEmergencyMode(state);

    const {
      slotId, selfServiceId, selfServiceType, asAdmin, emergency, keyStatus,
    } = payload as KeysSafeAction;

    const isCheckout = selfServiceType === SelfServiceType.CHECK_OUT;

    const endpointPayload: OpenKeysSafeRequest = {
      emergency,
      kioskId,
      slotId,
      selfServiceId,
    };

    try {
      if (emergency) {
        if (hasKioskDoorsError) {
          await dispatch(kioskApi.endpoints.openDoorsEmergency.initiate({ kioskId }, { forceRefetch: true })).unwrap();
        } else {
          await dispatch(kioskApi.endpoints.openSlotAsAdmin.initiate(endpointPayload, { forceRefetch: true })).unwrap();
        }
      } else if (asAdmin) {
        const newSlotId = getSlotIdBySelfServiceId(state, selfServiceId);
        const isMobilityCheckIn = selfServiceType === SelfServiceType.CHECK_IN && keyStatus === KeyStatus.NONE;
        endpointPayload.slotId = newSlotId;
        if ((isCheckout || isMobilityCheckIn) && !newSlotId) {
          endpointPayload.slotId = getFreeSlotId(state);
        }

        if (endpointPayload.slotId) {
          await dispatch(kioskApi.endpoints.openSlotAsAdmin.initiate(endpointPayload, { forceRefetch: true })).unwrap();
        } else {
          await dispatch(actions.setDoorStatus(DoorsStatus.CLOSED));
        }
      } else {
        await dispatch(kioskApi.endpoints.openSlot.initiate(endpointPayload, { forceRefetch: true })).unwrap();
      }
    } catch (e) {
      handleErrorWhenOpeningOrClosing(e, { ...endpointPayload, action: 'opening' });
      dispatch(setDoorError(true));
      dispatch(setDoorStatus(DoorsStatus.PENDING));
    }
  },
});

startAppListening({
  actionCreator: actions.closeKeysSafe,
  effect: async ({ payload }, { dispatch, getState, take }) => {
    const isReadyOptional = isKioskReadyOptional(getState());
    const isReady = isKioskReady(getState());
    if (!isReadyOptional && !isReady) {
      await take(actions.setIsKioskReady.match);
    }

    const state = getState();
    const kioskId = getKioskId(state);
    const hasKioskDoorsError = isKioskEmergencyMode(state);

    const {
      slotId, selfServiceId, selfServiceType, asAdmin, hasAccepted, emergency, keyStatus, keyType,
    } = payload as CloseSafeAction;

    const slot = getSlotBySelfServiceId(state, selfServiceId);

    const isUnFinalize = slot?.finalized === false;
    const isCheckout = selfServiceType === SelfServiceType.CHECK_OUT;
    const isMobilityCheckIn = selfServiceType === SelfServiceType.CHECK_IN && (keyStatus === KeyStatus.NONE || keyType === KeyType.MOBILITY);
    const canUnprepared = (isCheckout && keyType === KeyType.CUSTOMER && keyStatus === KeyStatus.IN_SLOT && !isUnFinalize) || isMobilityCheckIn;

    const endpointPayload: CloseKeysSafeRequest = {
      kioskId,
      emergency,
      selfServiceId,
      slotId: slot?.id,
      status: isCheckout ? KeyStatus.RETRIEVED : KeyStatus.IN_SLOT,
    };

    try {
      if (emergency) {
        if (hasKioskDoorsError) {
          await dispatch(kioskApi.endpoints.closeAllSlotsAsAdmin.initiate({ kioskId })).unwrap();
        } else {
          endpointPayload.slotId = slotId;
          await dispatch(kioskApi.endpoints.closeSlotAsAdmin.initiate(endpointPayload, { forceRefetch: true })).unwrap();
        }
      } else if (asAdmin) {
        if (hasAccepted) {
          const { slotId: originalSlotId } = getSelfServiceById(state, selfServiceId);

          if (canUnprepared && originalSlotId) {
            await dispatch(kioskApi.endpoints.unprepareSlotAsAdmin.initiate(endpointPayload)).unwrap();
          } else {
            // Admin logic is reversed
            const isInSlotCheckout = (isCheckout && keyType !== KeyType.MOBILITY);
            const status = !isUnFinalize && (isInSlotCheckout || isMobilityCheckIn) ? KeyStatus.IN_SLOT : KeyStatus.RETRIEVED;
            await dispatch(kioskApi.endpoints.updateSlotAsAdmin.initiate({ ...endpointPayload, status })).unwrap();
          }
        } else {
          await dispatch(kioskApi.endpoints.closeSlotAsAdmin.initiate(endpointPayload, {
            forceRefetch: true,
          })).unwrap();
        }
      } else {
        await dispatch(kioskApi.endpoints.updateSlot.initiate(endpointPayload)).unwrap();
      }

      await take((action) => actions.setDoorStatus.match(action) && action.payload === DoorsStatus.CLOSED);

      if (asAdmin) {
        if (!emergency) {
          const text = getReadySelfServiceSearchText(getState());
          await dispatch(kioskApi.endpoints.getReadySelfServices.initiate({ kioskId, type: selfServiceType, text }, { forceRefetch: true })).unwrap();
        }
        await dispatch(kioskApi.endpoints.getKioskData.initiate({ kioskId }, { forceRefetch: true })).unwrap();
      }
    } catch (e) {
      handleErrorWhenOpeningOrClosing(e, { ...endpointPayload, action: 'closing' });
    }
  },
});

startAppListening({
  predicate: (action, state) => isKioskEmergencyMode(state) && setDoorStatus.match(action)
    && (action.meta.origin === DoorsStatus.CLOSED || action.payload === DoorsStatus.CLOSED),
  effect: async (_, { dispatch, getState }) => {
    await dispatch(dealersApi.endpoints.getContext.initiate(undefined, { forceRefetch: true })).unwrap();
    const search = getSearch(getState());
    dispatch(push(`/${search}`));
  },
});

export default listenerMiddleware;
