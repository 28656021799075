import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useField } from 'react-final-form';

import Image from 'components/ui/Image';
import { GET_MEDIA_UPLOAD_URL } from 'constants/url';
import { SUPPORTED_MEDIAS } from 'constants/mediaTypes';
import { DocumentStatus } from 'modules/selfServices/types/SelfService';

import { useSelector } from 'hooks';
import useUpload from 'hooks/useUpload';
import useLabelTranslation from 'hooks/useLabelTranslation';

import { required } from 'utils/formUtils';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';

import Spinner from 'components/ui/Spinner';
import Carousel from 'components/ui/Carousel';
import Button, { ButtonType } from 'components/ui/Button';

import Trash from 'assets/icons/trash.svg';
import ImageIcon from 'assets/icons/image.svg';
import PictureIcon from 'assets/icons/picture.svg';

import { MediaEntityType, MediaSubType } from '../../types/MediaTypeEnum';

interface MobilityDocumentProps {
  name: string;
}

const Document: React.FC<MobilityDocumentProps> = ({ name }) => {
  const intl = useIntl();
  const { getLabelTranslation } = useLabelTranslation();

  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const [mediasFullscreen, setMediasFullscreen] = React.useState([]);
  const [hideFullscreenAction, setHideFullscreenAction] = React.useState(true);

  const { value: document } = useField(name, { subscription: { value: true } }).input;

  const {
    name: documentName, status, comment, sample,
  } = document;
  const isRequested = status === DocumentStatus.REQUESTED;

  const { value: media, onChange } = useField(`${name}.media`, {
    format: (v) => v,
    subscription: { value: true },
    validate: (value) => (isRequested ? required(value) : undefined),
  }).input;

  const {
    upload, isLoading: isUploading, remove, isDeleting, browse, medias: previewMedias,
  } = useUpload(selfServiceId, media ? [media] : undefined);
  const mediaPath = media?.path ?? sample?.path;

  const isLoading = isUploading || isDeleting;
  const canUpload = isRequested && !media;
  const displayFullscreen = mediasFullscreen.length > 0;
  const displayDelete = media && isRequested;

  const handlePreviewMedia = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const hasUploadedMedia = previewMedias.length > 0;
    setMediasFullscreen(hasUploadedMedia ? previewMedias : [sample]);
    setHideFullscreenAction(!hasUploadedMedia || !isRequested);
  }, [isRequested, previewMedias, sample]);

  const handleClose = React.useCallback(() => {
    setMediasFullscreen([]);
  }, []);

  const handleUpload = async () => {
    if (canUpload) {
      try {
        const [file] = Array.from(await browse({ accept: SUPPORTED_MEDIAS.MOBILITY_DOCUMENT, capture: 'environment' }));

        if (file) {
          const { uploadId, type, path } = await upload({
            file,
            contentType: file.type,
            url: GET_MEDIA_UPLOAD_URL,
            entityType: MediaEntityType.MOBILITY,
            subType: MediaSubType.DOCUMENT_MEDIA,
          });

          onChange({ uploadId, type, path });
        }
      } catch { /* Do nothing */ }
    }
  };

  const onDeleteMedia = async () => {
    setMediasFullscreen([]);

    await remove(media.uploadId);

    onChange(undefined);
  };

  const onDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDeleteMedia();
  };

  return (
    <>
      {displayFullscreen && (
        <Carousel
          fullscreen
          medias={mediasFullscreen}
          onClose={handleClose}
          onDelete={onDeleteMedia}
          hideActions={hideFullscreenAction}
          watermarkTitle={hideFullscreenAction ? intl.formatMessage({
            id: 'mobilityDocument.sample',
            defaultMessage: 'Sample picture',
          }) : undefined}
        />
      )}
      <div
        data-testid={name}
        onClick={handleUpload}
        className={classNames(
          'flex flex-row items-center justify-between text-description py-6 mx-5 overflow-hidden',
          {
            'cursor-pointer': canUpload,
            'text-gray-90': Boolean(media),
          },

        )}
      >
        <div className="flex flex-auto items-center w-2/5">
          <div className="mr-5 size-10 border-4 rounded cursor-pointer relative" onClick={mediaPath ? handlePreviewMedia : undefined} data-testId="preview">
            {mediaPath && <Image thumbnail src={mediaPath} className="size-8 bg-cover" />}
            {!mediaPath && <ImageIcon className="size-6 bg-cover m-auto mt-1" />}
          </div>
          <div className="flex flex-col">
            <span className="font-bold">
              {getLabelTranslation(documentName)}
            </span>
            {comment && (
              <span className="font-medium italic text-gray-70">
                {comment}
              </span>
            )}
          </div>

        </div>
        {isLoading && <Spinner className="mx-0 h-8" />}
        {!isLoading && (
          <div className="flex flex-none justify-end">
            {canUpload && <PictureIcon />}
            {displayDelete && (
              <Button className="ml-4 !p-0" onClick={onDelete} testId="delete" type={ButtonType.LIGHT}>
                <Trash className="fill-black" />
              </Button>
            )}
          </div>
        )}
      </div>
    </>

  );
};

export default Document;
