import React from 'react';
import { FormattedMessage } from 'react-intl';
import { KioskLocation, KioskType } from 'modules/dealers/types/ContextResponse';

import partteam from '../../assets/images/partteam.jpg';
import shareboxOutdoorLocker from '../../assets/images/sharebox.png';
import shareboxIndoorKiosk from '../../assets/images/shareboxIndoor.jpg';
import shareboxLocationId from '../../assets/images/sharebox_locationId.png';

type KioskLocationConfig = {
  title: React.ReactNode;
  description: React.ReactNode;
  locationLabel: React.ReactNode;
  lockerImage: {
    alt: string;
    src: string;
  };
  lockerLocationTagImage?: {
    alt: string;
    src: string;
  };
};

export const getDefaultConfig = (location: KioskLocation) => ({
  title: <FormattedMessage id="kioskLocation.title" defaultMessage="Kiosk location" />,
  description: (
    <FormattedMessage
      id="kioskLocation.description"
      // eslint-disable-next-line max-len
      defaultMessage="Please present yourself in front of the kiosk with the following location ID before going to the next step."
    />
  ),
  locationLabel: <FormattedMessage id="login.locationId" defaultMessage="Location ID" />,
  lockerImage: {
    alt: 'Sharebox locker',
    src: location === KioskLocation.INDOOR ? shareboxIndoorKiosk : shareboxOutdoorLocker,
  },
  lockerLocationTagImage: {
    alt: 'Sharebox location tag',
    src: shareboxLocationId,
  },
});

const getErrorConfigs = (location: KioskLocation): Record<string, KioskLocationConfig> => ({
  [KioskType.SHAREBOX]: {
    title: <FormattedMessage id="wrongKiosk.title" defaultMessage="Change kiosk" />,
    description: (
      <FormattedMessage
        id="wrongKiosk.sharebox.description"
        // eslint-disable-next-line max-len
        defaultMessage="Your keys are prepared in another kiosk, please start over on the kiosk with the following location ID"
      />
    ),
    locationLabel: <FormattedMessage id="login.locationId" defaultMessage="Location ID" />,
    lockerImage: {
      alt: 'Sharebox locker',
      src: location === KioskLocation.INDOOR ? shareboxIndoorKiosk : shareboxOutdoorLocker,
    },
    lockerLocationTagImage: {
      alt: 'Sharebox location tag',
      src: shareboxLocationId,
    },
  },
  [KioskType.PARTTEAM]: {
    title: <FormattedMessage id="wrongKiosk.title" defaultMessage="Change kiosk" />,
    description: (
      <FormattedMessage
        id="wrongKiosk.partteam.description"
        // eslint-disable-next-line max-len
        defaultMessage="Your keys are prepared in another kiosk, please start over on the kiosk with the following name"
      />
    ),
    locationLabel: <FormattedMessage id="name" defaultMessage="Name" />,
    lockerImage: {
      alt: 'Partteam kiosk',
      src: partteam,
    },
  },
});

const getConfig = (type: KioskType, location: KioskLocation, hasError: boolean): KioskLocationConfig => {
  let config: KioskLocationConfig;

  if (hasError) {
    const errorType = type === KioskType.FAKE ? KioskType.PARTTEAM : type;
    config = getErrorConfigs(location)[errorType];
  }

  if (!config) {
    config = getDefaultConfig(location);
  }

  return config;
};

export default getConfig;
