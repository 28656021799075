import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/ui/Modal';
import Button, { ButtonType } from 'components/ui/Button';

import SignatureInput from './SignatureInput';

interface SignatureModalProps {
  confirmMessage: React.ReactNode;
  disabled?: boolean;
  open?: boolean;
  loading?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
  onChange?:(signature?: HTMLCanvasElement)=> void;
  onConfirm: () => void;
}

const SignatureModal: React.FC<SignatureModalProps> = ({
  confirmMessage, disabled, open, onClose, onChange, onConfirm, loading, hideCloseButton,
}) => {
  const ref = React.useRef<HTMLButtonElement>();

  return (
    <Modal
      focusRef={ref}
      open={open}
      onClose={onClose}
      className="w-full max-w-lg kiosk:max-w-4xl kioskSharebox:max-w-2xl"
      hideCloseButton={hideCloseButton}
      title={<FormattedMessage id="signature.signHere" defaultMessage="Sign here" tagName="h2" />}
    >
      <div className="flex flex-col items-center mt-5 kiosk:mt-10" data-testid="signatureModal">
        <SignatureInput onChange={onChange} className="!h-auto kiosk:!h-96 aspect-video w-full" />
        <div className="flex flex-col w-full kiosk:mt-8 kiosk:flex-row-reverse">
          <Button
            ref={ref}
            loading={loading}
            onClick={onConfirm}
            className="w-full mt-5 mb-3 kiosk:my-0 kiosk:ml-5"
            testId="signatureConfirmButton"
            disabled={disabled}
          >
            {confirmMessage}
          </Button>
          <Button
            onClick={onClose}
            type={ButtonType.SECONDARY}
            className="w-full"
            testId="cancel-signature-button"
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SignatureModal;
