import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

import api from 'service/index';
import { PING } from 'constants/url';
import { getUrlParam } from 'utils/urlUtils';

const healthCheckApi = api.injectEndpoints({
  endpoints: (build) => ({
    healthCheck: build.query<void, void>({
      queryFn(_, __, ___, baseQuery) {
        const token = getUrlParam('token');

        return baseQuery({
          method: 'HEAD',
          url: PING,
          ...(token ? { params: { token } } : {}),
          timeout: 1000,
        }) as QueryReturnValue<undefined, FetchBaseQueryError, FetchBaseQueryMeta>;
      },
    }),
  }),
});

export default healthCheckApi;
