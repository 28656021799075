import React from 'react';
import { FORM_ERROR } from 'final-form';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { FormValidator } from 'types/Form';
import ParkingFormType from 'types/ParkingForm';
import FormKeys from 'modules/form/types/FormKeys';
import { displayMobilityParking } from 'modules/selfServices/selectors';

import Popup from '../ui/Popup';
import ParkingForm from './ParkingForm';
import ConnectedForm from '../ui/ConnectedForm';
import PageBaseProps from '../../types/PageBase';
import MandatoryWarning from '../MandatoryWarning';
import getDecorator from '../ui/ConnectedForm/decorator';

const decorator = (hasMobility: boolean) => getDecorator(hasMobility ? FormKeys.MOBILITY_PARKING : FormKeys.PARKING);

const validateForm: FormValidator<ParkingFormType> = (values) => ({
  [FORM_ERROR]: values.x || values.spotNumber ? undefined : 'required',
});

const Parking: React.FC<PageBaseProps> = ({ onNext, onPrev, isPenultimate }) => {
  const isMobility = useSelector(displayMobilityParking);

  const formDecorator = React.useMemo(() => decorator(isMobility), [isMobility]);

  return (
    <ConnectedForm
      validate={validateForm}
      keepDirtyOnReinitialize
      component={ParkingForm}
      decorators={[formDecorator]}
      onPrev={onPrev}
      isPenultimate={isPenultimate}
      onSubmit={onNext}
      formKey={isMobility ? FormKeys.MOBILITY_PARKING : FormKeys.PARKING}
      errorComponent={(
        <Popup title={(
          <FormattedMessage
            tagName="h2"
            id="parking.mandatoryModal.title"
            defaultMessage="You must first enter your parking spot number or point it on the map"
          />
        )}
        >
          <MandatoryWarning />
        </Popup>
      )}
      subscription={{ hasValidationErrors: true, errors: true }}
    />
  );
};
export default Parking;
