import React from 'react';

import PageFooter from 'components/ui/PageFooter';
import { useUpdateSelfServiceMutation } from 'modules/selfServices/service';

import { getParkingInfo, getParkingMaps } from 'modules/selfServices/selectors';
import PageBaseProps from '../../types/PageBase';
import ParkingContent from './ParkingContent';
import { useSelector } from '../../hooks';

const ParkingInstruction: React.FC<PageBaseProps> = ({
  onNext, onPrev, isPenultimate, shouldDisplayBackButton,
}) => {
  const [, { isLoading }] = useUpdateSelfServiceMutation({ fixedCacheKey: 'UPDATE/SELF_SERVICE' });
  const parking = useSelector(getParkingInfo);
  const maps = useSelector(getParkingMaps);

  return (
    <div className="main-content">
      <div className="content">
        <ParkingContent maps={maps} {...parking} />
        <PageFooter onNext={onNext} onPrev={onPrev} isPenultimate={isPenultimate} loading={isLoading} shouldDisplayBackButton={shouldDisplayBackButton} />
      </div>
    </div>
  );
};

export default ParkingInstruction;
