import React from 'react';
import classNames from 'classnames';

type RadioBulletProps<T extends string> = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'title' | 'ref' | 'value' | 'onChange'> & {
  description: React.ReactNode;
  checked: boolean;
  onChange: (value: T) => void;
  title: React.ReactNode;
  value: T;
};

const RadioBullet = <T extends string>({
  value, checked, onChange, title, description, ...inputProps
}: RadioBulletProps<T>) => {
  const id = `item-${value}`;
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div
      id={id}
      className={classNames(
        'flex items-center justify-between w-full p-5 kiosk:p-10 rounded-lg border border-border-bg cursor-pointer text-gray-90 mb-4 kiosk:mb-10',
        { 'hover:bg-tertiary/10': !checked },
        { 'bg-tertiary hover:bg-tertiary text-white': checked },
      )}
      data-testid="radiobullet-wrapper"
      onClick={handleChange}
    >
      <div className="flex flex-col">
        <label
          htmlFor={id}
          className="cursor-pointer text-xl font-bold"
        >
          {title}
        </label>
        <span>
          {description}
        </span>
      </div>
      <input
        className="cursor-pointer appearance-none rounded-full size-5 border border-tertiary border-2 checked:border-0 after:checked:block after:checked:bg-checked after:checked:h-5 after:checked:w-5 after:checked:bg-no-repeat after:checked:bg-cover shrink-0 text-white"
        {...inputProps}
        checked={checked}
        value={value}
        onChange={handleChange}
        type="radio"
      />
    </div>
  );
};

export default RadioBullet;
