import api from 'service/index';

import { SELF_SERVICE } from 'constants/url';

import { SelfService } from 'modules/selfServices/types/SelfService';

import PatchDropOffPointRequest from './types/PatchDropOffPoint';

const stepsApi = api.injectEndpoints({
  endpoints: (build) => ({
    patchDropOffPoint: build.mutation<SelfService, PatchDropOffPointRequest>({
      query: ({ step, selfServiceId }) => ({
        method: 'PATCH',
        url: `${SELF_SERVICE}/${selfServiceId}`,
        body: { dropOffPoint: { step } },
      }),
    }),
  }),
});

export const { usePatchDropOffPointMutation } = stepsApi;

export default stepsApi;
