import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'hooks';
import useQrCodeScanner from 'hooks/useQrCodeScanner';

import { useGetContextQuery } from 'modules/dealers/service';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import {
  displayOriginSelection,
  hasErrorOnKiosk, isKioskFull as isKioskFullSelector,
} from 'modules/dealers/selectors';

import Header from './Header';
import Background from './Background';
import SelfServiceTypeButtons from './SelfServiceTypeButtons';
import SelfServiceOriginButtons from './SelfServiceOriginButtons';

import './index.css';
import Popup from '../ui/Popup';
import KioskError from '../Errors/KioskError';

type State = {
  atDealership?: boolean,
  type?: SelfServiceType,
};

export type HomeButtonProps = {
  onClick: (state: State) => void,
};

const CONTEXT_POLLING_INTERVAL = 60000;

const Home: React.FC = () => {
  useQrCodeScanner();

  const navigate = useNavigate();
  const { search } = useLocation();

  const hasKioskError = useSelector(hasErrorOnKiosk);
  const isKioskFull = useSelector(isKioskFullSelector);
  const displayOriginButtons = useSelector(displayOriginSelection);

  const [showPopup, setShowPopup] = React.useState(false);

  const { data: contextData, isLoading } = useGetContextQuery(undefined, {
    pollingInterval: CONTEXT_POLLING_INTERVAL,
  });

  const {
    dealerName, brand, type, kioskName, kioskBackgroundMedia, backgroundMedia,
  } = contextData ?? {};

  const homeTitle = (!displayOriginButtons && kioskName) || dealerName;

  const handleClick = (state: State) => {
    const shouldShowPopup = (state.atDealership === true && hasKioskError)
      || (state.type === SelfServiceType.BREAKDOWN && isKioskFull);

    setShowPopup(shouldShowPopup);

    if (!shouldShowPopup) {
      navigate(`/login${search}`, {
        state,
      });
    }
  };

  return (
    <Background
      loading={isLoading}
      media={kioskBackgroundMedia ?? backgroundMedia}
    >
      <Header />
      <div className="flex flex-col justify-between grow w-full max-w-7xl z-30">
        <div className="w-full max-w-7xl m-auto text-white mt-8 grow sm:grow-0 px-7">
          <div className="flex items-center flex-col">
            {brand && (
              <div className="size-24 md:w-32 md:h-32" data-testid="logo">
                <div className="bg-white p-3 size-full flex mask-image">
                  <img src={brand.logoPathMobile} alt={brand.name} className="m-auto logo-home" />
                </div>
              </div>
            )}
            {homeTitle && (
              <h1
                className={classNames('home drop-shadow-md opacity-70 uppercase font-bold', {
                  'truncate w-full': kioskName,
                })}
              >
                {homeTitle}
              </h1>
            )}
            <h2 className="home drop-shadow-md">
              <FormattedMessage
                id="home.title"
                // eslint-disable-next-line max-len
                defaultMessage="{type, select, CHECK_IN {Ready for your check-in} CHECK_OUT {Ready for your check-out} other {How can we help you} }?"
                values={{ type }}
              />
            </h2>
          </div>
        </div>
        {!displayOriginButtons && hasKioskError && <KioskError />}

        {displayOriginButtons && <SelfServiceOriginButtons onClick={handleClick} />}
        {!displayOriginButtons && !hasKioskError && <SelfServiceTypeButtons onClick={handleClick} />}

        {showPopup && (
          <Popup onClose={setShowPopup} duration={8000} className="!bg-input-bg">
            {hasKioskError && <KioskError />}
            {!hasKioskError && isKioskFull && (
              <h2 className="py-16 text-center">
                <FormattedMessage
                  id="kiosk.fullWarning"
                  defaultMessage="The kiosk is full. It is no longer possible to drop keys."
                  tagName="h2"
                />
              </h2>
            )}
          </Popup>
        )}
      </div>
    </Background>
  );
};

export default Home;
