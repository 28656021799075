import React from 'react';
import { FormattedMessage } from 'react-intl';
import { b } from 'utils/i18nUtils';
import { useLazyGetQRCodeQuery } from 'modules/qrCode/service';
import { Button } from '../ui';

const QRCODE_TIMER = 60000;

const HomeOutdoorSharebox = () => {
  const timoutRef = React.useRef<NodeJS.Timeout>();
  const [displayQrCode, setDisplayQrCode] = React.useState(false);

  const [getQrCode, response] = useLazyGetQRCodeQuery();

  const hideQrCode = React.useCallback(() => {
    setDisplayQrCode(false);
    URL.revokeObjectURL(response.data);
  }, [response.data]);

  const handleClick = React.useCallback(async () => {
    if (displayQrCode) {
      clearTimeout(timoutRef.current);
      hideQrCode();
    } else {
      const { isError } = await getQrCode();
      setDisplayQrCode(!isError);
      timoutRef.current = setTimeout(hideQrCode, QRCODE_TIMER);
    }
  }, [displayQrCode, getQrCode, hideQrCode]);

  const titleMessage = React.useMemo(() => {
    if (displayQrCode) {
      return {
        id: 'home.outdoorSharebox.qrCodeTitle',
        defaultMessage: '<b>Scan the QR Code</b> to start the check-in/check-out',
      };
    }
    return {
      id: 'home.outdoorSharebox.title',
      defaultMessage: 'Please <b>use the link</b> found in the appointment confirmation message (SMS or email)',
    };
  }, [displayQrCode]);

  const buttonMessage = React.useMemo(() => {
    if (displayQrCode) {
      return { id: 'steps.back', defaultMessage: 'Back' };
    }
    return { id: 'home.outdoorSharebox.generateQrCode', defaultMessage: "I don't have the link" };
  }, [displayQrCode]);

  return (
    <div className="flex flex-col justify-center items-center gap-3 h-svh w-full text-center p-5">
      <div className="text-xl"><FormattedMessage {...titleMessage} values={{ b: (text) => b(text, 'text-xl') }} /></div>
      {displayQrCode && <img src={response.data} alt="QR code" className="aspect-square h-[50svh]" />}
      <Button
        onClick={handleClick}
        className="!py-3 !px-8 bold"
        loading={response.isFetching}
        testId={displayQrCode ? 'back' : 'generate'}
      >
        <FormattedMessage {...buttonMessage} />
      </Button>
    </div>
  );
};

export default HomeOutdoorSharebox;
