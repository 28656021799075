import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'components/ui';
import { ModalProps } from 'components/ui/Modal';

import CourtesyVehicle from '.';

const CourtesyVehicleModal: React.FC<Pick<ModalProps, 'open' | 'onClose'>> = ({ open, onClose }) => (
  <Modal
    open={open}
    onClose={onClose}
    className="w-full max-w-3xl max-h-screen flex flex-col"
    title={<FormattedMessage id="courtesyVehicle.title" defaultMessage="Courtesy vehicle" tagName="h1" />}
  >
    <div className="flex flex-col gap-5 overflow-y-auto">
      <CourtesyVehicle />
    </div>
  </Modal>
);

export default CourtesyVehicleModal;
