import React from 'react';
import { differenceInSeconds } from 'date-fns';

import { formatDate } from 'utils/dateUtils';

interface UseCountdownProps {
  date?: string | Date | number;
}

type UseCountdown = (props: UseCountdownProps) => {
  countdown: number;
};

const getSecondsRemaining = (date: Date | string | number) => {
  const formattedDate = formatDate(date);
  const now = Date.now();

  const diff = differenceInSeconds(formattedDate, now, { roundingMethod: 'ceil' });

  return Number.isNaN(diff) ? 0 : Math.max(0, diff);
};

// Countdown uses a date instead of seconds because when you leave the tab, timer is slowing down in browser
const useCountdown: UseCountdown = ({ date }) => {
  const formattedDate = formatDate(date);
  const [countdown, setCountdown] = React.useState(getSecondsRemaining(formattedDate));

  const handleSetCountdown = React.useCallback(() => setCountdown(getSecondsRemaining(formattedDate)), [formattedDate]);

  React.useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (countdown === 0) {
      handleSetCountdown();
    } else {
      // We use an interval here instead of a timeout
      // because of rounding issues that can stop the updates
      // since React doesn't update the state if the value is the same.
      intervalId = setInterval(handleSetCountdown, 1000);
    }

    return () => clearInterval(intervalId);
  }, [countdown, handleSetCountdown]);

  return {
    // We don't use `useMemo` here since timer is expected to change often.
    countdown,
  };
};

export default useCountdown;
