import api from 'service/index';
import { CONTEXT } from 'constants/url';
import { getUrlParam } from 'utils/urlUtils';

import { ContextResponse } from './types/ContextResponse';

const dealersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContext: build.query<ContextResponse, void>({
      query: () => {
        const token = getUrlParam('token');

        return {
          method: 'GET',
          url: CONTEXT,
          params: token ? { token } : {},
        };
      },
      transformResponse: (response: ContextResponse) => {
        if (Array.isArray(response?.kioskDealers)) {
          const kioskDealersWithSortedBrands = response.kioskDealers.map((dealer) => ({
            ...dealer,
            brands: dealer.brands.sort(({ name: aName }, { name: bName }) => aName.localeCompare(bName)),
          }));
          const sortedBrands = response.brands?.sort(({ name: aName }, { name: bName }) => aName.localeCompare(bName));
          return { ...response, kioskDealers: kioskDealersWithSortedBrands, brands: sortedBrands };
        }
        return response;
      },
    }),
  }),
});

export const { useGetContextQuery } = dealersApi;

export default dealersApi;
