import { UIStep } from 'components/ui/Steps';

import { SelfServiceType } from 'modules/selfServices/types/SelfService';
import Login from './Login';
import Verify from './Verify';
import BreakdownInstructions from '../BreakdownInstructions';

export type Step = UIStep<{
  isVisible: boolean | ((type:SelfServiceType) => boolean);
}>;

const STEPS: Record<string, Step> = {
  INSTRUCTIONS: {
    key: 'INSTRUCTIONS',
    component: BreakdownInstructions,
    next: 'LOGIN',
    data: {
      hideHeader: true,
      isVisible: (type) => type === SelfServiceType.BREAKDOWN,
    },
  },
  LOGIN: {
    key: 'LOGIN',
    component: Login,
    next: 'VERIFY',
    previous: 'INSTRUCTIONS',
    data: {
      hideHeader: true,
      isVisible: true,
    },
  },
  VERIFY: {
    key: 'VERIFY',
    component: Verify,
    // In the login component, we redirect to the other pages, so the only way to get this step is by doing a login MFA
    previous: 'LOGIN',
    data: {
      hideHeader: true,
      isVisible: true,
    },
  },
};

export const ALL_STEPS: Array<Step> = Object.values(STEPS);
