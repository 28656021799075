import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormRenderProps, useField } from 'react-final-form';
import { ArrowUpTrayIcon, ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline';

import PageBaseProps from 'types/PageBase';
import { MediaInterface } from 'types/Media';
import type { RepairOrderForm as RepairOrderFormType } from 'types/RepairOrderForm';

import { useSelector } from 'hooks';
import useUpload from 'hooks/useUpload';
import { formatToArray } from 'utils/formUtils';
import { GET_MEDIA_UPLOAD_URL } from 'constants/url';
import { SUPPORTED_MEDIAS } from 'constants/mediaTypes';
import useLabelTranslation from 'hooks/useLabelTranslation';

import { isPublicDevice } from 'modules/dealers/selectors';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';

import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import {
  Button, ExpandableList, FileList, PageFooter,
} from 'components/ui';
import { ButtonType } from 'components/ui/Button';
import FormTextarea from 'components/ui/form/FormTextarea';

import RepairOrderItem from './RepairOrderItem';
import { MediaEntityType, MediaSubType } from '../../types/MediaTypeEnum';

const RepairOrderForm: React.FC<FormRenderProps<RepairOrderFormType> & Pick<PageBaseProps, 'onPrev'>> = ({
  onPrev,
  handleSubmit,
}) => {
  const intl = useIntl();
  const { getLabelTranslation } = useLabelTranslation();

  const isPublic = useSelector(isPublicDevice);
  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const { data: selfService } = useGetSelfServiceByIdQuery({ id: selfServiceId });
  const { currencyIsoCode } = selfService.dealer;

  const {
    value: formMedias,
    onChange: setMedias,
  } = useField('medias', { subscription: { value: true }, format: formatToArray<MediaInterface> }).input;
  const { value: customerComment } = useField('customerComment', { subscription: { value: true } }).input;

  const {
    medias, browse, upload, remove, isLoading,
  } = useUpload(selfServiceId, formMedias);

  const [displayCustomerComment, setDisplayCustomerComment] = React.useState(Boolean(customerComment));

  const repairOrder = React.useMemo(
    () => selfService?.repairOrder?.map(({ adviceName, description, ...rest }) => ({
      name: getLabelTranslation(adviceName),
      description: description ? getLabelTranslation(description) : undefined,
      ...rest,
    })) ?? [],
    [selfService?.repairOrder, getLabelTranslation],
  );

  React.useEffect(() => {
    setMedias(medias);
  }, [setMedias, medias]);

  const handleShowComment = React.useCallback(() => {
    setDisplayCustomerComment(true);
  }, []);

  const handleUpload = React.useCallback(async () => {
    try {
      const files = Array.from(await browse({ multiple: true, accept: SUPPORTED_MEDIAS.REPAIR_ORDER }));
      await Promise.all(files.map((file) => upload({
        file,
        filename: file.name,
        contentType: file.type,
        url: GET_MEDIA_UPLOAD_URL,
        subType: MediaSubType.MEDIAS,
        entityType: MediaEntityType.SELF_SERVICE,
      })));
    } catch { /* Do nothing */ }
  }, [browse, upload]);

  const handleRemove = React.useCallback((media: MediaInterface) => remove(media.uploadId ?? media.id), [remove]);

  return (
    <>
      <div className="content">
        <div className="flex flex-col align-middle items-center w-full">
          {repairOrder.length > 0 && (
            <ExpandableList
              data={repairOrder.map((data) => (
                <RepairOrderItem
                  {...data}
                  currency={currencyIsoCode}
                  vat={selfService.valueAddedTax}
                />
              ))}
            />
          )}
          {!displayCustomerComment && (
            <Button
              type={ButtonType.SECONDARY}
              className="w-full mt-5"
              testId="AddComment"
              onClick={handleShowComment}
            >
              <div className="flex justify-center items-center ">
                <ChatBubbleBottomCenterIcon className="h-5 w-5 mr-3" />
                <FormattedMessage id="repairOrder.addComment" defaultMessage="Add comment" />
              </div>
            </Button>
          )}
          {displayCustomerComment && (
            <FormTextarea
              name="customerComment"
              className="w-full px-5 kiosk:px-10 mt-5"
              label={intl.formatMessage({ id: 'repairOrder.comment', defaultMessage: 'Comment' })}
              data-testid="comment"
              description={(
                <FormattedMessage
                  id="repairOrder.commentDescription"
                  defaultMessage="Specific requests are subject to parts and technician availability"
                />
              )}
            />
          )}
          {!isPublic && (
            <Button
              loading={isLoading}
              onClick={handleUpload}
              testId="addAttachments"
              className="w-full mt-5"
              type={ButtonType.SECONDARY}
            >
              <div className="flex justify-center items-center">
                <ArrowUpTrayIcon className="h-5 w-5 mr-3" />
                <FormattedMessage id="repairOrder.addAttachments" defaultMessage="Add attachments" />
              </div>
            </Button>
          )}
          {medias.length > 0 && (<FileList medias={medias} onDelete={handleRemove} className="w-full mt-5" />)}
        </div>
      </div>
      <PageFooter onNext={handleSubmit} onPrev={onPrev} loading={isLoading} />
    </>
  );
};

export default RepairOrderForm;
