import { createAction } from '@reduxjs/toolkit';

import type { KeysSafeAction, DoorsStatus } from 'modules/kiosk/types/KeysSafe';

interface DoorStatusMeta {
  origin?: DoorsStatus
}
type PrepareActionDoorStatus = (doorStatus: DoorsStatus, meta?: DoorStatusMeta) => { payload: DoorsStatus, meta: DoorStatusMeta };

export const openKeysSafe = createAction<KeysSafeAction>('@KIOSK/OPEN_KEYS_SAFE');
export const closeKeysSafe = createAction<KeysSafeAction>('@KIOSK/CLOSE_KEYS_SAFE');
export const setDoorStatus = createAction<PrepareActionDoorStatus, 'DOORS/SET_STATUS'>(
  'DOORS/SET_STATUS',
  (payload, meta = {}) => ({
    payload,
    meta,
  }),
);
export const setDoorError = createAction<boolean, 'DOORS/SET_ERROR'>('DOORS/SET_ERROR');
export const setIsKioskReady = createAction<boolean, 'KIOSK/SET_IS_READY'>('KIOSK/SET_IS_READY');

export const setIsKioskOffline = createAction<boolean, 'KIOSK/SET_OFFLINE'>('KIOSK/SET_OFFLINE');
