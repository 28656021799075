import React from 'react';

const isoMapping: Record<string, string> = {
  en: 'gb',
  sv: 'se',
};

interface FlagProps {
  locale: string;
}

const Flag: React.FC<FlagProps> = ({ locale }) => {
  const isoLocale = isoMapping[locale] ?? locale;

  return (
    <span
      data-testid={`selected-language-${locale}`}
      className={`fi fi-${isoLocale} !bg-cover rounded-full shadow-inner size-6 kiosk:size-8`}
    />
  );
};

export default Flag;
