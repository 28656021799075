import React from 'react';
import { goBack } from 'redux-first-history';
import { FormattedMessage } from 'react-intl';

import { useDispatch } from 'hooks';

import { logout } from 'modules/auth/actions';

import { Button } from 'components/ui';
import { ButtonType } from 'components/ui/Button';

import SignOut from 'assets/icons/signOut.svg';

interface FooterProps {
  hideBackButton?: boolean;
}

const Footer: React.FC<FooterProps> = ({ hideBackButton }) => {
  const dispatch = useDispatch();

  const handleOnBackClick = () => dispatch(goBack());
  const handleOnLogoutClick = () => dispatch(logout(undefined, { asAdmin: true }));

  return (
    <div className="nextButton gap-5">
      {!hideBackButton && (
        <Button type={ButtonType.SECONDARY} onClick={handleOnBackClick} className="min-w-18" testId="btn-back">
          <FormattedMessage id="steps.back" defaultMessage="Back" />
        </Button>
      )}
      <Button onClick={handleOnLogoutClick} className="min-w-18" testId="btn-log-out">
        <div className="flex justify-center items-center gap-3">
          <SignOut className="fill-white size-6 kiosk:size-8" />
          <FormattedMessage id="socket.updateLogoutModal.logOut" defaultMessage="Logout" values={{ countdown: 0 }} />
        </div>
      </Button>
    </div>
  );
};

export default Footer;
