import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames, { Argument } from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { b } from 'utils/i18nUtils';

import { logout } from 'modules/auth/actions';

import { getCurrentStep } from 'modules/steps/selector';
import { getOrigin, getSelfServiceType } from 'modules/dealers/selectors';

import Arrow from 'assets/icons/arrow.svg';
import SignOut from 'assets/icons/signOut.svg';
import ArrowDirection from 'assets/icons/Arrow';

import Confirm from './Confirm';
import Button, { ButtonType } from './Button';
import StepIndicator, { StepIndicatorProps } from './StepIndicator';

export interface StepsHeaderProps extends Partial<StepIndicatorProps> {
  onPrev?: () => void;
  className?: Argument;
  hideSteps?: boolean;
  hidePrevButton?: boolean;
}

const StepsHeader: React.FC<StepsHeaderProps> = ({
  currentIndex, length, onPrev, hideSteps, className, hidePrevButton,
}) => {
  const dispatch = useDispatch();

  const [showSignOutModal, setShowSignOutModal] = React.useState(false);

  const type = useSelector(getSelfServiceType);
  const origin = useSelector(getOrigin);
  const currentStep = useSelector(getCurrentStep);

  const handleCloseModal = React.useCallback(() => {
    setShowSignOutModal(false);
  }, []);

  const handleConfirmModal = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleSignOut = React.useCallback(() => setShowSignOutModal(true), []);
  const handleClick = React.useCallback(() => {
    if (currentIndex === 0) {
      handleSignOut();
    } else {
      onPrev();
    }
  }, [currentIndex, onPrev, handleSignOut]);

  return (
    <>
      <div className={classNames('w-14 flex py-2 kiosk:py-10 items-center kiosk:w-64', className)}>
        {currentIndex >= 0 && (
          <Button
            analytics={{
              category: 'Logout',
              action: `${origin} ${type} logout`,
              label: `Logout started from ${currentStep}`,
            }}
            type={ButtonType.SECONDARY}
            onClick={handleSignOut}
            testId="kiosk-prev-step"
            className="kiosk:!p-7 w-auto flex-row items-center justify-center hidden kiosk:flex max-w-full"
          >
            <SignOut className="size-6 shrink-0 mr-2 fill-gray-90" />
            <p className="truncate text-gray-90 !text-xl">
              <FormattedMessage id="header.title" defaultMessage="Log out" />
            </p>
          </Button>
        )}
        {!hidePrevButton && (
          <Button
            analytics={currentIndex === 0 ? {
              category: 'Logout',
              action: `${origin} ${type} logout`,
              label: `Logout started from ${currentStep}`,
            } : undefined}
            type={ButtonType.SECONDARY}
            onClick={handleClick}
            testId="prev-step"
            className="!p-2 kiosk:hidden"
          >
            {currentIndex === 0 && <SignOut className="size-6 fill-gray-90" />}
            {currentIndex !== 0 && <Arrow className={classNames('size-6', ArrowDirection.LEFT)} />}
          </Button>
        )}
      </div>
      {!hideSteps && <StepIndicator currentIndex={currentIndex} length={length} />}
      <Confirm
        focusCancel
        open={showSignOutModal}
        onCancel={handleCloseModal}
        onConfirm={handleConfirmModal}
        cancel={<FormattedMessage id="header.cancel" defaultMessage="No, continue" />}
        confirm={<FormattedMessage id="header.confirm" defaultMessage="Yes, cancel" />}
        title={(
          <h2 className="flex justify-center items-center p-4">
            <SignOut className="size-6 kiosk:size-12 mr-3 fill-gray-90" />
            <FormattedMessage id="header.title" defaultMessage="Log out" />
          </h2>
        )}
        question={(
          <FormattedMessage
            id={`header.${type?.toLowerCase()}.backModal.content`}
            defaultMessage={`Are you sure you want to <b>cancel</b> your ${type?.toLowerCase()} ?`}
            tagName="p"
            values={{ b }}
          />
        )}
      />
    </>
  );
};

export default StepsHeader;
