import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, AlertModal } from 'components/ui';
import { getNotifications } from 'modules/notifications/selectors';
import { removeNotification } from 'modules/notifications/actions';
import { ComponentType } from 'modules/notifications/types/Notification';
import { createDictionary } from 'utils/objectUtils';

export const NOTIFICATION_TIMEOUT = 5000;

const componentByType = createDictionary({
  [ComponentType.ALERT]: Alert,
  [ComponentType.MODAL]: AlertModal,
}, Alert);

const Notifications: React.FC = () => {
  const dispatch = useDispatch();

  const currentNotifications = useSelector(getNotifications);

  const handleNotificationEnd = React.useCallback(() => dispatch(removeNotification()), []);

  return (
    <div className="fixed top-12 right-4 flex flex-col h-max z-50">
      {currentNotifications.map(({
        id, title, description, collapse, type, duration, componentType,
      }) => {
        const Component = componentByType[componentType];
        return (
          <Component
            key={id}
            type={type}
            title={title}
            collapse={collapse}
            description={description}
            onClose={handleNotificationEnd}
            duration={duration ?? NOTIFICATION_TIMEOUT}
          />
        );
      })}
    </div>
  );
};

export default Notifications;
