import { IntlShape } from 'react-intl';

import { createDictionary } from 'utils/objectUtils';

import frenchLayout from 'simple-keyboard-layouts/build/layouts/french';
import germanLayout from 'simple-keyboard-layouts/build/layouts/german';
import polishLayout from 'simple-keyboard-layouts/build/layouts/polish';
import italianLayout from 'simple-keyboard-layouts/build/layouts/italian';
import englishLayout from 'simple-keyboard-layouts/build/layouts/english';
import spanishLayout from 'simple-keyboard-layouts/build/layouts/spanish';
import swedishLayout from 'simple-keyboard-layouts/build/layouts/swedish';
import norwegianLayout from 'simple-keyboard-layouts/build/layouts/norwegian';

// eslint-disable-next-line max-len
const shiftKey = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2_10)"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.2985 5.24308C11.1746 4.17415 12.8254 4.17415 13.7015 5.24308L19.383 12.1744C19.4849 12.2988 19.5405 12.4539 19.5405 12.614C19.5405 12.9998 19.2238 13.3126 18.8331 13.3126H15.8919C15.2202 13.3126 14.6757 13.8504 14.6757 14.5138V18.8379C14.6757 19.236 14.349 19.5586 13.9459 19.5586H10.0541C9.65104 19.5586 9.32432 19.236 9.32432 18.8379V14.5138C9.32432 13.8504 8.7798 13.3126 8.10811 13.3126H5.16688C4.77618 13.3126 4.45946 12.9998 4.45946 12.614C4.45946 12.4539 4.51509 12.2988 4.61704 12.1744L10.2985 5.24308ZM14.8359 4.33617C13.3756 2.55461 10.6244 2.55461 9.16411 4.33617L3.48268 11.2675C3.17039 11.6485 3 12.1238 3 12.614C3 13.7959 3.97014 14.754 5.16688 14.754H7.86486V18.8379C7.86486 20.032 8.845 21 10.0541 21H13.9459C15.155 21 16.1351 20.032 16.1351 18.8379V14.754H18.8331C20.0299 14.754 21 13.7959 21 12.614C21 12.1238 20.8296 11.6485 20.5173 11.2675L14.8359 4.33617Z" fill="#323232"/></g></svg>';
// eslint-disable-next-line max-len
const bkspKey = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_0_3)"><path d="M10.71 10.53L14.71 14.53C15 14.82 15.47 14.82 15.77 14.53C16.06 14.23 16.06 13.76 15.77 13.46L11.77 9.46C11.47 9.16 11 9.16 10.7 9.46C10.4 9.75 10.4 10.22 10.7 10.52L10.71 10.53Z" fill="#ffffff"/><path d="M14.71 9.46L10.71 13.46C10.41 13.75 10.41 14.22 10.71 14.52C11 14.81 11.47 14.81 11.77 14.52L15.77 10.52C16.06 10.22 16.06 9.75 15.77 9.45C15.47 9.15 15 9.15 14.7 9.45L14.71 9.46Z" fill="#ffffff"/><path d="M7.41 17.29L4.39 13.74C3.52 12.72 3.52 11.24 4.38 10.23L7.39 6.68C7.9 6.07 8.65 5.72 9.45 5.72H17.51C19 5.72 20.21 6.93 20.21 8.42V15.5C20.21 16.99 18.99 18.2 17.5 18.2H9.43C8.63 18.2 7.88 17.85 7.36 17.24L7.41 17.29ZM6.26 18.26C7.05 19.19 8.23 19.74 9.46 19.73H17.52C19.84 19.73 21.72 17.84 21.72 15.52V8.43C21.72 6.1 19.83 4.22 17.51 4.22H9.44C8.2 4.21 7.03 4.76 6.23 5.69L3.21 9.23C1.87 10.8 1.87 13.11 3.21 14.68L6.22 18.22L6.26 18.26Z" fill="#ffffff"/></g></svg>';
// eslint-disable-next-line max-len
const enterKey = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_0_3)"><path d="M19.3175 6.9081L19.3175 10.5405C19.3175 11.376 18.6516 12.054 17.804 12.054L3.27437 12.054C2.77794 12.054 2.36627 12.4536 2.36627 12.9621C2.36627 13.4586 2.77794 13.8702 3.27437 13.8702L17.804 13.8702C19.6444 13.8702 21.1337 12.3688 21.1337 10.5405L21.1337 6.9081C21.1337 6.39957 20.7342 6 20.2256 6C19.7292 6 19.3175 6.39957 19.3175 6.9081Z" fill="white" /><path d="M6.26508 8.67592L2.63267 12.3083C2.28153 12.6595 2.28153 13.2285 2.63267 13.5918L6.26508 17.2242C6.62832 17.5753 7.1974 17.5753 7.56064 17.2242C7.92388 16.861 7.92388 16.2919 7.56064 15.9286L3.92823 12.2962L3.92823 13.5797L7.56064 9.94726C7.92388 9.58402 7.92388 9.01494 7.56064 8.6517C7.2095 8.28846 6.64043 8.28846 6.27719 8.6517L6.26508 8.67592Z" fill="white" /></g><defs><clipPath id="clip0_0_3"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>';

const EXCLUDED_KEYS = {
  default: ['{tab}', '{lock}', '`', '=', '[', ']', '\\', ';', ',', '/', '.com'],
  shift: ['{tab}', '{lock}', '!', '#', '$', '%', '^', '*', '(', ')', '{', '}', '|', ':', '<', '>', '.com', '@'],
};

const EXCLUDED_KEYS_WITH_ENTER = {
  default: [...EXCLUDED_KEYS.default, '{enter}'],
  shift: [...EXCLUDED_KEYS.shift, '{enter}'],
};

export const WRITABLE_INPUT_TYPES = ['textarea', 'email', 'text', 'number', 'search', 'url', 'tel', 'password'];
export const WRITABLE_INPUT_TYPES_CONFIG = createDictionary({
  textarea: {
    excludedKeys: EXCLUDED_KEYS,
  },
  number: {
    layout: {
      default: ['1 2 3', '4 5 6', '7 8 9', ' 0 {bksp}'],
    },
    excludedKeys: EXCLUDED_KEYS,
  },
  tel: {
    layout: {
      default: ['1 2 3', '4 5 6', '7 8 9', '+ 0 {bksp}'],
    },
    excludedKeys: EXCLUDED_KEYS,
  },
}, { excludedKeys: EXCLUDED_KEYS_WITH_ENTER });

export const BUTTON_LABELS = (intl: IntlShape) => ({
  '{shift}': shiftKey,
  '{space}': intl.formatMessage({ id: 'keyboard.keys.space', defaultMessage: 'Space' }),
  '{bksp}': bkspKey,
  '{enter}': enterKey,
});

export const LAYOUT_BY_LANGUAGE = createDictionary({
  fr: frenchLayout.layout,
  es: spanishLayout.layout,
  de: germanLayout.layout,
  it: italianLayout.layout,
  no: norwegianLayout.layout,
  pl: polishLayout.layout,
  sv: swedishLayout.layout,
}, englishLayout.layout);

export const getPortalContainer = () => {
  let portal = document.getElementById('keyboard-portal');

  if (!portal) {
    portal = document.createElement('div');
    portal.id = 'keyboard-portal';
    portal.className = 'fixed z-60';
    document.body.appendChild(portal);
  }

  return portal;
};
