/* eslint-disable react/style-prop-object */
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { computeTax } from 'utils/priceUtils';

import { AbstractMedia } from 'types/AbstractMedia';

import { isPublicDevice } from 'modules/dealers/selectors';

import FileList from 'components/ui/FileList';
import { RepairOrder } from 'modules/selfServices/types/SelfService';

const TRUNCATE_LIMIT = 220;

interface RepairOrderItemProps extends Omit<RepairOrder, 'adviceName' | 'description'> {
  description?: string;
  name: string;
  vat: number;
  currency: string;
  medias?: AbstractMedia[];
}
const RepairOrderItem: React.FC<RepairOrderItemProps> = ({
  name,
  description,
  price,
  discountedPrice,
  currency,
  vat,
  medias,
  coefficient,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const isPublic = useSelector(isPublicDevice);
  const isTruncated = description?.length > TRUNCATE_LIMIT;

  const showPrice = discountedPrice >= 0;
  const showDiscount = price !== discountedPrice;

  const toggleDescription = React.useCallback(() => setIsExpanded((currentIsExpanded) => !currentIsExpanded), []);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between gap-2">
        <div>
          <div data-testid="repair-order-name" className="text-gray-90 font-bold break-words min-w-24 kiosk:text-3xl">
            {name}
          </div>
          <div data-testid="repair-order-description" className="text-gray-70 break-words text-sm whitespace-pre-wrap">
            {isExpanded || !isTruncated ? description : `${description?.slice(0, TRUNCATE_LIMIT)}...`}
            {isTruncated && (
              <span
                className="underline text-sm cursor-pointer ml-1"
                onClick={toggleDescription}
                data-testid="expand-description"
              >
                {isExpanded ? (
                  <FormattedMessage id="expand.less" defaultMessage="View less" />
                ) : (
                  <FormattedMessage id="expand.more" defaultMessage="View more" />
                )}
              </span>
            )}
          </div>
        </div>
        {showPrice && (
          <div className="flex-none flex flex-col justify-center items-end">
            <p className="font-bold text-gray-90" data-testid="repair-order-discountedPrice">
              <FormattedNumber
                style="currency"
                currency={currency}
                currencyDisplay="narrowSymbol"
                value={computeTax(discountedPrice, vat, coefficient)}
              />
            </p>
            {showDiscount && (
              <p className="line-through italic font-medium text-sm" data-testid="repair-order-price">
                <FormattedNumber
                  style="currency"
                  currency={currency}
                  currencyDisplay="narrowSymbol"
                  value={computeTax(price, vat, coefficient)}
                />
              </p>
            )}
          </div>
        )}
      </div>
      {medias?.length > 0 && <FileList medias={medias} showDownload={!isPublic} />}
    </div>
  );
};

export default RepairOrderItem;
