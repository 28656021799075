import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { b } from 'utils/i18nUtils';

import { Button, Modal } from 'components/ui';
import { ButtonType } from 'components/ui/Button';

interface Props {
  open: boolean;
  buttonText: MessageDescriptor;
  onClick: () => void;
  onCancel: () => void;
  loading?: boolean;
}

const PendingModal: React.FC<Props> = ({
  open, loading, buttonText, onClick, onCancel,
}) => {
  const ref = React.useRef();
  return (
    <Modal
      focusRef={ref}
      open={open}
      className="w-full max-w-md kiosk:max-w-4xl kioskSharebox:max-w-2xl"
      title={(
        <FormattedMessage
          id="page.finalInvoice.modal.pending.title"
          defaultMessage="Payment in progress"
          tagName="h2"
        />
      )}
    >
      {loading && <span className="loader !w-6 !h-6" />}
      {!loading && (
        <div data-testid="pending-payment-warning-modal" className="flex flex-col items-center mt-5 kiosk:mt-10">
          <FormattedMessage
            id="page.finalInvoice.modal.pending.content"
            defaultMessage="A payment is currently in progress, please wait. If you have canceled the transaction, you can safely pay again."
            tagName="p"
            values={{ b }}
          />
          <div className="flex flex-col kiosk:flex-row w-full mt-4 kiosk:mt-10">
            <Button onClick={onClick} testId="pay-button-modal" className="w-full mt-5 mb-3 kiosk:mr-5 kiosk:my-0">
              <FormattedMessage {...buttonText} />
            </Button>
            <Button
              type={ButtonType.SECONDARY}
              ref={ref}
              onClick={onCancel}
              className="w-full kiosk:ml-5"
              testId="back-button-modal"
            >
              <FormattedMessage id="steps.back" defaultMessage="Back" />
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PendingModal;
