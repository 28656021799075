import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { getOrigin, isRemoteKiosk, isShareboxIndoorKiosk } from 'modules/dealers/selectors';

export const getAppOrigin = createDraftSafeSelector(
  getOrigin,
  isRemoteKiosk,
  isShareboxIndoorKiosk,
  (origin, remoteKiosk, shareboxIndoorKiosk) => {
    if (remoteKiosk) {
      return 'REMOTE_KIOSK';
    }
    if (shareboxIndoorKiosk) {
      return 'SHAREBOX_INDOOR_KIOSK';
    }
    return origin ?? '';
  },
);

export const isLowPerfMode = isShareboxIndoorKiosk;
