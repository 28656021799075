import React from 'react';
import classNames, { Argument } from 'classnames';
import SignatureCanvas from 'react-signature-canvas';
import { XCircleIcon } from '@heroicons/react/24/solid';

interface SignatureInputProps {
  onChange: (signature?: HTMLCanvasElement) => void;
  'data-testid'?: string;
  className?: Argument;
}

const SignatureInput: React.FC<SignatureInputProps> = ({ onChange, 'data-testid': testId, className }) => {
  const signatureRef = React.useRef<SignatureCanvas>();
  const observerRef = React.useRef<ResizeObserver>();

  const handleSignatureChange = React.useCallback(() => {
    if (signatureRef.current) {
      onChange(signatureRef.current.getTrimmedCanvas());
    }
  }, [onChange]);

  const handleReset = React.useCallback(() => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      onChange();
    }
  }, [onChange]);

  // SEE: https://github.com/agilgur5/react-signature-canvas/issues/44
  const measuredRef = React.useCallback((node: SignatureCanvas) => {
    signatureRef.current = node;
    if (signatureRef.current) {
      observerRef.current?.disconnect();
      const canvas = signatureRef.current.getCanvas();

      if (canvas) {
        observerRef.current = new ResizeObserver(() => {
          if (signatureRef.current && canvas?.parentElement) {
            const signature = signatureRef.current.toData();

            canvas.height = canvas.parentElement.clientHeight;
            canvas.width = canvas.parentElement.clientWidth;

            signatureRef.current.fromData(signature);
          }
        });

        observerRef.current.observe(canvas);
      }
    }
  }, []);

  return (
    <div
      data-testid={testId}
      className={classNames(
        'relative rounded-lg kiosk:rounded-2xl h-80 kiosk:h-192 w-full border-2 border-dashed overflow-hidden signature',
        className,
      )}
    >
      <SignatureCanvas
        ref={measuredRef}
        clearOnResize={false}
        onEnd={handleSignatureChange}
        canvasProps={{ className: 'h-full w-full bg-gray-100' }}
      />
      <XCircleIcon
        onClick={handleReset}
        className="absolute rounded-full bg-gray-100 right-0 bottom-0 m-2 kiosk:m-4 size-8 kiosk:size-16 text-gray-700"
      />
    </div>
  );
};

export default SignatureInput;
