import React from 'react';
import { useIntl } from 'react-intl';
import { useField, UseFieldConfig } from 'react-final-form';

import Civilities from 'constants/civilities';
import { required, validateEmail } from 'utils/formUtils';

import { isEqualPhoneNumber } from 'modules/selfServices/types/SelfService';

import FormInput from 'components/ui/form/FormInput';
import PhoneNumberInput from 'components/ui/PhoneNumberInput';

import LanguageInput from './LanguageInput';
import CivilityInput from './CivilityInput';

const fieldConfig: UseFieldConfig<string> = { subscription: { value: true } };

export interface ContactFormProps {
  onError?: (hasError: boolean) => void;
  isPhoneNumberMandatory?: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ onError, isPhoneNumberMandatory }) => {
  const intl = useIntl();

  const { value: civility } = useField('civility', fieldConfig).input;
  const phoneNumberInput = useField('phoneNumber', {
    ...fieldConfig,
    isEqual: isEqualPhoneNumber,
  }).input;

  const isCompany = civility === Civilities.COMPANY;
  const isOtherCivility = civility === Civilities.NONE;

  return (
    <div className="space-y-4 mb-4">
      <LanguageInput />
      <CivilityInput />
      {isCompany && (
        <FormInput
          label={intl.formatMessage({ id: 'customerInfo.companyName', defaultMessage: 'Company name' })}
          name="companyName"
          required
          validate={required}
          data-testid="companyName-input"
        />
      )}
      {!isCompany && (
        <>
          <FormInput
            label={intl.formatMessage({ id: 'customerInfo.firstName', defaultMessage: 'First name' })}
            name="firstName"
            data-testid="firstName-input"
            autoComplete="given-name"
          />
          <FormInput
            key={`${civility}_lastName`}
            label={intl.formatMessage({ id: 'customerInfo.lastName', defaultMessage: 'Last name' })}
            name="lastName"
            required={!isOtherCivility}
            validate={!isOtherCivility ? required : undefined}
            data-testid="lastName-input"
            autoComplete="family-name"
          />
        </>
      )}
      <PhoneNumberInput
        onError={onError}
        required={isPhoneNumberMandatory}
        {...phoneNumberInput}
      />
      <FormInput
        label={intl.formatMessage({ id: 'customerInfo.email', defaultMessage: 'Email' })}
        name="email"
        validate={validateEmail}
        data-testid="email-input"
        type="email"
        inputMode="email"
        autoComplete="email"
      />
    </div>
  );
};
export default ContactForm;
