import { createListenerMiddleware } from '@reduxjs/toolkit';

import { logout } from 'modules/auth/actions';
import { setCurrentStep } from 'modules/steps/actions';
import * as actions from 'modules/selfServices/actions';

import chatApi from 'modules/chat/service';
import FormKeys from 'modules/form/types/FormKeys';
import Conciliate from 'modules/form/conciliateUtils';
import { AppStartListening } from 'App/ListenerMiddleware';
import selfServicesApi from 'modules/selfServices/service';

import { ErrorTypeEnum, isApiErrorResponse } from 'types/Error';

import setAppOrigin from 'utils/appUtils';
import type rootReducer from 'App/rootReducer';
import formActions from 'modules/form/actions';
import { getAppOrigin } from 'modules/app/selectors';
import { CustomerInfoForm } from 'types/CustomerInfoForm';
import { getTotalElapsedTime } from 'modules/elapsedTime/selectors';
import { getLoginData, getSelectedSelfServiceId } from 'modules/auth/selectors';
import { getFormattedFormData, getFormValuesByKey } from 'modules/form/selectors';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

startAppListening({
  actionCreator: actions.finalizeSelfService,
  effect: async ({ payload }, { dispatch, getState }) => {
    const totalElapsedTime = getTotalElapsedTime(getState());
    const selfServiceId = getSelectedSelfServiceId(getState());

    // Get the selfService
    const selfService = await dispatch(selfServicesApi.endpoints.getSelfServiceById.initiate(
      { id: selfServiceId },
    )).unwrap();

    const formattedData = getFormattedFormData(getState(), selfService);
    try {
      await dispatch(selfServicesApi.endpoints.updateSelfService.initiate({
        id: selfServiceId,
        body: {
          ...selfService,
          ...formattedData,
          elapsedTime: totalElapsedTime + payload.time,
        },
      }, { fixedCacheKey: 'UPDATE/SELF_SERVICE' })).unwrap();
      if (payload.shouldReset) {
        dispatch(logout({ doorTimeout: true }));
      } else {
        dispatch(setCurrentStep(payload.nextStep));
      }
    } catch (error) {
      const isWrongStatus = isApiErrorResponse(error) && error.data.errorType === ErrorTypeEnum.WRONG_STATUS;
      if (isWrongStatus) {
        dispatch(setCurrentStep(payload.nextStep));
      }
    }
  },
});

// Re-fetch the final instructions after the chat is read (for the first time) to hide the indicator
startAppListening({
  matcher: chatApi.endpoints.readMessages.matchFulfilled,
  effect: (_, { dispatch, getState }) => {
    const selfServiceId = getSelectedSelfServiceId(getState());
    const finalInstructions = selfServicesApi.endpoints.getFinalInstructions.select({ selfServiceId })(getState());
    if (finalInstructions.data?.unreadCount > 0) {
      dispatch(selfServicesApi.endpoints.getFinalInstructions.initiate({ selfServiceId }, { forceRefetch: true }));
    }
  },
});

startAppListening({
  matcher: selfServicesApi.endpoints.getSelfServiceById.matchFulfilled,
  effect: (_, { getState }) => {
    const state = getState() as ReturnType<typeof rootReducer>;
    const key = getAppOrigin(state);
    setAppOrigin(key);
  },
});

startAppListening({
  matcher: selfServicesApi.endpoints.getSelfServiceById.matchFulfilled,
  effect: (action, { dispatch, getState }) => {
    const { payload: selfService } = action;
    const state = getState() as ReturnType<typeof rootReducer>;

    const loginData = getLoginData(state);

    const ids = [
      FormKeys.QUESTIONS,
      FormKeys.KIOSK_USAGE,
      FormKeys.EXTRAS,
      FormKeys.CUSTOMER_INFO,
      FormKeys.PARKING,
      FormKeys.MOBILITY_PARKING,
      FormKeys.MOBILITY,
      FormKeys.REPAIR_ORDER,
    ];

    // Conciliate received data and cached data
    const newCache = ids.reduce((acc, formKey) => {
      const fn = Conciliate[`${formKey}Conciliate`];
      if (typeof fn === 'function') {
        acc.push({
          key: formKey,
          values: fn({ selfService, loginData }, getFormValuesByKey(state, formKey)),
        });
      }
      return acc;
    }, []);

    dispatch(formActions.upsertFormMany(newCache));
  },
});

startAppListening({
  matcher: selfServicesApi.endpoints.updateCustomerInfo.matchFulfilled,
  effect: (action, { dispatch }) => {
    const { permanent } = action.meta.arg.originalArgs.params;

    dispatch(
      formActions.upsertForm({
        key: FormKeys.CUSTOMER_INFO,
        values: {
          ...action.payload.customerInfo,
          permanent: permanent.toString(),
        } as CustomerInfoForm,
      }),
    );
  },
});

export default listenerMiddleware;
