import React from 'react';
import { useIntl } from 'react-intl';
import { FieldValidator, getIn } from 'final-form';
import { useField, useForm } from 'react-final-form';

import { GET_MEDIA_UPLOAD_URL, SELF_SERVICE, VEHICLE_CHECK } from 'constants/url';

import { AbstractMedia, MediaType } from 'types/AbstractMedia';
import { Item, VehicleCheckModelType } from 'modules/vehicleCheck/types/VehicleCheckModel';

import useLabelTranslation from 'hooks/useLabelTranslation';

import { getSelfServiceId } from 'modules/vehicleCheck/selectors';

import {
  useUploadMediaMutation,
  useGetMediaUrlMutation,
} from 'modules/medias/service';

import VehicleCheckImage from 'assets/images/vehicleCheckDefaultCard.jpg';

import MediaCard from '../ui/MediaCard/Card';
import Content from '../ui/MediaCard/Content';

import { useSelector } from '../../hooks';

interface CardProps {
  index: number;
  fieldName: string;
  onUpload?:() => void;
  onDelete?:(index: number) => void;
}

const defaultMedia: AbstractMedia = { type: MediaType.IMAGE, path: VehicleCheckImage };

const VehicleCheckCard: React.FC<CardProps> = ({
  fieldName, onUpload, onDelete, index,
}) => {
  const intl = useIntl();
  const { change } = useForm();

  const selfServiceId = useSelector(getSelfServiceId);
  const { getLabelTranslation } = useLabelTranslation();

  const [uploadMedia, { isLoading }] = useUploadMediaMutation();
  const [getMediaUrl, { isLoading: isFetchingUrl, isSuccess: isGetUrlSuccess }] = useGetMediaUrlMutation();
  const [isMediaProcessing, setIsMediaProcessing] = React.useState(isFetchingUrl || isLoading);
  const [hasUploadingError, setHasUploadingError] = React.useState(false);
  const [uploadData, setUploadData] = React.useState<Record<string, string> | undefined>(undefined);
  const isUploading = isMediaProcessing || isFetchingUrl || isLoading;

  const {
    name = '', instruction, media, type, responseMedia,
  } = useField<Item>(fieldName, { subscription: { value: true } }).input.value;
  const validate: FieldValidator<string> = React.useCallback((value, allValues) => {
    if (!value) {
      if (getIn(allValues, `${fieldName}.type`) === VehicleCheckModelType.MANDATORY) { // Mandatory item
        return 'validation.required';
      }
      if (getIn(allValues, `${fieldName}.responseMedia`) !== undefined) { // Media uploading
        return 'validation.loading';
      }
    }
    return undefined;
  }, [fieldName]);
  const setUploadId = useField<string>(`${fieldName}.responseMedia.uploadId`, { subscription: {}, validate }).input.onChange;

  const handleDelete = React.useCallback(() => {
    change(`${fieldName}.responseMedia`, undefined);
    onDelete?.(index);
  }, [change, fieldName, index, onDelete]);

  const uploadAndDisplayMedia = React.useCallback(async (url:string, file:Blob, uploadId:string, cardName:string, mediaType:string) => {
    const path = URL.createObjectURL(file);
    change(`${cardName}.responseMedia`, { type: mediaType, path, contentType: file.type });
    await uploadMedia({ url, file }).unwrap();
    setUploadId(uploadId);
    onUpload?.();
    setHasUploadingError(false);
    setUploadData(undefined);
  }, [change, onUpload, setUploadId, uploadMedia]);

  const handleUpload = React.useCallback(async (file: File) => {
    try {
      setIsMediaProcessing(true);
      const { path: url, uploadId, type: mediaType } = await getMediaUrl({
        url: `${SELF_SERVICE}/${selfServiceId}${VEHICLE_CHECK}${GET_MEDIA_UPLOAD_URL}`,
        contentType: file.type,
      }).unwrap();
      setUploadData({ url, uploadId, mediaType });
      await uploadAndDisplayMedia(url, file, uploadId, fieldName, mediaType);
    } catch (e) {
      setHasUploadingError(true);
      change(`${fieldName}.responseMedia`, undefined);
    }
    setIsMediaProcessing(false);
  }, [change, fieldName, getMediaUrl, selfServiceId, uploadAndDisplayMedia]);

  const handleRetry = React.useCallback(async (file: File) => {
    try {
      setIsMediaProcessing(true);
      if (!isGetUrlSuccess) {
        await handleUpload(file);
      } else {
        await uploadAndDisplayMedia(uploadData?.url, file, uploadData?.uploadId, fieldName, uploadData?.mediaType);
      }
    } catch (e) { /* empty */ }
    setIsMediaProcessing(false);
  }, [isGetUrlSuccess, handleUpload, uploadAndDisplayMedia, uploadData?.url, uploadData?.uploadId, uploadData?.mediaType, fieldName]);

  const handleChangeName = (newName: string) => change(`${fieldName}.name`, newName);

  const title = typeof name === 'string' ? name : getLabelTranslation(name);
  const description = instruction ? getLabelTranslation(instruction) : '';

  const uploadId = responseMedia?.uploadId;
  const isUploaded = Boolean(uploadId);
  const isAdditional = type === VehicleCheckModelType.ADDITIONAL;
  const isMandatory = type === VehicleCheckModelType.MANDATORY;

  const shouldShowOverlay = !isUploaded && media;
  const shouldEditTitle = isAdditional && isUploaded;
  const shouldShowPlaceholder = isAdditional && !title && isUploaded;
  const displayedMedia = responseMedia ?? media ?? defaultMedia;
  const hidePreview = (isAdditional && !isUploaded) || hasUploadingError;

  const placeholder = intl.formatMessage({ id: 'vehicleCheck.addNote', defaultMessage: 'Add note (e.g.: scratch on the roof)' });
  const displayedTitle = isAdditional && !isUploaded ? intl.formatMessage({ id: 'vehicleCheck.addAdditionalMedia', defaultMessage: 'Add additional media' }) : title;

  return (
    <div>
      <MediaCard
        media={displayedMedia}
        hideOverlay={!shouldShowOverlay}
        hidePreview={hidePreview}
        onUpload={handleUpload}
        onDelete={handleDelete}
        isUploading={isUploading}
        className="hide-play-button"
        hasError={hasUploadingError}
        onRetry={handleRetry}
      />
      <Content
        editable={shouldEditTitle}
        placeholder={shouldShowPlaceholder && placeholder}
        title={displayedTitle}
        mandatory={isMandatory}
        description={description}
        onChangeName={handleChangeName}
      />
    </div>
  );
};

export default VehicleCheckCard;
