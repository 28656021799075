import { useDispatch } from 'react-redux';

import { isAuthorizedFile } from 'utils/formUtils';
import { MediaSupport } from 'constants/mediaTypes';
import { addNotification } from 'modules/notifications/actions';
import { NotificationType } from 'modules/notifications/types/Notification';

interface BrowseParams {
  accept?:MediaSupport;
  multiple?: boolean;
  capture?: string;
}

type UseBrowse = () => (params?:BrowseParams) => Promise<FileList | Array<File>>;

export const unMount = (element:HTMLElement) => {
  const parent = element instanceof HTMLElement && element.parentNode;
  if (parent) {
    return parent.removeChild(element);
  }
  return undefined;
};

const useBrowse:UseBrowse = () => {
  const dispatch = useDispatch();

  return ({ accept, multiple, capture } = {}) => new Promise((resolve, reject) => {
    const fileInput = document.createElement('input');

    fileInput.type = 'file';
    fileInput.dataset.testid = 'hidden-file-input';
    fileInput.style.display = 'none';

    fileInput.accept = accept ? [...(accept.extensions ?? []), ...accept.types ?? []].join(',') : 'image/*';
    fileInput.multiple = multiple;
    if (capture) {
      fileInput.capture = capture;
    }

    fileInput.addEventListener('change', () => {
      const files = Array.from(fileInput.files).filter((file) => isAuthorizedFile(file, accept));
      if (files.length !== fileInput.files.length) {
        dispatch(addNotification({
          title: {
            id: 'media.errorTitle',
            defaultMessage: 'Error',
          },
          description: {
            id: 'media.errorMessage',
            defaultMessage: 'This type of file is not allowed',
          },
          type: NotificationType.ERROR,
        }));
      }
      resolve(files);
      unMount(fileInput);
    }, { once: true });

    // Detect when the file browser is closed (i.e. cancelled if `onchange` didn't fire) using focus on window
    window.addEventListener('focus', () => {
      // setTimeout is needed to avoid race condition with `change` event (no less than 300ms)
      setTimeout(() => {
        if (fileInput.files.length === 0) {
          unMount(fileInput);
          reject();
        }
      }, 300);
    }, { once: true });

    document.body.appendChild(fileInput);
    fileInput.click();
  });
};

export default useBrowse;
