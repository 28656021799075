const VENDOR_ID = 7851;
const PRODUCT_ID = 16;

class QRCodeScannerDevice {
  private static instance: QRCodeScannerDevice;

  private device: HIDDevice;

  private buffer: Uint8Array;

  private listeners: Set<(qrCodeData: string) => void>;

  public static getInstance(vendorId = VENDOR_ID, productId = PRODUCT_ID): QRCodeScannerDevice {
    if (!this.instance) {
      this.instance = new QRCodeScannerDevice(vendorId, productId);
    }

    return this.instance;
  }

  private constructor(vendorId: number, productId: number) {
    this.device = null;
    this.listeners = new Set();
    this.buffer = new Uint8Array();

    this.init(vendorId, productId);
  }

  private async init(vendorId: number, productId: number): Promise<void> {
    if (this.device) {
      return;
    }

    const devices = await navigator.hid?.getDevices();
    this.device = devices?.find(
      (device) => device.productId === productId && device.vendorId === vendorId,
    );

    if (this.device) {
      this.device.addEventListener('inputreport', (event) => this.handleInputReport(event));
      await this.device.open();
    } else {
      setTimeout(this.init, 1000);
    }
  }

  private handleInputReport({ data }: HIDInputReportEvent): void {
    const qrCode = new Uint8Array(data.buffer);
    // The format of the qrCode is the following:
    // Byte 0 : report ID
    // Byte 1 : Barcode Length
    // Byte 2 - 57 : Decoded data
    // Byte 58 - 61 : Reserved
    // Byte 62 : Newland Symbology Identifier
    // Byte 63 : Check if all data is received

    // Get the decoded data
    const isLastPart = qrCode[62] === 0;
    const decodedData = qrCode.slice(1, 57);

    const mergedBuffer = new Uint8Array(this.buffer.length + decodedData.length);
    mergedBuffer.set(this.buffer);
    mergedBuffer.set(decodedData, this.buffer.length);

    this.buffer = mergedBuffer;

    if (isLastPart) {
      const decodedDataString = new TextDecoder().decode(this.buffer);

      // Remove blank characters
      const [qrCodeToken] = decodedDataString.split('\r');
      if (qrCodeToken?.length > 50) {
        this.listeners.forEach((listener) => listener(qrCodeToken));
      }

      this.buffer = new Uint8Array();
    }
  }

  public addListener(listener: (qrCodeData: string) => void): void {
    this.listeners.add(listener);
  }

  public removeListener(listener: (qrCodeData: string) => void): void {
    this.listeners.delete(listener);
  }
}

export default QRCodeScannerDevice;
