import React from 'react';
import classNames, { Argument } from 'classnames';

import { Button, Carousel } from 'components/ui';
import { downloadURL } from 'utils/downloadUtils';
import { AbstractMedia } from 'types/AbstractMedia';
import { isPublicDevice } from 'modules/dealers/selectors';
import { ButtonProps, ButtonType } from 'components/ui/Button';

import { useSelector } from '../../hooks';
import DocumentLine from './DocumentLine';

interface AttachmentsProps {
  className?: Argument,
  documentClassName?: Argument,
  documents?: AbstractMedia[],
  singleDownloadTitle?: React.ReactNode,
  buttonProps?: ButtonProps,
}

const Attachments: React.FC<AttachmentsProps> = ({
  className, documentClassName, singleDownloadTitle, buttonProps, documents = [],
}) => {
  const isPublic = useSelector(isPublicDevice);
  const [previewMedia, setPreviewMedia] = React.useState<number>();

  const hasDocument = documents.length > 0;
  const hasManyDocuments = documents.length > 1;
  const displayDocumentsList = hasManyDocuments || isPublic || !singleDownloadTitle;
  const showDownloadButton = singleDownloadTitle && hasDocument && !hasManyDocuments && !isPublic;

  const handleMediaPreview = (index: number) => () => setPreviewMedia(index);

  const handleDownload = React.useCallback(() => {
    // In this case - We've only one item items in medias
    const [file] = documents;
    downloadURL(file.path, file.filename);
  }, [documents]);

  return (
    <>
      {/* We display a button if there are only one files and a list when we have many files */}
      {showDownloadButton && (
        <Button
          className="w-full mb-4"
          testId="download-button"
          onClick={handleDownload}
          type={ButtonType.PRIMARY}
          {...buttonProps}
        >
          {singleDownloadTitle}
        </Button>
      )}

      {displayDocumentsList && (
        <>
          <div className={classNames('flex flex-col justify-center mt-2', className)} data-testid="document-list">
            {documents.map((document, index) => (
              <div
                key={document.id}
                data-testid="document-list-item"
                className={classNames(documentClassName, 'py-6 border-t-2')}
              >
                <DocumentLine
                  document={document}
                  hideDownload={isPublic}
                  onPreview={isPublic ? handleMediaPreview(index) : undefined}
                />
              </div>
            ))}
          </div>
          {previewMedia >= 0 && (
            <Carousel
              fullscreen
              hideActions
              medias={documents}
              onClose={handleMediaPreview(undefined)}
              defaultMediaIndex={previewMedia}
            />
          )}
        </>
      )}
    </>
  );
};

export default Attachments;
