import React from 'react';
import classNames, { Argument } from 'classnames';

import useMediaMetrics from 'hooks/useMediaMetrics';
import { isLowPerfMode } from 'modules/app/selectors';

import { AbstractMedia } from 'types/AbstractMedia';

import Play from 'assets/icons/play.svg';

import { useSelector } from '../../hooks';
import Button, { ButtonType } from './Button';

interface VideoProps extends React.DetailedHTMLProps<Omit<React.VideoHTMLAttributes<HTMLVideoElement>, 'className'>, HTMLVideoElement> {
  media: AbstractMedia;
  thumbnail?: boolean;
  className?: Argument;
  'data-testid'?: string;
}

const isIOSSafari = /iPad|iPhone|iPod/.test(navigator.userAgent);

const Video: React.FC<VideoProps> = ({
  media, thumbnail, 'data-testid': testId, onSeeking, className,
}) => {
  const ref = React.useRef<HTMLVideoElement>(null);

  const mediaMetrics = useMediaMetrics(media);
  const lowPerfMode = useSelector(isLowPerfMode);

  const isBlobUrl = media.path.startsWith('blob:');
  const shouldLoadVideo = !lowPerfMode || !thumbnail;
  const hasMediaThumbnail = media.thumbnailPath;

  const playVideo = React.useCallback(() => {
    try {
      if (isIOSSafari) {
        ref.current.playsInline = true;
        ref.current.autoplay = true;
      }
      ref.current.play();
    } catch (error) { /* Nothing to do */ }
  }, [ref]);

  // Pause video when it's not visible
  React.useLayoutEffect(() => {
    if (ref.current && shouldLoadVideo) {
      if (thumbnail && isIOSSafari && isBlobUrl) {
        // Trick to load preview on IOS safari with blob url
        ref.current.autoplay = true;
        ref.current.pause();
        ref.current.load();
        ref.current.autoplay = false;
      }

      if (thumbnail && !ref.current.paused) {
        ref.current.pause();
        ref.current.currentTime = 0;
      }

      const observer = new IntersectionObserver((entries) => {
        if (entries.every(({ isIntersecting }) => !isIntersecting)) {
          ref.current.pause();
          ref.current.currentTime = 0;
        } else if (ref.current.paused && !thumbnail) {
          playVideo();
        }
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      });

      observer.observe(ref.current as HTMLVideoElement);

      return () => observer.disconnect();
    }

    return undefined;
  }, [playVideo, isBlobUrl, thumbnail, shouldLoadVideo]);

  return (
    <>
      {thumbnail && (
        <Button
          testId="carousel-play"
          type={ButtonType.WHITE}
          className="rounded-full size-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white drop-shadow-xl active:!bg-white !p-1 z-20 cursor-pointer play-button"
        >
          <Play className="fill-gray-90" />
        </Button>
      )}
      {!shouldLoadVideo && hasMediaThumbnail && (
        <div
          style={{ backgroundImage: `url("${media.thumbnailPath}")` }}
          className={classNames('relative bg-contain bg-origin-padding bg-center bg-no-repeat h-full w-full bg-input-bg', className)}
        />
      )}
      {shouldLoadVideo && (
        <video
          ref={ref}
          preload="auto"
          onSeeking={onSeeking}
          className={`max-h-full w-full ${thumbnail ? ' bg-black' : ' bg-recommended-bg'}`}
          data-testid={testId}
          autoPlay={!thumbnail}
          controls={!thumbnail}
          playsInline={!thumbnail}
          disablePictureInPicture
          disableRemotePlayback
          controlsList="nofullscreen nodownload noremoteplayback noplaybackrate nopip"
          {...mediaMetrics}
        >
          <source src={isBlobUrl ? media.path : `${media.path}#t=0.001`} type={media.contentType} />
          <track kind="captions" />
        </video>
      )}
    </>
  );
};

export default Video;
