import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import type { SelfServiceType } from 'modules/selfServices/types/SelfService';

export interface GetReadySelfServicesRequest {
  kioskId: string;
  type: SelfServiceType;
  text?: string;
}

export enum KeyType {
  MOBILITY = 'MOBILITY',
  CUSTOMER = 'CUSTOMER',
}

export interface ReadySelfService {
  id: string;
  keyType?: KeyType;
  keyStatus: KeyStatus;
  registration: string;
  startDate: string;
  endDate?: string;
  customerDisplayName?: string;
  slotId?: string;
  type: SelfServiceType;
  finalized?: boolean;
}

export type GetReadySelfServicesResponse = ReadySelfService[];
