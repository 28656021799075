import storage from 'redux-persist/lib/storage/session';
import { REHYDRATE, PersistConfig, StateReconciler } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

import setAppOrigin from 'utils/appUtils';
import { getUrlParam } from 'utils/urlUtils';
import { getAppOrigin } from 'modules/app/selectors';
import { getExpiresIn } from 'modules/auth/selectors';

import rootReducer, { resetApp } from './rootReducer';
import { startAppListening } from './ListenerMiddleware';

startAppListening({
  type: REHYDRATE,
  effect: (_, listenerApi) => {
    const expiresIn = getExpiresIn(listenerApi.getOriginalState());
    if ((expiresIn && expiresIn > Date.now())) {
      listenerApi.dispatch(resetApp());
    }

    const key = getAppOrigin(listenerApi.getState());
    setAppOrigin(key);
  },
});

const stateReconciler: StateReconciler<ReturnType<typeof rootReducer>> = (inbound, original, reduced, config) => {
  const outdoorShareboxToken = getUrlParam('OSToken');
  const reconciliate = !outdoorShareboxToken
    && (inbound.auth.publicToken === original.auth.publicToken
      || inbound.auth.vehicleCheckPublicToken === original.auth.publicToken);
  return reconciliate ? autoMergeLevel1(inbound, original, reduced, config) : original;
};

// eslint-disable-next-line import/prefer-default-export
export const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  storage,
  key: 'root',
  timeout: 86400000,
  blacklist: ['app', 'api', 'chat', 'socket', 'router', 'kiosk', 'notifications', 'healthCheck', 'onlinePayment'],
  stateReconciler,
};
