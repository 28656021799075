import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Arrow from 'assets/icons/arrow.svg';
import ArrowDirection from 'assets/icons/Arrow';

type ExpandableTextAreaProps = {
  data: React.ReactNode[]
};

const DEFAULT_ROW_SIZE = 6;

const ExpandableList: React.FC<ExpandableTextAreaProps> = ({ data }) => {
  const [nbRowDisplayed, setNbRowDisplayed] = React.useState<number>(DEFAULT_ROW_SIZE);

  const isCollapsed = React.useMemo(() => nbRowDisplayed < data.length, [data, nbRowDisplayed]);
  const displayedData = data.filter((_, index) => index < nbRowDisplayed);

  const handleClick = React.useCallback(() => {
    setNbRowDisplayed(isCollapsed ? data.length : DEFAULT_ROW_SIZE);
  }, [setNbRowDisplayed, data, isCollapsed]);

  return (
    <div className="flex flex-col bg-input-bg rounded-lg kiosk:rounded-2xl p-4 kiosk:p-6 w-full">
      {displayedData.map((item, index) => (
        <div
          data-testid={`item-${index}`}
          className="border-b border-bg-divider pb-3 mb-3 last-of-type:pb-0 last-of-type:mb-0 last-of-type:border-0"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          {item}
        </div>
      ))}
      {data.length > DEFAULT_ROW_SIZE && (
        <div
          className="w-full pt-3 cursor-pointer"
          onClick={handleClick}
          data-testid="ExpandCollapseBtn"
        >
          <div className="flex justify-center items-center kiosk:text-2xl kioskSharebox:text-xl">
            {isCollapsed && <FormattedMessage id="list.viewAll" defaultMessage="View all" />}
            {!isCollapsed && <FormattedMessage id="list.reduce" defaultMessage="Reduce" />}
            <Arrow className={classNames('size-4 ml-3', isCollapsed ? ArrowDirection.DOWN : ArrowDirection.UP)} />
          </div>
        </div>
      )}

    </div>
  );
};

export default ExpandableList;
