import { createSlice } from '@reduxjs/toolkit';

import { logout } from 'modules/auth/actions';
import formsAdapter from 'modules/form/adapter';
import dealersApi from 'modules/dealers/service';
import selfServicesApi from 'modules/selfServices/service';

const formsSlice = createSlice({
  name: 'form',
  initialState: formsAdapter.getInitialState(),
  reducers: {
    upsertForm: formsAdapter.upsertOne,
    upsertFormMany: formsAdapter.upsertMany,
    resetForm: () => formsAdapter.getInitialState(),
    resetVCForm: (state) => ({ ...state, entities: { ...state.entities, vehicleCheck: undefined } }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => formsAdapter.getInitialState())
      .addMatcher(
        selfServicesApi.endpoints.getFinalInstructions.matchFulfilled,
        () => formsAdapter.getInitialState(),
      )
      .addMatcher(
        dealersApi.endpoints.getContext.matchFulfilled,
        () => formsAdapter.getInitialState(),
      );
  },
});

export default formsSlice;
