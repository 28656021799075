import React from 'react';
import { useSelector } from 'react-redux';

import { getHelpLinePhoneNumbers } from 'modules/dealers/selectors';

import PhoneNumber from 'assets/icons/phoneNumber.svg';

const DealersPhone = () => {
  const phoneNumbers = useSelector(getHelpLinePhoneNumbers);

  return (
    <div className="flex flex-col items-center mt-10">
      {phoneNumbers.map(({ dealerName, selfServicePhoneNumber: phone }) => (
        <div
          key={`${dealerName}_${phone.prefix}_${phone.local}`}
          className="inline-flex font-bold items-center flex-row flex-wrap justify-center"
        >
          {dealerName && <span>{dealerName}: </span>}
          {!dealerName && <PhoneNumber className="size-6" />}
          <span className="ml-4 font-bold">{`+${phone.prefix} ${phone.local}`}</span>
        </div>
      ))}
    </div>
  );
};

export default DealersPhone;
