import { v4 as uuidv4 } from 'uuid';

import api from 'service/index';
import { SELF_SERVICE } from 'constants/url';

import UserType from 'types/UserType';
import { addNotification } from 'modules/notifications/actions';
import { NotificationType } from 'modules/notifications/types/Notification';

import { linkifyMessage } from './utils';
import { Message } from './types/Message';
import GetMessagesQuery from './types/GetMessagesQuery';
import AddMessageMutation from './types/AddMessageMutation';
import GetMessagesResponse from './types/GetMessagesResponse';

const chatApi = api.injectEndpoints({
  endpoints: (build) => ({
    addMessage: build.mutation<Message, AddMessageMutation>({
      query: ({ selfServiceId, message }) => ({
        method: 'POST',
        url: `${SELF_SERVICE}/${selfServiceId}/messages`,
        body: { message },
      }),
      transformResponse: (_, __, orig) => ({
        // Fake id, it should be overwritten when the user re-opens the chat (i.e. when the page 0 is fetched)
        id: uuidv4(),
        unread: false,
        content: orig.message,
        createdOn: new Date().toISOString(),
        createdBy: {
          // Fake id, it should be overwritten when the user re-opens the chat (i.e. when the page 0 is fetched)
          id: 'self',
          type: UserType.SYSTEM,
          onCustomerBehalf: true,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            addNotification({
              type: NotificationType.ERROR,
              title: {
                id: 'admin.errors.retry',
                defaultMessage: 'Please try again. If the problem persists, contact the support team.',
              },
            }),
          );
        }
      },
    }),
    getMessages: build.mutation<GetMessagesResponse, GetMessagesQuery>({
      query: ({ selfServiceId, page, size }) => ({
        method: 'GET',
        url: `${SELF_SERVICE}/${selfServiceId}/messages`,
        params: { page, size },
      }),
      transformResponse: (response: GetMessagesResponse) => ({
        ...response,
        content: response.content?.map((message) => ({
          ...message,
          content: linkifyMessage(message),
        })),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            addNotification({
              type: NotificationType.ERROR,
              title: {
                id: 'admin.errors.retry',
                defaultMessage: 'Please try again. If the problem persists, contact the support team.',
              },
            }),
          );
        }
      },
    }),
    readMessages: build.mutation<void, string>({
      query: (selfServiceId) => ({
        method: 'PATCH',
        url: `${SELF_SERVICE}/${selfServiceId}/messages/read`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            addNotification({
              type: NotificationType.ERROR,
              title: {
                id: 'admin.errors.retry',
                defaultMessage: 'Please try again. If the problem persists, contact the support team.',
              },
            }),
          );
        }
      },
    }),
  }),
});

export const { useGetMessagesMutation, useAddMessageMutation, useReadMessagesMutation } = chatApi;

export default chatApi;
