import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from 'hooks';

import { DoorsStatus } from 'modules/kiosk/types/KeysSafe';

import { setDoorError, setDoorStatus } from 'modules/kiosk/actions';

import { hasAdminToken } from 'modules/auth/selectors';
import { getDoorsStatus, getIsCloseFailure, getIsOpenFailure } from 'modules/kiosk/selectors';

import Button, { ButtonType } from 'components/ui/Button';
import doorClosedImage from 'assets/images/doorsClosed.png';
import doorOpenedImage from 'assets/images/doorsOpened.png';
import classNames, { Argument } from 'classnames';

export enum ErrorOrigin {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

interface KeysErrorProps {
  onOpen: () => void;
  onClose: () => void;
  className?: Argument;
}

const KeysError:React.FC<React.PropsWithChildren<KeysErrorProps>> = ({
  onOpen, onClose, className, children,
}) => {
  const dispatch = useDispatch();

  const isAdmin = useSelector(hasAdminToken);
  const doorStatus = useSelector(getDoorsStatus);
  const isOpenFailure = useSelector(getIsOpenFailure);
  const isCloseFailure = useSelector(getIsCloseFailure);

  const isFailure = isOpenFailure || isCloseFailure;

  const handleCancel = () => {
    dispatch(setDoorStatus(DoorsStatus.PENDING));
    dispatch(setDoorError(false));
  };

  const onRetry = isOpenFailure ? onOpen : onClose;
  const errorOrigin = isAdmin ? ErrorOrigin.ADMIN : ErrorOrigin.USER;

  return isFailure ? (
    <div className={classNames('flex flex-col items-center gap-16', className)} id="keys-modal-error">
      <div className="text-6xl text-center">
        <FormattedMessage
          tagName="h1"
          id={`kiosk.doorError.${errorOrigin}.${doorStatus}.title`}
          defaultMessage={`An error occurred while ${isOpenFailure ? 'opening' : 'closing'} the slot`}
        />
        <span className="mt-10 block">
          <FormattedMessage
            id={`kiosk.doorError.${errorOrigin}.${doorStatus}.description`}
            defaultMessage={
              isAdmin
                ? 'Please try again. If the problem persists, you can cancel and contact Fleetback support.'
                : 'There seems to have been a problem. Please try again or log out if the problem persists.'
            }
          />
        </span>
      </div>
      <img src={isOpenFailure ? doorClosedImage : doorOpenedImage} alt="Kiosk with keys" className="my-12" />
      <div className="p-10 bg-white rounded-full flex gap-5" id="keys-modal-actions">
        {isAdmin && (
          <Button testId="error-cancel" className="w-72" type={ButtonType.SECONDARY} onClick={handleCancel}>
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
        )}
        {typeof onRetry === 'function' && (
          <Button testId="error-retry" className="w-72" onClick={onRetry}>
            <FormattedMessage id="retry" defaultMessage="Retry" />
          </Button>
        )}
        {children}
      </div>
    </div>
  ) : null;
};

export default KeysError;
