import React from 'react';

import ConnectedForm from 'components/ui/ConnectedForm';

import FormKeys from 'modules/form/types/FormKeys';

import PageBaseProps from 'types/PageBase';

import VehicleCheckFormRender from './VehicleCheckFormRender';

const VehicleCheckForm: React.FC<PageBaseProps> = ({ onNext, onPrev, shouldDisplayBackButton }) => (
  <ConnectedForm
    onPrev={onPrev}
    onSubmit={onNext}
    subscription={{}}
    formKey={FormKeys.VEHICLE_CHECK}
    component={VehicleCheckFormRender}
    shouldDisplayBackButton={shouldDisplayBackButton}
  />
);

export default VehicleCheckForm;
