import React from 'react';
import classNames, { Argument } from 'classnames';
import { FormattedNumber, FormattedMessage, useIntl } from 'react-intl';
import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline';

import Label from 'types/Label';
import ExtraStatus from 'types/ExtraStatus';
import { AbstractMedia, MediaType } from 'types/AbstractMedia';

import useLabelTranslation from 'hooks/useLabelTranslation';

import Carousel from '../Carousel';
import Textarea from '../Textarea';
import Button, { ButtonType } from '../Button';

interface CardProps {
  title: React.ReactNode;
  status: ExtraStatus;
  comment?: string;
  allowComment?: boolean;
  description?: Label;
  disabled?: boolean;
  mediaPlaceholder?: AbstractMedia;
  medias?: AbstractMedia[];
  mandatory?: boolean;
  highlighted?: boolean;
  titleClassName?: Argument;
  onStatusChange?: (status: ExtraStatus) => void;
  onCommentChange?: (comment: string) => void;
  onDisabledClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: Argument;
  centerText?: boolean;
}

type CardPriceProps = | {
  /** @type {number} You should provide a `currency` as well */
  price: number;
  /** @type {string} Is mandatory when `price` is present */
  currency: string;
  discountedPrice?: number;
} | {
  price?: never;
  currency?: never;
  discountedPrice?: never;
};

const Card: React.FC<CardProps & CardPriceProps> = ({
  title,
  description,
  medias = [],
  mandatory,
  price,
  status,
  comment,
  highlighted,
  currency,
  disabled,
  allowComment,
  centerText,
  mediaPlaceholder,
  onStatusChange,
  onDisabledClick,
  onCommentChange,
  discountedPrice,
  titleClassName,
  className,
}) => {
  const intl = useIntl();
  const { getLabelTranslation } = useLabelTranslation();

  const hasPrice = price > 0;
  const hasMedias = medias?.length > 0;
  const hasComment = Boolean(comment);
  const hasDescription = Boolean(description);
  const hasDiscount = hasPrice && discountedPrice >= 0 && price !== discountedPrice;

  const [shouldShowComment, setShouldShowComment] = React.useState(hasComment);
  const [videoIndexPreview, setVideoIndexPreview] = React.useState<number>();

  const videos = React.useMemo(() => medias.filter(({ type }) => type === MediaType.VIDEO), [medias]);

  const handleClick = React.useCallback(() => setShouldShowComment(true), []);

  const handleOpenVideoPreview = React.useCallback(
    (media: AbstractMedia) => setVideoIndexPreview(media.type === MediaType.VIDEO ? videos.findIndex((video) => video.id === media.id) : undefined),
    [videos],
  );
  const handleCloseVideoPreview = React.useCallback(() => setVideoIndexPreview(undefined), []);

  const handleCommentChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => onCommentChange(event.target.value),
    [onCommentChange],
  );

  const handleStatusChange = (newStatus: ExtraStatus) => () => {
    onStatusChange(newStatus);
  };

  return (
    <div className={classNames('rounded-lg kiosk:rounded-2xl bg-input-bg overflow-hidden flex flex-col mb-5', className)}>
      {hasMedias && <Carousel hideCursor medias={medias} onMediaClick={handleOpenVideoPreview} />}
      {!hasMedias && mediaPlaceholder && <Carousel medias={[mediaPlaceholder]} />}
      {videoIndexPreview >= 0 && (
        <Carousel
          fullscreen
          medias={videos}
          onClose={handleCloseVideoPreview}
          defaultMediaIndex={videoIndexPreview}
        />
      )}

      <div className={classNames('p-3 kiosk:p-6 flex flex-col justify-between grow', { '!pb-0': allowComment })}>
        <div>
          {highlighted && (
            <p data-testid="highlight" className="flex items-center w-fit mb-3 bg-recommended-bg rounded-3xl text-white px-5 xs:px-4 py-1 !text-sm kiosk:!text-base font-semibold">
              <span className="!text-xs mr-2 text-star-color">★</span>
              <FormattedMessage
                id="extras.recommended"
                defaultMessage="Recommended for you"
              />
            </p>
          )}
          <div className={classNames({
          }, 'font-bold flex mb-3', titleClassName)}
          >
            <h2 data-testid="title" className="title_card overflow-hidden flex flex-col flex-1">
              <span className={classNames('break-word flex', { 'm-auto': centerText })}>
                {mandatory && <span className="text-star-mandatory !text-xl mr-2 kiosk:!text-3xl !leading-none">*</span>}
                {title}
              </span>
            </h2>
            {hasPrice && (
              <div data-testid="price" className="flex flex-col kiosk:text-2xl kioskSharebox:text-xl ml-4 items-end">
                <span data-testid="full-price" className={classNames('kiosk:!text-xl leading-6 kiosk:!leading-11', { 'line-through text-gray-90 font-normal text-sm kiosk:!text-lg': hasDiscount })}>
                  {/* eslint-disable-next-line react/style-prop-object */}
                  <FormattedNumber value={price} style="currency" currency={currency} />
                </span>
                {hasDiscount && (
                  <span className="!text-base kiosk:!text-2xl ml-0 sm:ml-2 kiosk:!leading-11" data-testid="discounted-price">
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <FormattedNumber value={discountedPrice} style="currency" currency={currency} />
                  </span>
                )}
              </div>
            )}
          </div>
          {hasDescription && (
            <p
              data-testid="description"
              className={classNames('whitespace-pre-wrap font-normal text-sm text-gray-70 kiosk:text-xl leading-4 mb-3', { 'text-center': centerText })}
            >
              {getLabelTranslation(description)}
            </p>
          )}
        </div>
        <div>
          <div className="relative grid grid-cols-2">
            {disabled && onDisabledClick && <div className="absolute top-0 left-0 size-full z-10 cursor-not-allowed" onClick={onDisabledClick} data-testid="disabledExtraOverlay" />}
            <Button
              type={status === ExtraStatus.ACCEPTED ? ButtonType.TERTIARY : ButtonType.PRIMARY}
              onClick={handleStatusChange(ExtraStatus.ACCEPTED)}
              className="py-3 kiosk:!py-6 kiosk:text-xl mr-1.5"
              testId="accept"
              disabled={disabled}
            >
              <FormattedMessage id="confirm.yes" defaultMessage="Yes" />
            </Button>
            <Button
              type={status === ExtraStatus.REFUSED ? ButtonType.TERTIARY : ButtonType.PRIMARY}
              onClick={handleStatusChange(ExtraStatus.REFUSED)}
              className="py-3 kiosk:!py-6 kiosk:text-xl ml-1.5"
              testId="refuse"
              disabled={disabled}
            >
              <FormattedMessage id="confirm.no" defaultMessage="No" />
            </Button>
          </div>
          {allowComment && !shouldShowComment && (
            <>
              <div className="w-full h-px bg-divider-bg mt-3" />
              <Button
                type={ButtonType.LIGHT}
                className="w-full my-1"
                testId="AddComment"
                onClick={handleClick}
              >
                <div className="flex justify-center items-center kiosk:text-xl">
                  <ChatBubbleBottomCenterIcon className="h-5 w-5 mr-3" />
                  <FormattedMessage id="repairOrder.addComment" defaultMessage="Add comment" />
                </div>
              </Button>
            </>
          )}
          {shouldShowComment && (
            <>
              <div className="w-full h-px bg-divider-bg mt-5" />
              <Textarea
                label={intl.formatMessage({ id: 'repairOrder.comment', defaultMessage: 'Comment' })}
                value={comment}
                data-testid="comment"
                rows={3}
                onChange={handleCommentChange}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
