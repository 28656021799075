import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from 'App/Store';

import { messagesAdapter } from './adapters';

const getState = (state: RootState) => state.chat;

const getPage = createDraftSafeSelector(
  getState,
  ({ page }) => page,
);

export const getPageNumber = createDraftSafeSelector(
  getPage,
  (page) => page?.number ?? 0,
);

export const getTotalPages = createDraftSafeSelector(
  getPage,
  (page) => page?.totalPages ?? 0,
);

const getMessages = createDraftSafeSelector(
  getState,
  ({ messages }) => messages,
);

const getAllMessages = createDraftSafeSelector(
  getMessages,
  messagesAdapter.getSelectors().selectAll,
);

export const getSortedMessages = createDraftSafeSelector(
  getAllMessages,
  (messages) => messages?.toReversed() ?? [],
);

export const haveUnreadMessages = createDraftSafeSelector(
  getAllMessages,
  (messages) => messages.some(({ unread }) => unread),
);

export const getUnreadMessageCount = createDraftSafeSelector(
  getAllMessages,
  (messages) => messages.reduce((acc, { unread }) => (unread ? acc + 1 : acc), 0),
);
