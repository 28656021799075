import { jwtDecode } from 'jwt-decode';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import authApi from 'modules/auth/service';
import dealersApi from 'modules/dealers/service';

import type { Token } from 'modules/vehicleCheck/types/GetContextResponse';
import type InitialState from './types/InitialState';
import type { ContextResponse } from './types/ContextResponse';
import type { InitialState as LoginResponse } from '../auth/types/InitialState';

const dealersSlice = createSlice({
  name: 'dealers',
  initialState: {
    status: 200,
    context: null,
  } as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      dealersApi.endpoints.getContext.matchFulfilled,
      (state, action: PayloadAction<ContextResponse>) => ({
        ...state,
        context: action.payload,
        status: 200,
      }),
    );
    builder.addMatcher(authApi.endpoints.getMFAConnection.matchFulfilled, (state, action) => {
      const {
        connectionId, email, phoneNumber, selfServiceId, ...rest
      } = action.payload;

      return {
        ...state,
        context: {
          ...state.context,
          ...rest,
        },
        status: 200,
      };
    });
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, action: PayloadAction<LoginResponse>) => {
        const { token } = action.payload;
        const decodedToken = jwtDecode<Token>(token);
        return {
          ...state,
          context: {
            ...state.context,
            type: decodedToken.type,
            origin: decodedToken.origin,
            kioskId: decodedToken.kiosk_id,
            kioskType: decodedToken.kiosk_type,
            kioskLocationId: decodedToken.location_id,
            kioskLocation: decodedToken.kiosk_location,
          },
          status: 200,
        };
      },
    );
    builder.addMatcher(
      dealersApi.endpoints.getContext.matchRejected,
      (state, action) => ({
        ...state,
        status: typeof action.payload.status === 'number' ? action.payload.status : 404,
      }),
    );
  },
});

export default dealersSlice;
