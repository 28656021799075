import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { b } from 'utils/i18nUtils';
import { useSelector } from 'hooks';

import { Button, Confirm } from 'components/ui';
import Spinner from 'components/ui/Spinner';
import { ButtonType } from 'components/ui/Button';

import Doors from 'assets/icons/doors.svg';
import Warning from 'assets/icons/warning.svg';

import KeysManagementModal from 'components/Key/KeysManagementModal';
import {
  getSlotsByRack,
  hasError as hasErrorSelector,
  isIdle,
  isKioskDataLoading,
  isClosingOrClosed as isClosingOrClosedSelector,
  showEmergencyAlert,
} from 'modules/kiosk/selectors';
import { isKioskEmergencyMode } from 'modules/dealers/selectors';
import type { Slot as SlotType } from 'modules/kiosk/types/Slot';
import { closeKeysSafe, openKeysSafe } from 'modules/kiosk/actions';

import Slot from './Slot';
import KeysError from '../../Key/KeysError';
import EmergencyAlert from './EmergencyAlert';
import EmergencySlot from './Slot/EmergencySlot';
import DoorsEmergencyModalContent from './DoorsEmergencyModalContent';
import EmergencyKeysManagementModalContent from './EmergencyKeysManagementModalContent';

const Slots: React.FC = () => {
  const dispatch = useDispatch();

  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [selectedSlot, setSelectedSlot] = React.useState<SlotType>(null);
  const [isEmergencyModeEnabled, setIsEmergencyModeEnabled] = React.useState(false);

  const doorsIdle = useSelector(isIdle);
  const hasError = useSelector(hasErrorSelector);
  const hasKioskDoorsError = useSelector(isKioskEmergencyMode);
  const isLoading = useSelector(isKioskDataLoading);
  const slotsByRack = useSelector(getSlotsByRack);
  const isClosingOrClosed = useSelector(isClosingOrClosedSelector);
  const shouldDisplayEmergencyAlert = useSelector(showEmergencyAlert);

  const displayKeyModal = !doorsIdle || hasError;
  const displayLoaderLabel = hasKioskDoorsError && isClosingOrClosed;
  const displayUnlockCarousel = hasKioskDoorsError && isEmergencyModeEnabled;

  const handleCancelConfirm = React.useCallback(() => setShowConfirmModal(false), []);
  const handleOpenConfirm = React.useCallback(
    (slot: SlotType) => () => {
      setShowConfirmModal(true);
      setSelectedSlot(slot);
    },
    [],
  );

  const handleOpenDoor = () => {
    setShowConfirmModal(false);
    dispatch(openKeysSafe({
      slotId: selectedSlot?.id, hasAccepted: false, emergency: true, asAdmin: true,
    }));
  };

  const handleCloseDoor = () => {
    dispatch(closeKeysSafe({ asAdmin: true, emergency: true, slotId: selectedSlot?.id }));
  };

  return (
    <div className="main-content !pt-44">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="flex flex-col mb-20 w-full">
          {shouldDisplayEmergencyAlert && (
            <EmergencyAlert isActivated={isEmergencyModeEnabled} onChange={setIsEmergencyModeEnabled} />
          )}
          {displayKeyModal && (
            <h1 className="text-center">
              <FormattedMessage id="admin.manage.action.open" defaultMessage="Open slot" />
            </h1>
          )}
          {displayUnlockCarousel && (
            <div className="w-full p-4 bg-secondary rounded-full mt-10 flex gap-x-8 items-center">
              <div className="relative bg-secondary rounded-full size-20">
                <Doors className="absolute inset-4 fill-black" />
              </div>
              <div className="flex flex-col grow">
                <span className="font-bold">
                  <FormattedMessage id="admin.emergency.openDoors.title" defaultMessage="Open the 2 sliding doors" />
                </span>
                <span className="!text-xl">
                  <FormattedMessage
                    id="admin.emergency.openDoors.subTitle"
                    defaultMessage="Use this to unlock the carousel."
                  />
                </span>
              </div>
              <Button
                type={ButtonType.ERROR}
                onClick={handleOpenDoor}
                className="!text-xl !leading-10 font-semibold w-56 !p-6 uppercase transition-none"
              >
                <FormattedMessage id="admin.emergency.openDoors.action.title" defaultMessage="Open doors" />
              </Button>
            </div>
          )}
          {!displayKeyModal && Object.entries(slotsByRack).map(([rack, rackSlots]) => (
            <React.Fragment key={rack}>
              <h3 className={classNames('mb-6 mt-12 first:mt-0', { 'opacity-20': displayUnlockCarousel })}>
                <FormattedMessage id="admin.rack" defaultMessage="Rack {rack}" values={{ rack }} />
              </h3>
              {isEmergencyModeEnabled && (
                <ul className={classNames('grid grid-cols-1 grid-flow-row gap-5 pb-20', { 'opacity-20': hasKioskDoorsError })}>
                  {rackSlots.map((slot) => (
                    <EmergencySlot key={slot.id} {...slot} onOpen={handleOpenConfirm(slot)} disabled={hasKioskDoorsError} />
                  ))}
                </ul>
              )}
              {!isEmergencyModeEnabled && (
                <ul className="grid grid-cols-3 grid-flow-row gap-8">
                  {rackSlots.map((slot) => (
                    <Slot key={`${slot.id}-`} {...slot} />
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
      <Confirm
        open={showConfirmModal}
        onConfirm={handleOpenDoor}
        onCancel={handleCancelConfirm}
        confirmProps={{ type: ButtonType.ERROR }}
        cancelProps={{ type: ButtonType.SECONDARY }}
        title={(
          <h2 className="text-center uppercase p-4">
            <FormattedMessage id="admin.manage.key.confirmationModal.title" defaultMessage="Confirm opening" />
          </h2>
        )}
        question={(
          <>
            <Warning className="size-36 bg-error p-8 overflow-visible mb-4 rounded-full fill-white" />
            <p className="mb-14">
              <FormattedMessage
                id="admin.manage.key.confirmationModal.content"
                defaultMessage="Any open slot will be <b>marked as emptied</b>. Do not leave anything inside."
                values={{ b }}
              />
            </p>
          </>
        )}
      />
      <KeysManagementModal
        loaderClassName="emergency"
        label={displayLoaderLabel && (
          <span className="mt-10 text-white text-3xl text-center">
            <FormattedMessage id="admin.emergency.openDoorsLoader.description" defaultMessage="We are carrying out a test on the carrousel." />
          </span>
        )}
      >
        {hasKioskDoorsError && <DoorsEmergencyModalContent onClose={handleCloseDoor} />}
        {!hasKioskDoorsError && (
          <>
            {hasError && <KeysError onOpen={handleOpenDoor} onClose={handleCloseDoor} />}
            {!hasError && selectedSlot && <EmergencyKeysManagementModalContent {...selectedSlot} onClose={handleCloseDoor} />}
          </>
        )}
      </KeysManagementModal>
    </div>
  );
};

export default Slots;
