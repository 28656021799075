import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import type { Slot as SlotType } from 'modules/kiosk/types/Slot';

import { Button } from 'components/ui';
import { ButtonType } from 'components/ui/Button';

const EmergencySlot: React.FC<Omit<SlotType, 'rack' | 'used'> & { used?: boolean; onOpen: () => void; disabled?: boolean }> = ({
  id,
  position,
  displayName,
  referenceId,
  registration,
  used,
  onOpen,
  disabled,
}) => {
  // Concats the registration and the displayName if they are both defined
  // Otherwise it returns the one that is defined
  const information = [referenceId, displayName].filter(Boolean).join(' - ');

  return (
    <li
      className={classNames('w-full p-7 bg-secondary rounded-full flex gap-x-8 items-center', {
        'border-2 border-solid border-border-bg bg-white': !used,
      })}
      data-testid={`slot-${id}`}
    >
      <div className="align-middle text-3xl font-bold pl-2">{position}</div>
      <div className="flex-1 flex flex-col">
        {registration && <div className="text-2xl font-bold">{registration}</div>}
        {information && <div className="text-xl">{information}</div>}
      </div>
      <Button type={ButtonType.ERROR} onClick={onOpen} testId="open" disabled={disabled} className="kiosk:text-xl kiosk:py-4 kiosk:px-8">
        <FormattedMessage id="admin.manage.action.open" defaultMessage="Open slot" />
      </Button>
    </li>
  );
};

export default EmergencySlot;
