import selfServicesApi from 'modules/selfServices/service';

import { store } from './Store';

let lastKnownSelfServiceId: string = null;

/**
 * This listener is used to re-fetch the self-service data when the user navigates back to the page.
 * This fixes an issue where the data is not re-fetched when the user navigates back to the application
 *  using "back" button from Stripe after its link expired (amount changed and/or took too long).
 * @see https://fleetback.atlassian.net/browse/BMWVID-14770
 */
const listener = (event: PageTransitionEvent) => {
  if (event.persisted) {
    const { queryCacheKey, refetch } = store.dispatch(
      selfServicesApi.endpoints.getSelfServiceById.initiate({ id: lastKnownSelfServiceId }),
    );

    if (queryCacheKey) {
      refetch();
    }
  }
};

store.subscribe(() => {
  const { selfServiceId } = store.getState().auth;

  if (selfServiceId) {
    // If the self-service id changes, we need to re-attach the listener
    if (selfServiceId !== lastKnownSelfServiceId) {
      lastKnownSelfServiceId = selfServiceId;

      window.removeEventListener('pageshow', listener);
      window.addEventListener('pageshow', listener);
    }
  } else {
    window.removeEventListener('pageshow', listener);
  }
});
